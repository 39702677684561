@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$largePadding: 43px;
$largePaddingFormDevice: 116px;
$largePaddingFormDesktop: 103px;
$minScreenSize: 414px;

$smallMargin: 48px;
$mediumMargin: 64px;
$largeMargin: 107px;
$largeMarginForm: 104px;

$imageContainerPaddingRight: 10px;

$imageContainerDeviceWidth: 364px;
$imageContainerDesktopWidth: 736px;
$imageContainerDeviceHeight: 311px;
$imageContainerDesktopHeight: 438px;
$imageContainerMaxWidth: 88vw;

$imageDeviceWidth: 242px;
$imageDesktopWidth: 584px;
$imageDeviceHeight: 268px;
$imageDesktopHeight: 368px;

$imageContainerDesktopWidthForm: 760px;
$imageContainerDeviceHeightForm: 244px;
$imageContainerDesktopHeightForm: 344px;
$imageContainerMaxWidthForm: 88vw;

$imageDeviceWidthForm: 192px;
$imageDesktopWidthForm: 360px;
$imageDeviceHeightForm: 126px;
$imageDesktopHeightForm: 236px;

$smallImageDeviceWidth: 94px;
$smallImageDesktopWidth: 188px;
$smallImageDeviceHeight: 94px;
$smallImageDesktopHeight: 188px;

$smallImagePaddingRightDevice: 31px;
$smallImagePaddingRightDesktop: 92px;

$gridMarginBottomDevice: 69px;
$gridMarginBottomDesktop: 12px;

.imagesContainer {
  inline-size: theme.fluid-size(
    $imageContainerDeviceWidth,
    $imageContainerDesktopWidth,
    $minScreenSize,
    theme.$maxViewportWidth
  );
  block-size: theme.fluid-size(
    $imageContainerDeviceHeight,
    $imageContainerDesktopHeight,
    $minScreenSize,
    theme.$maxViewportWidth
  );
  position: relative;
  margin-block-start: $mediumMargin;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: $imageContainerPaddingRight;
  max-width: $imageContainerMaxWidth;
  align-self: center;

  @include theme.small-desktop {
    grid-area: image;
    margin-block-start: theme.fluid-size($mediumMargin, $largeMargin, theme.$largeDevice, theme.$maxViewportWidth);
    max-width: $imageContainerDesktopWidth;
    max-height: $imageContainerDesktopHeight;
  }

  .image {
    inline-size: theme.fluid-size($imageDeviceWidth, $imageDesktopWidth, $minScreenSize, theme.$maxViewportWidth);
    block-size: theme.fluid-size($imageDeviceHeight, $imageDesktopHeight, $minScreenSize, theme.$maxViewportWidth);
    z-index: 2;
    border-radius: theme.$border-radius-small;
    margin-block-start: $largePadding;
    object-fit: cover;

    @include theme.small-desktop {
      border-radius: theme.$border-radius-medium;
      max-width: $imageDesktopWidth;
      max-height: $imageDesktopHeight;
    }
  }

  .smallImage {
    inline-size: theme.fluid-size(
      $smallImageDeviceWidth,
      $smallImageDesktopWidth,
      $minScreenSize,
      theme.$maxViewportWidth
    );
    block-size: theme.fluid-size(
      $smallImageDeviceHeight,
      $smallImageDesktopHeight,
      $minScreenSize,
      theme.$maxViewportWidth
    );
    z-index: 2;
    border-radius: theme.$border-radius-small;
    margin-inline-end: $smallImagePaddingRightDevice;

    object-fit: cover;

    @include theme.small-desktop {
      border-radius: theme.$border-radius-medium;
      max-width: $imageDesktopWidth;
      max-height: $imageDesktopHeight;
      margin-inline-end: $smallImagePaddingRightDesktop;
    }
  }

  &.isFormVariant {
    flex-direction: row-reverse;
    margin-block-start: $smallMargin;

    inline-size: theme.fluid-size(100%, $imageContainerDesktopWidthForm, $minScreenSize, theme.$maxViewportWidth);
    block-size: theme.fluid-size(
      $imageContainerDeviceHeightForm,
      $imageContainerDesktopHeightForm,
      $minScreenSize,
      theme.$maxViewportWidth
    );

    @include theme.small-desktop {
      margin-block-start: $largeMarginForm;
    }

    .gridSvg {
      margin-bottom: $gridMarginBottomDevice;
      @include theme.small-desktop {
        margin-bottom: $gridMarginBottomDesktop;
      }
    }

    .image {
      margin-block-start: 0;
      margin-block-end: $largePaddingFormDevice;

      inline-size: theme.fluid-size(
        $imageDeviceWidthForm,
        $imageDesktopWidthForm,
        $minScreenSize,
        theme.$maxViewportWidth
      );
      block-size: theme.fluid-size(
        $imageDeviceHeightForm,
        $imageDesktopHeightForm,
        $minScreenSize,
        theme.$maxViewportWidth
      );
      @include theme.small-desktop {
        margin-block-end: $largePaddingFormDesktop;
      }
    }
  }
}
