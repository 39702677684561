@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';

$paddingSmallDevice: 8px;
$paddingLargeDevice: 16px;
$paddingsmallDesktop: 24px;
$paddingsLargeDesktop: 32px;
$borderRadiusSmall: 16px;
$borderRadiusLarge: 24px;
$backgroundDark: rgba(255, 255, 255, 0.05);
$backgroundLight: rgba($green-primary, 0.05);
$fluidWidthDevice: 23.2vw;
$fluidWidthDesktop: 8.3vw;
$fluidMaxWidth: 40.8vw;

.AccordionComponentCard {
  display: flex;
  padding: $paddingSmallDevice $paddingLargeDevice;
  flex-direction: column;
  align-items: flex-start;
  gap: $paddingsLargeDesktop;
  border-radius: $borderRadiusLarge;
  position: relative;
  width: 100%;

  @include large-device {
    display: flex;
    padding: $paddingsmallDesktop $paddingsmallDesktop $paddingsLargeDesktop;
    justify-content: space-between;
    border-radius: $border-radius-medium;
    gap: 0;
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .image {
    width: $fluidWidthDevice;
    height: auto;
    aspect-ratio: 1 / 1;
    border-radius: $borderRadiusSmall;

    @include large-device {
      width: $fluidWidthDesktop;
      border-radius: $borderRadiusLarge;
      right: $paddingsmallDesktop;
      top: $paddingsmallDesktop;
    }
  }

  .subtitle {
    @include large-device {
      max-width: $fluidMaxWidth;
    }
  }

  &.light {
    background: $backgroundLight;

    .subtitle,
    .title {
      color: $green-primary !important;
    }
  }
  &.dark {
    background: $backgroundDark;

    .subtitle,
    .title {
      color: $green-secondary !important;
    }
  }
}
