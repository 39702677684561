@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$filtersDevicePadding: 40px 25px 64px 40px;
$filtersDesktopPadding: 80px 96px 56px 96px;

$filtersDeviceHeadingBottomMargin: 40px;
$filtersDesktopHeadingBottomMargin: 64px;

$filterEntryContainerHeightDesktop: 320px;
$filterEntryContainerColumnGap: 92px;

$filterEntryDeviceHeight: 64px;
$filterEntryDesktopHeight: 80px;
// We want our entries to be half of the available width minus half the padding (92/2);
$filterEntryDesktopWidth: calc(50% - 46px);

$filterEntryFontSizeSmallDevice: 14px;
$filterEntryFontSizeLargeDevice: 18px;
$filterEntryLineHeightSmallDevice: 20px;
$filterEntryLineHeightLargeDevice: 32px;

$filterIndexMinWidth: 10%;
$filterLabelWidth: 90%;
$filterLabelMinWidth: 40%;
$filterInputSelectMinWidth: 40%;
$filterInputToggleMinWidth: 10%;
$filterSectionMargin: 10px;

$filterCtaDeviceTopMargin: 40px;
$filterCtaDesktopTopMargin: 48px;
$filterCtaIconSize: 25px;
$filterCtaIconPadding: 8px;
$filterCtaFontSize: 16px;
$filterCtaLineHeight: 24px;

.Filters {
  padding: $filtersDevicePadding;

  @include theme.small-desktop {
    padding: $filtersDesktopPadding;
  }

  .heading {
    margin-bottom: $filtersDeviceHeadingBottomMargin;
    color: $green-primary;

    @include theme.small-desktop {
      margin-bottom: $filtersDesktopHeadingBottomMargin;
    }
  }
  
  .FilterEntryContainer {
    display: flex;
    flex-direction: column;

    @include theme.small-desktop {
      height: $filterEntryContainerHeightDesktop;
      flex-wrap: wrap;
      column-gap: $filterEntryContainerColumnGap;
    }

    .FilterEntry {
      height: $filterEntryDeviceHeight;
      width: 100%;
      font-size: $filterEntryFontSizeSmallDevice;
      line-height: $filterEntryLineHeightSmallDevice;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dae8e8;
      color: $green-primary;
      font-weight: $normal-font-weight;

      @include theme.large-device {
        font-size: $filterEntryFontSizeLargeDevice;
        line-height: $filterEntryLineHeightLargeDevice;
      }

      @include theme.small-desktop {
        height: $filterEntryDesktopHeight;
        width: $filterEntryDesktopWidth;
      }
      
      .FilterIndex {
        font-family: $amazonEmberMono;
        font-size: $filterEntryFontSizeSmallDevice;
      }
  
      .FilterLabel {
        width: $filterLabelWidth;
        min-width: $filterLabelMinWidth;
        margin-left: $filterSectionMargin;
      }
      
      // TODO: See if we can make the dropdown element render on its own new line on mobile views. Need to get flexbox to force a row break.
      .FilterInputSelect {
        float: right;
        min-width: $filterInputSelectMinWidth;
      }

      .FilterInputToggle {
        float: right;
        margin-left: $filterSectionMargin;
      }
    }
  }

  .CtaEntry {
    display: flex;
    align-items: center;
    margin-top: $filterCtaDeviceTopMargin;

    @include theme.small-desktop {
      margin-top: $filterCtaDesktopTopMargin;
    }

    .CtaLink {
      margin-left: auto;

      a {
        float: right;
        font-weight: $bold-font-weight;
        font-size: $filterCtaFontSize;
        line-height: $filterCtaLineHeight;
        text-decoration: underline;
      }

      .CtaEntryIcon {
        float: left;
        @include size($filterCtaIconSize);
        color: $green-primary;
        background-color: $sky-blue;
        margin-right: 10px;
        padding: $filterCtaIconPadding;
        border-radius: 100%;
      }
    }
  }
}

kat-box {
  --padding: 0 !important;
}
