@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$checkboxSubLabelGap: 4px;
$checkboxSubLabelMargin: 8px 0 0 0;
$selectGroupTopMargin: 24px;
$selectGroupBorderRadius: 24px;
$selectGroupGap: 16px;
$textInputContainerTopMargin: 32px;
$errorWrapperTopMargin: 12px;
$errorWrapperGap: 4px;
$errorIconSize: 24px;

$selectGroupMobilePadding: 16px;

$selectGroupDesktopPadding: 24px;

.label {
  font-family:
    #{$amazonEmberDisplay},
    sans-serif;
  font-size: theme.$fontSizeSubtitle;
  line-height: theme.$fontLineHeightSubtitle;
  margin: 0;
  color: theme.$green-primary;

  &.error {
    color: theme.$errorColor;
  }
}

.subLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $checkboxSubLabelGap;
  margin: $checkboxSubLabelMargin !important;
}

.subLabelIcon {
  @include theme.size(24px);
  outline: 2px solid theme.$green-primary;
  outline-offset: -3px;
  border-radius: 50%;

  &.error {
    color: theme.$errorColor;
    outline-color: theme.$errorColor;
  }
}

.subLabel {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$green-primary;
  margin: 0 !important;

  &.error {
    color: theme.$errorColor;
  }
}

.selectGroup {
  margin-top: $selectGroupTopMargin;
  padding: $selectGroupMobilePadding;
  border-radius: $selectGroupBorderRadius;
  background: theme.$white;
  display: flex;
  flex-direction: column;
  gap: $selectGroupGap;
  flex-wrap: wrap;

  @include theme.small-desktop {
    flex-direction: row;
    padding: $selectGroupDesktopPadding;
  }

  &.singleCheck {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.textInputContainer {
  margin-top: $textInputContainerTopMargin;
  width: 100%;
}

.errorWrapper {
  margin-top: $errorWrapperTopMargin;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $errorWrapperGap;
}

.errorIcon {
  @include theme.size($errorIconSize);
  color: theme.$errorColor;
}

.errorMessage {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
  color: theme.$errorColor;
}
