@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$borderRadius: 12px;
$paddingTop: 10px;
$paddingRight: 16px;

.tagline {
  background-color: theme.$white;
  border-radius: $borderRadius;
  font-size: theme.$fontSizeLabel;
  line-height: theme.$fontLineHeightLabel;
  padding: $paddingTop $paddingRight;
  display: block;
  width: fit-content;
}
