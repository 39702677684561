@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$mobileNavbar: 72px;
$desktopNavbar: 96px;
$topMargin: 16px;
$tabContainerSpacing: 6px;
$activeTabPadding: 4px;

$mobileTabSpacing: 12px;
$desktopTabSpacing: 16px;

$imageSize: 32px;

.navigationTabsStickyContainer {
  position: sticky;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: website.zindex(website.$zLayout, anchorLinks);
  padding: 0;

  transition: top 0.41s;
  top: 0;
}

.withNavbar {
  top: $mobileNavbar;

  @include theme.small-desktop {
    top: $desktopNavbar + $topMargin;
  }
}

.navigationTabs {
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  column-gap: $tabContainerSpacing;
  background-color: theme.$white;
  border-radius: theme.$border-radius-medium;
  padding: $activeTabPadding;
  width: fit-content;
}

.tab {
  cursor: pointer;
  border-radius: theme.$border-radius-medium;
  padding: 16px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: $mobileTabSpacing;
  color: theme.$slate-dark;

  &:hover {
    background-color: theme.$grey-secondary;
  }

  @include theme.small-desktop {
    column-gap: $desktopTabSpacing;
  }
}

.isActive {
  background-color: theme.$slate-dark !important;
  .label {
    color: theme.$white;
  }
}

.image {
  height: auto;
  width: $imageSize;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
}

.label {
  margin: 0 !important;
}
