@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$textAreaSubLabelGap: 4px;
$textAreaSubLabelMargin: 8px 0 0 0;
$textAreaWrapperMargin: 32px 0 0 0;
$textAreaWrapperMobilePadding: 24px 24px 46px 24px;
$textAreaWrapperBorderRadius: 24px;
$textAreaLabelTopOffset: 24px;
$textAreaLabelRightOffset: 24px;
$textAreaLabelLeftOffset: 24px;
$textAreaHeight: 350px;
$textAreaResizerSize: 24px;
$textAreaResizerRightOffset: 10px;
$textAreaResizerBottomOffset: 10px;
$textAreaCharacterCountBottomOffset: 16px;
$textAreaCharacterCountLeftOffset: 16px;
$textAreaErrorWrapperTopMargin: 12px;
$textAreaErrorWrapperGap: 4px;
$textAreaErrorIconSize: 24px;

.subLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $textAreaSubLabelGap;
  margin: $textAreaSubLabelMargin !important;
}

.subLabelIcon {
  @include theme.size(24px);
  outline: 2px solid theme.$green-primary;
  outline-offset: -3px;
  border-radius: 50%;

  &.error {
    color: theme.$errorColor;
    outline-color: theme.$errorColor;
  }
}

.subLabel {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$green-primary;
  margin: 0 !important;

  &.error {
    color: theme.$errorColor;
  }
}

.textAreaWrapper {
  position: relative;
  width: 100%;
  margin: $textAreaWrapperMargin;
  padding: $textAreaWrapperMobilePadding;
  border: 1px solid rgba(theme.$green-primary, 0.65);
  border-radius: $textAreaWrapperBorderRadius;
  background: theme.$white;

  // TODO: add this color to theme package;
  &.error {
    border-color: rgba(theme.$errorColor, 0.65);
  }

  &.disabled {
    background: rgba(theme.$white, 0.4);
    border-color: rgba(theme.$slate-dark, 0.4);
  }

  *.error {
    color: theme.$errorColor;
  }

  *.disabled {
    background: rgba(theme.$white, 0.4);
    color: rgba(theme.$slate-dark, 0.4);
    pointer-events: none;
    cursor: pointer;
  }
}

.label {
  position: absolute;
  top: $textAreaLabelTopOffset;
  right: $textAreaLabelRightOffset;
  left: $textAreaLabelLeftOffset;
  z-index: 1;
  font-size: #{theme.$fontSizeBodySmall};
  line-height: #{theme.$fontLineHeightBodySmall};
  color: theme.$green-primary;
  opacity: 0.85;
}

.textArea {
  height: $textAreaHeight;
  width: 100%;
  border: none;
  padding: 0;
  font-family:
    #{$amazonEmberDisplay},
    sans-serif;
  font-size: #{theme.$fontSizeBodySmall};
  line-height: #{theme.$fontLineHeightBodySmall};
  color: theme.$green-primary;
  opacity: 0.85;
  // Disable default resizer
  resize: none;

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.textAreaResizer {
  @include theme.size($textAreaResizerSize);
  position: absolute;
  right: $textAreaResizerRightOffset;
  bottom: $textAreaResizerBottomOffset;
  cursor: s-resize;
  color: theme.$green-primary;
}

.characterCount {
  position: absolute;
  bottom: $textAreaCharacterCountBottomOffset;
  left: $textAreaCharacterCountLeftOffset;
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
  color: theme.$green-primary;
  opacity: 0.85;
}

.errorWrapper {
  margin-top: $textAreaErrorWrapperTopMargin;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $textAreaErrorWrapperGap;
}

.errorIcon {
  @include theme.size($textAreaErrorIconSize);
  color: theme.$errorColor;
}

.errorMessage {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
  color: theme.$errorColor;
}
