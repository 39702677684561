@use "~@amzn/sss-website-theme/dist/theme/index.module.scss" as theme;
@import '../../../index.scss';


.rateButton {
  display: inline-flex;
  height: 48px;
  padding: 10px 16px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0 !important;
  border-radius: 32px;
  font-family: #{$amazonEmberDisplay} !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  &.playbook {
    font-weight: bold !important;
    font-family: #{$amazonEmberDisplay}, sans-serif !important;
  }

  @include theme.small-desktop {
    font-size: 16px !important;
    line-height: 24px !important;
  }

}


