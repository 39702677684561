@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$xSmallOptionHeight: 30px;
$xSmallOptionWidth: 44px;

$mobileOptionHeight: 48px;
$mobileOptionWidth: 64px;

$desktopOptionHeight: 80px;
$desktopOptionWidth: 120px;

$additionalLabelExtraSpacing: 16px;

$mobileOptionsSpacing: 6px;
$desktopOptionsSpacing: 12px;

$optionBorderRadius: 100px;

$xxSmallMobileBreakpoint: 407px;

$errorWrapperTopMargin: 12px;
$errorWrapperGap: 4px;
$errorIconSize: 24px;

$focus-blue: #4a90e2;
$blue-shadow: rgba(74, 144, 226, 0.5);

.radioGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  color: theme.$green-primary;
  margin-bottom: 24px;
  font-family: website.$amazonEmberDisplay;
  font-size: theme.$fontSizeSubtitle !important;
  text-align: left;
  align-self: flex-start;
}

.optionsContainer {
  display: flex;
  justify-content: center;
  gap: $mobileOptionsSpacing !important;
  width: 100%;
  overflow-y: unset;

  @media (min-width: theme.$mediumDevice) {
    gap: $desktopOptionsSpacing !important;
  }

}

.option {
  display: flex;
  flex-direction: column;
  max-width: $mobileOptionWidth;
  align-items: center;
  text-align: center;
  overflow: unset !important;

  @include theme.medium-device {
    max-width: $desktopOptionWidth;
  }

  font-size: 10px;

  @media (min-width: 400px) {
    font-size: theme.fluid-size(11px, 14px, 400px, 1920px);
  }
}

.radio {
  position: absolute;

  // Visually hide default html radio button
  // so we replace with a custom one
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;

  &:focus-visible + label.label {
    outline: 2px solid $focus-blue;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px $blue-shadow;
  }

  &:checked {
    & + label.label {
      background-color: theme.$slate-dark;
      color: theme.$grey-secondary;
    }
  }
}

div {
  label.label {
    background-color: theme.$white;
    color: theme.$green-primary;
    line-height: theme.$fontLineHeightLabel;

    position: relative;

    user-select: none;
    cursor: pointer;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    transition: 0.2s;
    border-radius: $optionBorderRadius;

    @include theme.size($xSmallOptionWidth, $xSmallOptionHeight);


    @media (min-width: 400px) {
      @include theme.size($mobileOptionWidth, $mobileOptionHeight);
    }

    @include theme.medium-device {
      @include theme.size($desktopOptionWidth, $desktopOptionHeight);
    }
  }

  .additionalLabel {
    font-family: website.$amazonEmberMono;
    color: theme.$green-primary;
    margin-top: 8px;
    width: $mobileOptionWidth + $additionalLabelExtraSpacing;
    opacity: 0.85;
    overflow: unset;

    @include theme.medium-device {
      width: $desktopOptionWidth + $additionalLabelExtraSpacing;
    }
  }
}

.errorWrapper {
  margin-top: $errorWrapperTopMargin;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $errorWrapperGap;
}

.errorIcon {
  @include theme.size($errorIconSize);
  color: #cf1b24;
}

.errorMessage {
  font-family: website.$amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
  color: #cf1b24;
}
