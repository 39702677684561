@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$mobileCardPadding: 16px;
$cardPadding: 8px;

$marginBottomDevice: 48px;
$marginBottomDesktop: 68px;

$borderRadiusSmall: 12px;
$borderRadiusCard: 32px;
$borderRadiusLarge: 24px;

$imageDesktopFixedDimension: 180px;
$imageDeviceFixedDimension: 88px;

$minScreenWidth: 414px;

$fontLineHeightCardHeader: fluid-size(24px, 40px, theme.$minViewportWidth, theme.$maxViewportWidth);

kat-card.redirectionCard {
  cursor: pointer;
  height: 100%;
  border-radius: $borderRadiusCard;
  width: 100%;
  display: flex;
  justify-content: start;
  background-color: $white;

  --base-padding: #{$mobileCardPadding};

  @include theme.small-desktop {
    --base-padding: #{$cardPadding};
  }
}

.cardContent {
  display: flex;
  justify-content: start;
  background-color: $white;

  .image {
    width: 112px;
    height: auto;
    border-radius: theme.$border-radius-small;
    align-self: start;
    aspect-ratio: 0.87 / 1;

    @include theme.small-desktop {
      aspect-ratio: 1 / 1;
    }
  }

  .cardBody {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    row-gap: 8px;
    width: 100%;
  }

  .tagline {
    color: theme.$green-primary;
    background-color: rgba(theme.$green-primary, 0.05);
  }

  .title {
    color: theme.$green-primary;
    font-size: 24px;
    margin: 0;
  }

  .copy {
    font-size: $fontSizeBodySmall !important;
    line-height: $fontLineHeightBodySmall !important;
    color: theme.$green-primary;
    margin: 0;
  }

  .cardCta {
    margin-left: auto;
    min-width: 56px;
  }

  .bottom {
    align-self: flex-end;
  }
}
