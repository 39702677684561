@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import 'src/index.scss';

$mobileBorderRadius: 32px;
$xLargeDesktop: 1920px;

$mobileLateralPadding: 25px;
$mobileTopPadding: 64px;
$mobileBottomPadding: 80px;

$xLargeDesktopLeftPadding: 212px;
$xLargeDesktopRightPadding: 80px;
$xLargeDesktopTopBottomPadding: 141px;

$largeDeviceMiddleSpace: 40px;
$smallDesktopMiddleSpace: 60px;
$xLargeMiddleSpace: 142px;

$assetBannerMaxWidthDesktop: 750px;

$deviceHeadingBottomMargin: 24px;
$desktopHeadingBottomMargin: 40px;

$deviceBodyBottomMargin: 48px;
$desktopBodyBottomMargin: 64px;

$deviceCtaBottomMargin: 96px;
$deviceCtaPadding: 20px 24px 20px 32px;
$desktopCtaPadding: 28px 32px 28px 40px;

.assetBanner {
  border-top-left-radius: $mobileBorderRadius;
  border-top-right-radius: $mobileBorderRadius;

  padding: $mobileTopPadding $mobileLateralPadding $mobileBottomPadding;

  background-color: theme.$green-primary;
  display: flex;
  flex-direction: column;

  @include theme.large-device {
    flex-direction: row;
    column-gap: theme.fluid-size(
      $smallDesktopMiddleSpace,
      $smallDesktopMiddleSpace,
      theme.$largeDevice,
      theme.$smallDesktop
    );
  }

  @include theme.small-desktop {
    flex-direction: row;
    column-gap: theme.fluid-size($smallDesktopMiddleSpace, $xLargeMiddleSpace, theme.$smallDesktop, $xLargeDesktop);
    padding-top: theme.fluid-size(
      $mobileTopPadding,
      $xLargeDesktopTopBottomPadding,
      theme.$smallDesktop,
      $xLargeDesktop
    );
    padding-right: theme.fluid-size(
      $smallDesktopMiddleSpace,
      $xLargeDesktopLeftPadding,
      theme.$smallDesktop,
      $xLargeDesktop
    );
    padding-bottom: theme.fluid-size(
      $mobileBottomPadding,
      $xLargeDesktopTopBottomPadding,
      theme.$smallDesktop,
      $xLargeDesktop
    );
    padding-left: theme.fluid-size(
      $smallDesktopMiddleSpace,
      $xLargeDesktopRightPadding,
      theme.$smallDesktop,
      $xLargeDesktop
    );

    border-top-left-radius: theme.$border-radius-medium;
    border-top-right-radius: theme.$border-radius-medium;
  }
}

.assetGraphic {
  width: 100%;
  @include theme.small-desktop {
    max-width: $assetBannerMaxWidthDesktop;
  }
}
   
#assetBanner {
  .content {
    width: fit-content;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .heading {
    color: theme.$green-secondary;
    margin-bottom: $deviceHeadingBottomMargin;  
    @include theme.small-desktop {
      margin-bottom: $desktopHeadingBottomMargin;
    }
  }

  .body {
    white-space: pre-line;
    color: theme.$white;
    opacity: theme.$asxCopyOpacity;
    margin-bottom: $deviceBodyBottomMargin;
    @include theme.small-desktop {
      margin-bottom: $desktopBodyBottomMargin;
    }
  }
}
.cta {
  width: fit-content;
  margin-bottom: $deviceCtaBottomMargin;
  font-size: theme.$fontSizeCta !important;
  line-height: theme.$fontLineHeightCta !important;
  font-weight: 700 !important;
  padding: $deviceCtaPadding !important;
  @include theme.small-desktop {
    // Leaving the device margin in this case wouldn't make any difference since there's nothing under the CTA but removing it for good practice
    margin-bottom: 0;
    padding: $desktopCtaPadding !important;
  }
}
.ctaContent {
  gap: 4px !important;
}
