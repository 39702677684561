@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$largeDesktopBreakpoint: 1920px;

$mobileDynamicListVerticalPadding: 40px;

$largeDeviceDynamicListTopPadding: 133px;
$largeDeviceDynamicListBottomPadding: 157px;

$largeDeviceDynamicListMiddleSpace: 100px;
$largeDesktopDynamicListMiddleSpace: 208px;

$headingBottomSpaceSmall: 8px;
$headingBottomSpaceLessThanLargeDevice: 48px;
$headingBottomSpaceMoreThanLargeDevice: 64px;

$subtitleBottomSpaceSmallDevice: 84px;
$subtitleBottomSpaceMediumDevice: 84px;

$imageTopPosition: 152px;
$imageTopPositionCollapsed: 79px;
$imageTopPositionPaginator: 196px;
$imageBottomSpace: 125px;

.dynamicList {
  display: flex;
  flex-direction: column;

  @include theme.large-device {
    gap: theme.fluid-size(
      $largeDeviceDynamicListMiddleSpace,
      $largeDesktopDynamicListMiddleSpace,
      theme.$largeDevice,
      $largeDesktopBreakpoint
    );

    justify-content: space-between;
    flex-direction: row;
  }
}

.imageContainer {
  z-index: theme.zindex(website.$zLayout, dynamicListImage);
  height: fit-content;
  color: theme.$green-primary;
  display: flex;
  gap: $imageBottomSpace;
  flex-direction: column;
  padding-top: 16px;

  @include theme.large-device {
    //Responsive layout
    flex-basis: 48.5%;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    top: $imageTopPositionPaginator;
    position: sticky;
  }
}
.heading {
  color: theme.$green-primary;
  padding-bottom: $headingBottomSpaceSmall;

  @include theme.small-device {
    padding-bottom: theme.fluid-size(
      $headingBottomSpaceSmall,
      $headingBottomSpaceLessThanLargeDevice,
      theme.$smallDevice,
      theme.$largeDevice
    );
  }

  @include theme.large-device {
    padding-bottom: $headingBottomSpaceMoreThanLargeDevice;
  }
}

.subtitle {
  color: theme.$green-primary;
  padding-bottom: $subtitleBottomSpaceSmallDevice;
  opacity: theme.$asxCopyOpacity;

  @include theme.medium-device {
    padding-bottom: $subtitleBottomSpaceMediumDevice;
  }
}

.list {
  display: flex;
  flex-direction: column;

  @include theme.large-device {
    //Responsive layout
    flex-basis: 51.5%;
  }
}

.pageIndicator {
  align-self: flex-end;
  font-family: website.$amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
}

.DynamicListSVG {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

  svg {
    max-height: 40vh;
  }
}

.svgMobile {
  position: sticky;
  top: $imageTopPosition;
  background-color: theme.$grey-tertiary;
  padding-top: 28px;
  z-index: 1;

  &.collapse {
    top: $imageTopPositionCollapsed
  }
}
