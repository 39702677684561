@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$mobileSearchResultsWrapperBottomPadding: 460px;
$mobileSearchBarWrapperTopMargin: 140px;
$mobileSearchBarWrapperResultsGap: 24px;

$desktopSearchResultsWrapperBottomPadding: 346px;
$desktopSearchBarWrapperTopMargin: 100px;

.searchResultsWrapper {
  &.noResults {
    padding-bottom: $mobileSearchResultsWrapperBottomPadding;
    @include theme.large-device {
      padding-bottom: $desktopSearchResultsWrapperBottomPadding;
    }
  }
}

.searchBarWrapper {
  margin-top: $mobileSearchBarWrapperTopMargin;
  display: flex;
  justify-content: center;

  @include theme.small-desktop {
    margin-top: $desktopSearchBarWrapperTopMargin;
  }

  &.hasResults {
    margin-top: 0;
    align-items: center;
    gap: $mobileSearchBarWrapperResultsGap;
  }

  &.noResults {
    margin-top: $mobileSearchBarWrapperTopMargin;
    
    @include theme.small-desktop {
      margin-top: $desktopSearchBarWrapperTopMargin;
    }
  }
}

.collapsedBackButton {
  flex-shrink: 0;
}