@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$checkboxDescriptionPadding: 16px 16px 0 12px;
$checkboxRightMargin: 10px;
$checkboxSize: 16px;
$checkmarkBorderRadius: 4px;
$checkmarkRightMargin: 8px;
$checkboxTransitionDuration: 0.2s;
$iconPadding: 1px;
$descriptionMargin: 4px 0 16px 24px;

$focusBorderRadius: 12px;
$checkboxOutlineOffset: -4px;

.checkboxDescription {
  width: 100%;
  background-color: rgba(theme.$grey-primary, 0.65);
  border-bottom: 1px solid rgba(theme.$green-primary, 0.2);
  padding: $checkboxDescriptionPadding;
  cursor: pointer;
  background: theme.$white;
  text-align: start;

  &.modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .isFirst {
    padding-top: 0;
  }

  &.isLast {
    border-bottom: 0;
  }

  &:hover {
    background-color: theme.$grey-tertiary;
    border-radius: $focusBorderRadius;

    .checkmark {
      border: 1px solid theme.$green-primary;
    }
  }

  &:focus-within {
    outline: 1px solid theme.$green-primary;
    outline-offset: $checkboxOutlineOffset;
    border-radius: $focusBorderRadius;
  }

  &.error {
    border-bottom: 1px solid theme.$errorColor;

    &:focus-within {
      outline: 1px solid $errorColor;
    }

    .checkmark {
      border: 1px solid theme.$errorColor;
    }
    .input {
      &:checked ~ .checkmark {
        background-color: theme.$errorColor;

        .icon {
          color: theme.$white;
        }
      }
    }

    .label {
      color: theme.$errorColor;
    }

    .description {
      color: theme.$errorColor;
    }
  }
}

.checkbox {
  display: inline-flex;
  align-items: center;
  font-size: #{theme.$fontSizeBodySmall};
  line-height: #{theme.$fontLineHeightBodySmall};
  margin-right: $checkboxRightMargin;
  height: fit-content;
}

.checkmark {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $checkboxSize;
  height: $checkboxSize;
  min-width: $checkboxSize;
  min-height: $checkboxSize;
  background-color: transparent;
  border: 1px solid rgba(theme.$green-primary, 0.65);
  border-radius: $checkmarkBorderRadius;
  transition: background-color $checkboxTransitionDuration;
  margin-inline-end: $checkmarkRightMargin;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ .checkmark {
    background-color: theme.$green-primary;

    .icon {
      color: theme.$green-secondary;
    }
  }
}

.icon {
  display: flex;
  padding: $iconPadding;
  color: transparent;
  width: $checkboxSize * 0.8;
  height: $checkboxSize * 0.8;
  opacity: 1;
  transition: opacity $checkboxTransitionDuration;
}

.label {
  color: theme.$green-primary;
}

.description {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$green-primary;
  margin: $descriptionMargin !important;
}
