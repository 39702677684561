@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$optionBorderRadius: 100px;

.icon {
  @include theme.size(12px);
  box-sizing: content-box !important;
  padding: 8px;
  margin-left: auto;
}

.navigationTabsStickyContainer {
  width: 100vw;
}

// To prevent line breaks displaying during CSS transitions
.navigationTabs {
  min-width: fit-content;
}

.extended {
  // CSS transition cannot be achieved by setting a new width value (unless we avoid using width: auto, which we require with the way)
  // this layout is approached.
  // A workaround to have a full width transition without using relying on the width property is just setting this element a flex: 1
  .navigationTabs {
    flex: 1;
    border-radius: 0;
  }

  .tab {
    width: 100%;

    &:hover {
      background-color: theme.$white;
    }
  }
}

div {
  label.option {
    display: flex !important;
    flex-direction: row;
    column-gap: 12px;
    width: 100%;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgba(theme.$green-primary, 0.2) !important;
    padding: 26px 0;
  }
}

.radio {
  position: absolute;

  // Visually hide default html radio button
  // so we replace with a custom one
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 100vw;
  pointer-events: none;

  &:checked {
    & ~ .circle {
      .checkIcon {
        background-color: theme.$green-primary;

        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.label {
  color: theme.$slate-dark;
  font-size: theme.$fontSizeBodySmall;
  line-height: theme.$fontLineHeightBodySmall;

  position: relative;

  user-select: none;
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
  border-radius: $optionBorderRadius;
  margin: 0 !important;
}

.circle {
  @include theme.size(16px);
  border-radius: 100%;
  margin-left: auto;
  border: 1px theme.$green-primary solid;
}

.checkIcon {
  transition: all 0.2s;
  border-radius: 100%;
  color: theme.$green-secondary;

  opacity: 0;
  transform: scale(0);
}
