@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

// Values taken from the mocks
$containerGapDesktop: 40px;
$containerGapDevice: 48px;
$imageContainerGapDesktop: 12px;
$imageContainerGapMobile: 40px;
$imageBorderRadius: 40px;
$imagePrimaryWidthDevice: 268px;
$imagePrimaryHeightDevice: 179px;
$imagePrimaryWidthDesktop: 280px;
$imagePrimaryHeightDesktop: 200px;
$imageSecondaryWidthDevice: 172px;
$imageSecondaryHeightDevice: 172px;
$imageSecondaryWidthDesktop: 280px;
$imageSecondaryHeightDesktop: 376px;
$imageSecondaryMarginTopDesktop: 160px;
$ctaButtonMaxHeight: 80px;
$ctaButtonGapDesktop: 52px;
$ctaButtonGapDevice: 32px;
$titleSpacingBottom: 32px;

.bannerContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $containerGapDevice;
  background-color: theme.$grey-secondary;
  color: theme.$green-primary;

  @include theme.small-desktop {
    flex-direction: row-reverse;
    gap: $containerGapDesktop;
  }

  &.isDark {
    background-color: theme.$slate-dark;
    color: theme.$green-secondary;
  }

  .ctaContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
    gap: $ctaButtonGapDevice;

    @include theme.small-desktop {
      flex-basis: 0;
      gap: $ctaButtonGapDesktop;
    }

    .copyContainer {
      display: flex;
      flex-direction: column;
      flex: 1;

      .title {
        margin-bottom: $titleSpacingBottom;
      }

      .description,
      .subTitle {
        opacity: theme.$asxCopyOpacity;
      }
    }

    .ctaButton {
      align-self: start;
      max-height: $ctaButtonMaxHeight;
      font-size: 16px;
    }
  }

  .imageContainer {
    display: flex;
    flex: 1;
    flex-basis: 100%;
    flex-wrap: wrap;
    gap: $imageContainerGapMobile;

    @include theme.small-desktop {
      flex-basis: 0;
      flex-wrap: initial;
      gap: $imageContainerGapDesktop;
    }

    .image {
      border-radius: $imageBorderRadius;
      object-fit: cover;
    }

    .imagePrimaryWrapper,
    .imageSecondaryWrapper {
      flex-basis: 100%;

      @include theme.small-desktop {
        flex-basis: 0;
      }
    }

    .imagePrimaryWrapper {
      text-align: right;
      .imagePrimary {
        width: $imagePrimaryWidthDevice;
        height: $imagePrimaryHeightDevice;

        @include theme.small-desktop {
          width: $imagePrimaryWidthDesktop;
          height: $imagePrimaryHeightDesktop;
        }
      }
    }

    .imageSecondaryWrapper {
      margin-right: auto;
      margin-left: auto;

      .imageSecondary {
        width: $imageSecondaryWidthDevice;
        height: $imageSecondaryHeightDevice;

        @include theme.small-desktop {
          width: $imageSecondaryWidthDesktop;
          height: $imageSecondaryHeightDesktop;
          margin-top: $imageSecondaryMarginTopDesktop;
        }
      }
    }
  }
}
