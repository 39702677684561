@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../../index.scss';

$contactFormWrapperMobileGap: 64px;
$formParagraphMobileBottomMargin: 24px;
$formDropdownContainerMobileBottomMargin: 48px;
$formRequiredFieldsMobileBottomMargin: 24px;
$formSectionHeaderMobileMargin: 0 0 24px 0;
$formSectionHeaderWrapperMobileTopMargin: 48px;
$formSubtitleMobileMargin: 40px 0 0 0;
$submitButtonWrapperMobileTopMargin: 24px;
$submitButtonWrapperMobileRowGap: 40px;

$formErrorsPresentWrapperGap: 8px;
$formErrorsPresentWrapperBorderRadius: 12px;
$formErrorsPresentWrapperPadding: 16px 24px;
$formErrorsPresentWrapperBottomMargin: 32px;
$formErrorsPresentIconSize: 24px;
$formInputWrapperMargin: 32px;
$formInputWrapperMarginLarge: 48px;
$formLabelMargin: 16px 0 0 0;
$requiredFieldsMarginDevice: 16px 0 24px;
$requiredFieldsMarginDesktop: 48px 0 32px;

$contactFormWrapperDesktopMinGap: 20px;
$contactFormWrapperDesktopMaxGap: 152px;
$topOffsetDesktop: 110px;
$formParagraphDesktopMargin: 32px;
$formDropdownContainerDesktopBottomMargin: 88px;
$formRequiredFieldsDesktopBottomMargin: 32px;
$formSectionHeaderDesktopMargin: 0 0 48px 0;
$formSectionHeaderWrapperDesktopTopMargin: 80px;
$formLabelDesktopMargin: 16px 0 0 0;
$formSubtitleDesktopMargin: 48px 0 0 0;
$submitButtonWrapperDesktopTopMargin: 32px;
$submitButtonWrapperDesktopRowGap: 80px;

$pageSectionPadding: 48px;
$submitCtaFontSize: 16px;

.contactFormWrapper {
  display: flex;
  flex-direction: column;
  gap: $contactFormWrapperMobileGap;
  @include theme.medium-device {
    flex-direction: row;
    gap: theme.fluid-size(
      $contactFormWrapperDesktopMinGap,
      $contactFormWrapperDesktopMaxGap,
      theme.$mediumDevice,
      theme.$maxViewportWidth
    );
  }
}

.contactFormImageContainer {
  @include theme.medium-device {
    align-self: flex-start;
    position: sticky;
    top: $pageSectionPadding + $topOffsetDesktop;
    flex: 0;
  }
}

.contactFormHeading {
  margin: 0 !important;
  color: theme.$green-primary;
}

.formParagraphWrapper {
  margin-top: $formParagraphMobileBottomMargin;
}

.formParagraph {
  font-family:
    #{$amazonEmberDisplay},
    sans-serif;
  font-size: #{theme.$fontSizeBody};
  line-height: #{theme.$fontLineHeightBody};
  color: theme.$green-primary;
  margin: 0 0 $formParagraphMobileBottomMargin !important;

  @include theme.medium-device {
    margin: 0 0 $formParagraphDesktopMargin !important;
  }
  p {
    margin: 0 !important;
  }
}

.formDropdownContainer {
  margin-bottom: $formDropdownContainerMobileBottomMargin !important;

  @include theme.small-desktop {
    margin-bottom: $formDropdownContainerDesktopBottomMargin !important;
  }
}

.formErrorsPresentWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $formErrorsPresentWrapperGap;
  border-radius: $formErrorsPresentWrapperBorderRadius;
  padding: $formErrorsPresentWrapperPadding;
  background: rgba(theme.$errorColor, 0.05);
  margin-bottom: $formErrorsPresentWrapperBottomMargin;
}

.formErrorsPresentIcon {
  @include theme.size($formErrorsPresentIconSize);
  color: theme.$errorColor;
}

.formErrorsPresent {
  margin: 0 !important;
  color: theme.$errorColor;
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
}

.formRequiredFields {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$green-primary;
  margin: $requiredFieldsMarginDevice !important;

  @include theme.small-desktop {
    margin: $requiredFieldsMarginDesktop !important;
  }
}

.formSectionHeaderWrapper {
  margin-top: $formSectionHeaderWrapperMobileTopMargin;

  @include theme.small-desktop {
    margin-top: $formSectionHeaderWrapperDesktopTopMargin;
  }
}

.formSectionHeader {
  color: theme.$green-primary;
  margin: $formSectionHeaderMobileMargin !important;

  @include theme.small-desktop {
    margin: $formSectionHeaderDesktopMargin !important;
  }
}

.formInputWrapper {
  margin-top: $formInputWrapperMargin;
}

.formInputWrapperLarge {
  margin-top: $formInputWrapperMarginLarge;
}

.formLabel {
  width: 100%;
  font-size: theme.$fontSizeSubtitle !important;
  line-height: theme.$fontLineHeightSubtitle !important;
  color: theme.$green-primary;

  @include theme.small-desktop {
    margin: $formLabelDesktopMargin !important;
  }
}

.formSubtitle {
  @extend .formLabel;
  margin: $formSubtitleMobileMargin !important;

  @include theme.small-desktop {
    margin: $formSubtitleDesktopMargin !important;
  }
}

.submitButtonWrapper {
  margin-top: $submitButtonWrapperMobileTopMargin;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: $submitButtonWrapperMobileRowGap;

  @include theme.medium-device {
    margin-left: auto;
  }

  @include theme.small-desktop {
    margin-top: $submitButtonWrapperDesktopTopMargin;
    row-gap: $submitButtonWrapperDesktopRowGap;
  }

  button {
    font-size: $submitCtaFontSize !important;
    width: fit-content;
    align-self: end;
  }
}

.privacyNoticeLabel {
  font-size: #{theme.$fontSizeBodySmall};
  line-height: #{theme.$fontLineHeightBodySmall};
  color: theme.$green-primary;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 736px;
  overflow: hidden;
}
