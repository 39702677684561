@use "~@amzn/sss-website-theme/dist/theme/index.module.scss" as theme;

$borderWidth: 1px;
$paddingDevice: 24px;
$paddingDesktop: 48px;
$boundedContainerMargin: 0 auto;

.boundedContainer {
  margin: $boundedContainerMargin;
  max-width: theme.$maxViewportWidth;

  margin: 0 $paddingDevice;

  @include theme.small-desktop {
    margin: 0 $paddingDesktop;
  }
}

.horizontalDivider {
  width: 100%;
  height: $borderWidth;
  display: inline-block;
  background-color: theme.$green-primary;
}
