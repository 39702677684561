@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$defaultWidth: 90%;
$minWidthLargeDevice: 200px;
$minWidthDesktop: 300px;
$borderRadius: 12px;
$height: 48px;
$padding: 10px;
$fontSize: 14px;
$optionBorderColor: rgb(theme.$green-primary, 0.2);

$icon-size: 2vh;
$option-column-gap: 1vh;

kat-dropdown {
  display: inline-block;
  width: #{$defaultWidth};

  @include theme.large-device {
    min-width: #{$minWidthLargeDevice};
  }

  @include theme.small-desktop {
    min-width: #{$minWidthDesktop};
  }

  // https://katal.amazon.dev/design-system/components/dropdown/?tab-props=style-api
  --border-color: #{theme.$grey-primary};
  --border-radius: #{$borderRadius};
  --height: #{$height};
  --padding: #{$padding};

  --font-size: #{$fontSize};
  --font-weight: #{theme.$bold-font-weight};
  --font-family: #{website.$amazonEmberDisplay}, sans-serif;
  --color: #{theme.$green-primary};

  --hover-ring-outlay: 0;
  --hover-ring-width: 0;

  --indicator-color: #{theme.$green-primary};
  --placeholder-color: #{theme.$green-primary};

  --option-border-color: #{$optionBorderColor};
  --option-color: #{theme.$green-primary};
}

.katalOption {
  &:first-of-type {
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
  }

  &:last-of-type {
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }
}

.option {
  display: flex;
  align-items: center;
  column-gap: $option-column-gap;
}

.optionReversed {
  @extend .option;
  justify-content: space-between;
}

.icon {
  @include theme.size($icon-size);
}
