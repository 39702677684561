@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import 'src/index.scss';

$mobileCollapsedSearchInputPadding: 0 0 8px 0;

.searchInput {
  padding: 0;
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  color: theme.$green-primary;
  text-align: center;
  overflow: visible !important;
  font-feature-settings: 'clig' off, 'liga' off;

  &:focus::placeholder {
    color: transparent;
  }

  &::placeholder{
    color: theme.$green-primary;
    opacity: 0.75;
  }

  // removing browser default 'clear' X button that appears on hover:
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  @include theme.small-desktop {
    letter-spacing: -1.32px;
  }
}

.collapsedSearchInput {
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  border: none;
  background: transparent;
  outline: none;
  color: theme.$green-primary;
  width: 100%;
  padding: $mobileCollapsedSearchInputPadding;

  &:focus::placeholder {
    color: transparent;
  }

  &::placeholder{
    color: theme.$green-primary;
    opacity: 0.75;
  }

  // removing browser default 'clear' X button that appears on hover:
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
}