@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$mobileInputContainerPadding: 0 0 16px 0;
$mobileInputContainerTopMargin: 24px;
$mobileInputContainerHeight: 60px;
$mobileButtonsContainerTopMargin: 64px;
$mobileCollapsedSearchMaxWidth: 364px;

$desktopInputContainerPadding: 0 50px 24px 50px;
$desktopInputContainerTopMargin: 64px;
$desktopInputContainerHeight: 120px;
$desktopButtonsContainerTopMargin: 48px;
$desktopClearButtonHeight: 20px;

$searchBarContainerMaxWidth: 904px;
$inputContainerBeforeWidth: 60px;
$submitButtonContentGap: 4px;
$buttonsContainerGap: 24px;
$backButtonTextUnderlineOffset: 4px;
$iconSize: 24px;

.searchBarContainer {
  text-align: center;
  transition: height ease-out 0.8s;
  max-width: $searchBarContainerMaxWidth;
  width: 100%;
}

.title {
  color: theme.$green-primary;
  font-size: theme.$fontSizeSubtitle !important;
  line-height: theme.$fontLineHeightSubtitle !important;
  margin: 0 !important;
}

.inputContainer {
  padding: $mobileInputContainerPadding;
  margin-top: $mobileInputContainerTopMargin;
  height: $mobileInputContainerHeight;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(theme.$green-primary, 0.3);
  @include theme.small-desktop {
    padding: $desktopInputContainerPadding;
    margin-top: $desktopInputContainerTopMargin;
    height: $desktopInputContainerHeight;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: inherit;
    width: $inputContainerBeforeWidth;
    background: linear-gradient(270deg, rgba(theme.$grey-secondary, 0) 0%, theme.$grey-secondary 70%);
    transform: translateX(-1px);
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: $buttonsContainerGap;
  justify-content: center;
  align-items: center;
  margin-top: $mobileButtonsContainerTopMargin;
  @include theme.small-desktop {
    margin-top: $desktopButtonsContainerTopMargin;
  }
}

.submitButton {
  font-size: #{theme.$fontSizeCta};
  line-height: #{theme.$fontLineHeightCta};
}

.submitButtonContent {
  // We use !important here because primaryButton has a 12px gap already applied and due to specifcity we can't override it from here without using !important
  gap: $submitButtonContentGap !important;
}

.clearButton {
  height: $desktopClearButtonHeight;
  color: theme.$green-primary !important;
  padding: 0 !important;
  border-radius: 0 !important;
  text-decoration: underline !important;
  text-underline-offset: $backButtonTextUnderlineOffset;
  font-size: #{theme.$fontSizeCta};
  line-height: #{theme.$fontLineHeightCta};
  &::before {
    content: none;
  }
}

// All this 'collapsed' class names are only used in mobile

.collapsedSearch {
  width: 100%;
  max-width: $mobileCollapsedSearchMaxWidth;
}

.collapsedContainer {
  background-color: theme.$grey-secondary;
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(theme.$green-primary, 0.3);
}

.collapsedClearButton {
  --background: none !important;
  --border-color: none !important;
  box-shadow: none;
  --color: transparent !important;
  line-height: 1.4;
  padding: 0;

  &:hover {
    text-decoration: underline;
    text-decoration-color: theme.$green-primary;
  }
}

.clearIcon {
  @include theme.size($iconSize);
  color: theme.$green-primary !important;
}