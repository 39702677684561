@use 'src/index.scss' as website;
@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';

$primaryButtonDeviceRegularSizePadding: 20px 24px 20px 32px;
$primaryButtonDesktopRegularSizePadding: 20px 32px 20px 40px;

.primaryButton {
  --primary-button-background-color: #{$green-primary};
  --primary-button-border-color: #{$green-secondary};
  --secondary-button-background-color: #{$slate-dark};
  --secondary-button-border-color: #{$green-primary};
  --primary-button-hover-in-duration: 600ms;
  --primary-button-hover-out-duration: 800ms;
  appearance: none;
  border: none;
  background-color: var(--primary-button-background-color) !important;
  border-radius: 40px;
  color: var(--primary-button-border-color) !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 150ms linear;
  font-family: #{website.$amazonEmberDisplay}, sans-serif;
  display: inline-block; // Button was 'inline-block' but Links were taking 'inline' by default and breaking layout
  max-height: 80px;
  z-index: 2; // Ensure buttons are rendered above other page content

  &.inverted {
    --primary-button-background-color: #{$slate-dark};
    --primary-button-border-color: #{$green-primary};
    --secondary-button-background-color: #{$green-primary};
    --secondary-button-border-color: #{$green-secondary};
  }

  &.outlined {
    --primary-button-background-color: transparent;
    border-width: 2px !important;
    border-style: solid !important;
    --primary-button-border-color: #{$green-primary};

    &::before {
      content: '';

      background-color: transparent;
    }
  }

  &.bold {
    font-weight: bold;
  }

  &.regularSize {
    padding: $primaryButtonDeviceRegularSizePadding;
    @include small-desktop {
      padding: $primaryButtonDesktopRegularSizePadding;
    }
  }

  &.smallSize {
    padding: 20px 24px;
  }

  &.highlightedSize {
    padding: 24px 26px;
  }

  &.hasIcon {
    padding: 20px 24px;

    @include small-desktop {
      padding: 28px 40px;
    }
  }

  &:disabled {
    &.outlined {
      --primary-button-border-color: rgba($green-primary, 0.5);
      background-color: transparent !important;
      color: rgba($green-primary, 0.5);
    }
    &.isDark {
      &.outlined {
        --primary-button-border-color: rgba($grey-secondary, 0.5);
      }
    }
  }

  &.isDark {
    --primary-button-background-color: #{$green-secondary};
    --primary-button-border-color: #{$green-primary};
    --secondary-button-background-color: #{$grey-secondary};
    --secondary-button-border-color: #{$green-secondary};
    color: $green-primary;

    &.inverted {
      --primary-button-background-color: #{$grey-secondary};
      --primary-button-border-color: #{$green-primary};
      --secondary-button-background-color: #{$green-secondary};
      --secondary-button-border-color: #{$green-primary};
    }

    &.outlined {
      --primary-button-background-color: transparent;
      --primary-button-border-color: #{$grey-secondary};
    }
  }

  &.isOnlyIcon {
    inline-size: 80px;
    block-size: 56px;
    padding: 0;

    &.smallSize {
      inline-size: 56px;
      block-size: 40px;
    }
  }

  .buttonContent {
    overflow: hidden;
    position: relative;
    block-size: 100%;
  }

  .primaryContent,
  .secondaryContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    block-size: 100%;
    transition: transform var(--primary-button-hover-out-duration) $hoverCubicBezier;
    transform-style: preserve-3d;
  }

  .icon {
    inline-size: 24px;
    block-size: 24px;
    color: currentColor;
  }

  .secondaryContent {
    position: absolute;
    inset: 0;
    transform: translateY(250%);

    .icon {
      color: currentColor;
    }
  }

  .iconWrapper {
    position: relative;
    background-color: rgba(0, 97, 97, 0.1);
    padding: 16px 26px;
    margin: 4px;
    border-radius: 100px;
  }

  &:focus {
    outline: none;
    box-shadow:
      inset 0 0 0 2px var(--primary-button-background-color),
      inset 0 0 0 3px var(--primary-button-border-color);
  }

  &:disabled {
    background-color: #c9cfd2 !important;
    color: #868e97 !important;
    cursor: initial;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    clip-path: ellipse(100% 125% at 50% 125%);
    background-color: var(--secondary-button-background-color);
    transform: translate(0, 176%) scale(2.03, 2.5);
    transition: transform var(--primary-button-hover-out-duration) $hoverCubicBezier;
    inline-size: 100%;
    block-size: 100%;
  }

  @include small-desktop {
    &:hover:not(:disabled) {
      &::before {
        transform: translate(0, 0%) scale(2.03, 1.3);
        transition: transform var(--primary-button-hover-in-duration) $hoverCubicBezier;
      }

      .primaryContent {
        transform: translateY(-150%);
        transition: transform var(--primary-button-hover-in-duration) $hoverCubicBezier;
      }

      .secondaryContent {
        transform: translateY(0%);
        transition: transform var(--primary-button-hover-in-duration) $hoverCubicBezier;
      }
    }
  }
}
