@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';

$xLargeDesktopMaxWidth: 660px;
$desktopTitleMaxWidth: 944px;
$desktopRedirectionCardBottom: 16px;
$desktopRedirectionCardRight: 16px;
$desktopRedirectionCardMaxWidth: 460px;

.SecondaryHero {
  display: flex;
  flex-direction: column;

  .flexContent {
    display: flex;
    flex-direction: column;
    margin-block-end: 9.6vw;

    @include small-desktop {
      flex-direction: row;
      justify-content: space-between;
      margin-block-end: 6.5vw;
    }
  }

  .heading {
    margin-bottom: 11.6vw !important;
    @include small-desktop {
      margin-bottom: 0 !important;
      max-width: $desktopTitleMaxWidth;
    }
  }

  .imageWrapper {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 2 / 1;

    @include small-desktop {
      aspect-ratio: 3.5 / 1;
      margin-bottom: 0;
    }

    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: $border-radius-small;
      object-fit: cover;

      @include small-desktop {
        border-radius: $border-radius-medium;
      }
    }
  }

  .copyContainer {
    white-space: pre-wrap;
    padding: 0 17px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 28px;

    &.hasRedirectionCard {
      padding: 0;
    }

    @include small-desktop {
      width: 40vw;
      max-width: $xLargeDesktopMaxWidth;
      padding: 0;
      gap: 32px;
    }
  }

  .copy {
    margin: 0 !important;
    opacity: $asxCopyOpacity;
    @include small-desktop {
      max-width: 32.4vw;
      margin: 0 !important;
    }
  }

  .redirectionCard {
    @include small-desktop {
      position: absolute;
      bottom: $desktopRedirectionCardBottom;
      right: $desktopRedirectionCardRight;
      overflow: hidden;
      max-width: $desktopRedirectionCardMaxWidth;
      height: auto;
    }
  }

  .externalLink {
    font-size: $fontSizeCta;
    line-height: $fontLineHeightCta;
    font-weight: 700;
  }

  &.light {
    .heading,
    .externalLink,
    .copy {
      color: $green-primary !important;
    }
  }
  &.dark {
    .heading,
    .externalLink,
    .copy {
      color: $grey-secondary !important;
    }
  }
}
