@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$mediumPadding: 24px;
$gap: 16px;
$titleGap: 40px;
$largePadding: 43px;
$xLargePadding: 174px;

$sectionPaddingDevice: 30px;
$sectionPaddingDesktop: 48px;

$minScreenSize: 414px;

$smallMargin: 48px;
$mediumMargin: 64px;
$largeMargin: 107px;

$topPaddingDevice: 64px;
$topPaddingDesktop: 93px;
$bottomPaddingDevice: 80px;
$bottomPaddingDesktop: 160px;
$leftPadding: 62px;
$rightPadding: 11.04vw;

$imageContainerPaddingRight: 10px;
$imageContainerDeviceWidth: 364px;
$imageContainerDesktopWidth: 736px;
$imageContainerDeviceHeight: 311px;
$imageContainerDesktopHeight: 438px;

$imageContainerMaxWidth: 88vw;

$imageDeviceWidth: 242px;
$imageDesktopWidth: 584px;
$imageDeviceHeight: 268px;
$imageDesktopHeight: 368px;

$mainBannerTitleMaxWidth: 600px;

$subtitleTopPadding: 20px;

.MainBanner {
  padding: calc($topPaddingDevice - $sectionPaddingDevice) 0 calc($bottomPaddingDevice - $sectionPaddingDevice);
  &.dark {
    color: theme.$green-secondary;
  }

  &.light {
    color: theme.$green-primary;
  }

  display: flex;
  flex-direction: column;
  gap: $gap;

  @include theme.small-desktop {
    padding: calc($topPaddingDesktop - $sectionPaddingDesktop) calc($rightPadding - $sectionPaddingDesktop)
      calc($bottomPaddingDesktop - $sectionPaddingDesktop) calc($leftPadding - $sectionPaddingDesktop);
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'title subtitle'
      'image accordions';
    column-gap: $xLargePadding;
    &.noSubtitle {
      grid-template-columns: auto;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'title accordions'
        'image accordions';
      gap: $mediumMargin theme.fluid-size($mediumPadding, $xLargePadding, theme.$largeDevice, theme.$maxViewportWidth);
      .accordionsWrapper {
        margin-block-start: theme.fluid-size(0px, $mediumPadding, theme.$largeDevice, theme.$maxViewportWidth);
      }
    }
  }
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: $titleGap;
}

.title {
  margin: 0 !important;
  font-size: theme.$fontSizeHeading02 !important;
  line-height: theme.$fontLineHeightHeading02 !important;

  &.dark {
    color: theme.$grey-secondary;
  }

  &.light {
    color: theme.$green-primary;
  }
  @include theme.small-desktop {
    max-width: $mainBannerTitleMaxWidth;
    grid-area: title;
    letter-spacing: -1px;
  }
}

.subtitle {
  margin: $gap 0 0 !important;
  padding-top: $subtitleTopPadding;
  opacity: theme.$asxCopyOpacity;
  &.dark {
    color: rgba(theme.$grey-secondary, 0.8);
  }

  &.light {
    color: rgba(theme.$green-primary, 0.8);
  }

  @include theme.small-desktop {
    grid-area: subtitle;
    letter-spacing: -0.2px;
    margin: 0 !important;
  }
}

.accordionsWrapper {
  @include theme.small-desktop {
    grid-area: accordions;
  }
}

.cta {
  margin-block-start: $smallMargin;
  @include theme.small-desktop {
    margin-block-start: $mediumMargin;
  }
}

.gridImage {
  padding-right: 0;
}
