@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$widthDevice: 254px;
$heightDevice: 222px;
$widthDesktop: 320px;
$heightDesktop: 288px;

$widthDeviceForm: 194px;
$heightDeviceForm: 121px;
$widthDesktopForm: 280px;
$heightDesktopForm: 219px;

$gridOffset: -24px;

.grid {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 1;
  inline-size: $widthDevice;
  block-size: $heightDevice;
  opacity: 0.3;
  &.dark {
    color: theme.$grey-secondary;
  }
  &.light {
    color: theme.$green-primary;
  }

  @include theme.small-desktop {
    inline-size: $widthDesktop;
    block-size: $heightDesktop;
  }
}

.isFormVariant {
  inset-block-end: 0;
  inset-inline-end: 0;
  inset-block-start: unset;
  inset-inline-start: unset;
  inline-size: $widthDeviceForm;
  block-size: $heightDeviceForm;

  @include theme.small-desktop {
    inset-inline-end: $gridOffset;
    inline-size: $widthDesktopForm;
    block-size: $heightDesktopForm;
    opacity: 0.1;
  }
}
