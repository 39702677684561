@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$copySectionMargin: 40px;
$sliderActionsHeight: 60px;
$sliderActionsMargin: 20px;
$cardMargin: 0px;
$amazonEmberMono: 'amazonEmberMono';
$paginationFontSize: 14px;
$progressColorLight: #00616180; //green-primary but with 50% opacity
$progressColorDark: #e5efef80; //grey-tertiary but with 50% opacity
$progressBarHeight: 2px;
$progressBarWidth: 20%;
$progressBarMaxWidth: 400px;

// Small
$cardSmallContainerSmallDevice: 530px;
// Medium
$cardMediumContainerSmallDevice: 550px;
$cardMediumContainerLargeDevice: 680px;
// Medium-Large
$cardMediumLargeContainerSmallDevice: 640px;
$cardMediumLargeContainerLargeDevice: 790px;
// Large
$cardLargeContainerSmallDevice: 670px;
$cardLargeContainerLargeDevice: 830px;
$cardLargeContainerLargeDesktop: 950px;
// XLarge
$cardXLargeContainerSmallDevice: 740px;
$cardXLargeContainerLargeDevice: 870px;
$cardXLargeContainerLargeDesktop: $cardLargeContainerLargeDesktop;

kat-carousel {
  --kat-carousel-mobile-label-max-height: 0px;

  kat-carousel-item.kat-visually-hidden {
    transition: all 0.5s ease-in !important;
  }

  kat-carousel-item {
    transition: all 0.5s ease-out !important;
  }
}

kat-progress {
  --linear-height: #{$progressBarHeight};

  &.dark {
    --track-background: #{$progressColorDark};
    --track-foreground: #{theme.$grey-secondary};
  }

  &.light {
    --track-background: #{$progressColorLight};
    --track-foreground: #{theme.$green-primary};
  }
}

.slider {
  .sliderCarousel {
    .sliderCarouselItems {
      &.displayContents {
        display: contents !important;
      }
    }

    .sliderCarouselItem {
      padding: 0 !important;

      div {
        section {
          padding: 0 !important;
        }
      }

      @include theme.large-device {
        padding: $cardMargin 0 0 !important;
        div {
          section {
            padding: 0 16px !important;
          }
        }
      }

      .cardContainer {
        @include theme.large-device {
          padding: 15px !important;
        }

        &.small {
          height: $cardSmallContainerSmallDevice;
        }

        &.medium {
          height: $cardMediumContainerSmallDevice;

          @include theme.large-device {
            height: $cardMediumContainerLargeDevice;
          }
        }

        &.medium-large {
          height: $cardMediumLargeContainerSmallDevice;

          @include theme.large-device {
            height: $cardMediumLargeContainerLargeDevice;
          }
        }

        &.large {
          height: $cardLargeContainerSmallDevice;

          @include theme.large-device {
            height: $cardLargeContainerLargeDevice;
          }

          @media (min-width: theme.$maxViewportWidth) {
            height: $cardLargeContainerLargeDesktop;
          }
        }

        &.xlarge {
          height: $cardXLargeContainerSmallDevice;

          @include theme.large-device {
            height: $cardXLargeContainerLargeDevice;
          }

          @media (min-width: theme.$maxViewportWidth) {
            height: $cardXLargeContainerLargeDesktop;
          }
        }
      }
    }
  }

  .sliderActions {
    padding-top: $sliderActionsMargin;
    height: $sliderActionsHeight;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;

    .sliderPagination {
      font-family: $amazonEmberMono;
      font-size: $paginationFontSize;
      margin-right: auto;
      opacity: theme.$asxCopyOpacity;

      @include theme.large-device {
        margin-right: initial;
      }

      &.dark {
        color: theme.$grey-secondary;
      }

      &.light {
        color: theme.$green-primary;
      }
    }

    .sliderProgress {
      height: $progressBarHeight;
      width: $progressBarWidth;
      max-width: $progressBarMaxWidth;
      margin-right: auto;
    }

    .navigation {
      button {
        margin-left: 1rem;
      }
    }
  }

  &.dark {
    background-color: #{theme.$slate-dark};

    .sliderActions {
      background-color: #{theme.$slate-dark};
    }
  }
}
