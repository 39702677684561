@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$checkboxDescriptionGroupContainerMargin: 16px 0 0 0;
$checkboxSubLabelGap: 4px;
$checkboxSubLabelMargin: 8px 0 0 0;
$checkboxGroupTopMargin: 24px;
$checkboxGroupBorderRadius: 24px;
$checkboxGroupGap: 16px;
$textInputContainerTopMargin: 32px;
$errorWrapperTopMargin: 12px;
$errorWrapperGap: 4px;
$errorIconSize: 24px;

$checkboxGroupMobilePadding: 16px;

$checkboxGroupDesktopPadding: 24px;

$groupBottomPaddingMobile: 32px;
$groupBottomPaddingDesktop: 44px;

.checkboxDescriptionGroupContainer {
  margin: $checkboxDescriptionGroupContainerMargin;
}

.label {
  font-family:
    #{$amazonEmberDisplay},
    sans-serif;
  font-size: theme.$fontSizeSubtitle !important;
  line-height: theme.$fontLineHeightSubtitle !important;
  margin: 0 !important;
  color: theme.$green-primary;

  &.error {
    color: theme.$errorColor;
  }
}

.subLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $checkboxSubLabelGap;
  margin: $checkboxSubLabelMargin !important;
}

.subLabelIcon {
  @include theme.size(24px);
  outline: 2px solid theme.$green-primary;
  outline-offset: -3px;
  border-radius: 50%;

  &.error {
    color: theme.$errorColor;
    outline-color: theme.$errorColor;
  }
}

.subLabel {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$green-primary;
  margin: 0 !important;

  &.error {
    color: theme.$errorColor;
  }
}

.checkboxGroup {
  margin-top: $checkboxGroupTopMargin !important;
  padding: $checkboxGroupMobilePadding $checkboxGroupMobilePadding $groupBottomPaddingMobile !important;
  border-radius: $checkboxGroupBorderRadius !important;
  background: theme.$white;
  display: flex;
  flex-direction: column;

  @include theme.small-desktop {
    flex-wrap: wrap;
    padding: $checkboxGroupDesktopPadding $checkboxGroupDesktopPadding $groupBottomPaddingDesktop !important;
  }

  .optionsPrefix {
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 400;
    opacity: 0.85;
    font-family: $amazonEmberMono;
    color: theme.$green-primary;
  }

  &.modal {
    padding: 24px !important;
  }
}

.textInputContainer {
  margin-top: $textInputContainerTopMargin;
  width: 100%;
}

.errorWrapper {
  margin-top: $errorWrapperTopMargin;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $errorWrapperGap;
}

.errorIcon {
  @include theme.size($errorIconSize);
  color: theme.$errorColor;
}

.errorMessage {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
  color: theme.$errorColor;
}
