@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$right-position-mobile: 30px;
$bottom-position-mobile: 30px;
$bottom-position-mobile: 30px;

$right-position-desktop: 60px;
$bottom-position-desktop: 60px;
$bottom-position-desktop: 60px;

.scrollButtonContainer {
  position: sticky;
  bottom: $bottom-position-mobile;
  padding-bottom: $bottom-position-mobile;
  padding-right: $right-position-mobile;
  z-index: website.zindex(website.$zLayout, backToTop);

  @include theme.small-desktop {
    bottom: $bottom-position-desktop;
    padding-right: $right-position-desktop;
    padding-bottom: $bottom-position-desktop;
  }
}

.scrollButton {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  padding-top: 0;
  overflow-y: hidden;

  transition-property: opacity, transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  opacity: 0;
  transform: translateY(60px);
  pointer-events: none;
}

.visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}
