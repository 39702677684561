@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$marginSmall: 10px;
$marginMedium: 16px;
$marginLarge: 32px;

$marginBottomDevice: 48px;
$marginBottomDesktop: 68px;

$borderRadiusSmall: 12px;
$borderRadiusMedium: 16px;
$borderRadiusLarge: 24px;

$imageDesktopFixedDimension: 180px;
$imageDeviceFixedDimension: 88px;

$minScreenWidth: 414px;

$fontLineHeightCardHeader: fluid-size(24px, 40px, theme.$minViewportWidth, theme.$maxViewportWidth);

.card {
  height: 100%;
  border-radius: theme.$border-radius-small;
  width: 100%;
  &:not(.rateable) {
    cursor: pointer;
  }

  .cardHeader {
    display: flex;
    align-items: center;
    gap: $marginMedium;
    margin-bottom: $marginBottomDevice;

    @include theme.small-desktop {
      margin-bottom: $marginBottomDesktop;
    }

    .cardPill,
    .details {
      font-family: #{$amazonEmberMono};
      display: inline-block;
      font-size: #{theme.$fontSizeLabel};
      font-weight: 400;
      color: #{theme.$green-primary};
      &.dark {
        color: #{theme.$green-secondary};
      }
    }

    .cardPill {
      background-color: theme.$white;
      border-radius: $borderRadiusSmall;
      padding: $marginSmall $marginMedium $marginSmall $marginMedium;
      &.dark {
        background-color: rgba(theme.$white, 0.05);
      }
    }
  }

  .cardContent {
    padding: 0 0 $marginMedium 0 !important;
    height: 100%;
    color: #{theme.$green-primary};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cardText {
      .cardHeading {
        @include theme.small-desktop {
          margin-bottom: $marginMedium !important;
        }
      }
      .cardSubtitle {
        height: 40%;
        font-size: #{theme.$fontSizeBodySmall};
        line-height: #{theme.$fontLineHeightBodySmall};
        margin: 0 0;
      }
    }

    .rateButtonContainer {
      position: relative;
      margin-top: 5%;
      margin-bottom: auto;
    }

    .cardImage {
      width: theme.fluid-size(
        $imageDeviceFixedDimension,
        $imageDesktopFixedDimension,
        $minScreenWidth,
        theme.$maxViewportWidth
      );
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: $borderRadiusMedium;

      @include theme.small-desktop {
        border-radius: $borderRadiusLarge;
      }
    }

    @include theme.small-desktop {
      padding: 0 0 $marginLarge !important;
      h3 {
        font-size: 40px !important;
        line-height: 40px !important;
      }
    }

    &.dark {
      background-color: #{theme.$slate-light};
      color: #{theme.$green-secondary};

      .cardSubtitle {
        color: white;
      }

      h3 {
        &.dark {
          background-color: #{theme.$slate-light};
          color: #{theme.$green-secondary};
        }
      }
    }
  }

  .cardFooter {
    &.rateable {
      cursor: pointer;
    }
    width: 100%;
    border-top: 1px solid rgba(theme.$green-primary, 0.2);
    padding-top: $marginMedium;
    display: flex;
    align-items: center;
    font-family: #{$amazonEmberDisplay};

    &.dark {
      border-top: 1px solid rgba(theme.$white, 0.2);
    }

    .link {
      font-weight: 700;
      font-size: #{theme.$fontSizeCta};
      line-height: #{theme.$fontLineHeightCta};
      margin: 0;

      &.dark {
        color: #{theme.$green-secondary};
      }
      &.light {
        color: #{theme.$green-primary};
      }
    }

    .cardActionButton {
      margin-left: auto;
    }
  }
}

kat-card {
  --background: #{theme.$grey-primary};
  --color: #{theme.$green-primary};
  --kat-text-link: #{theme.$green-primary};
  --kat-text-link-hover: #{theme.$green-primary};
  --border-style: none;
  --box-shadow: none;
  --base-gap: 0;
  --base-padding: #{$marginMedium};

  &.dark {
    --background: #{theme.$slate-light};
    --color: #{theme.$green-secondary};
    --kat-text-link: #{theme.$green-secondary};
    --kat-text-link-hover: #{theme.$green-secondary};
  }

  @include theme.small-desktop {
    --base-padding: #{$marginLarge};
  }
}
