@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$textInputWrapperGap: 40px;
$textInputIconSize: 24px;
$errorWrapperTopMargin: 12px;
$errorWrapperGap: 4px;
$errorIconSize: 24px;

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: $textInputWrapperGap;
  border-bottom: 1px solid rgba(theme.$green-primary, 0.65);

  // TODO: add this color to theme package;
  &.error {
    border-color: rgba(theme.$errorColor, 0.65);
  }

  &.disabled {
    border-color: rgba(theme.$slate-dark, 0.4);
  }

  *.error {
    color: theme.$errorColor;
  }

  *.disabled {
    color: rgba(theme.$slate-dark, 0.4);
    pointer-events: none;
    cursor: pointer;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-family:
    #{$amazonEmberDisplay},
    sans-serif;
  font-size: #{theme.$fontSizeSubtitle};
  line-height: #{theme.$fontLineHeightSubtitle};
  color: theme.$green-primary;
  opacity: 0.85;
  transition: top ease-in-out 0.2s;

  &.active {
    font-family: $amazonEmberMono;
    font-size: theme.$fontSizeLabel;
    line-height: theme.$fontLineHeightLabel;
  }
}

.textInput {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  font-size: #{theme.$fontSizeSubtitle};
  line-height: #{theme.$fontLineHeightSubtitle};
  color: theme.$green-primary;
  background: transparent;

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.textInputIcon {
  @include theme.size($textInputIconSize);
  color: theme.$green-primary;
}

.errorWrapper {
  margin-top: $errorWrapperTopMargin;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $errorWrapperGap;
}

.errorIcon {
  @include theme.size($errorIconSize);
  color: theme.$errorColor;
}

.errorMessage {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
  color: theme.$errorColor;
}
