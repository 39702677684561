@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import "plyr-react/plyr.css";

$gradientGray: #090C11;

$plyrControlSpacing: 32px;
$plyrControlIconSize: 24px;
$controlsGap: 4px;
$playButtonWidth: 80px;
$playButtonHeight: 56px;
$playButtonBorderRadius: 32px;
$buttonSize: 40px;
$volumeSliderTop: -32px;
$volumeSliderLeft: -8px;

$mobileControlsPadding: 24px;
$mobileProgressInputPadding: 16px 0 0 0;

// These styles are for when the transcription is visible
$mobileTranscriptionEnabledTop: 88px;
$mobileTranscriptionEnabledBorderRadius: 24px;
// This 27.45vh was calculated according to the size of the video in the mobile mocks, we need it to be able to maintain an aspect ratio
$mobileTranscriptionEnabledMaxHeight: 27.45vh;

$desktopControlsPadding: 32px;
$desktopProgressBarPadding: 0 0 0 32px;
$desktopProgressInputPadding: 16px 0;
// These styles are for when the transcription is visible
$desktopTranscriptionEnabledMaxHeight: 696px;
$desktopTranscriptionEnabledControlsPaddingBottom: 32px;

:global(.plyr) {
  --plyr-video-background: #{theme.$slate-dark};
  --plyr-color-main: #{theme.$green-secondary};
  --plyr-control-spacing: #{$plyrControlSpacing};
  --plyr-control-icon-size: #{$plyrControlIconSize};
  --plyr-range-thumb-shadow: 0;
  --plyr-range-thumb-active-shadow-width: 0;
  --plyr-video-control-background-hover: transparent;
  --plyr-video-control-color-hover: #{theme.$green-secondary};
  --plyr-video-controls-background: #{linear-gradient(360deg, rgba($gradientGray, 0.7) 0.01%, rgba($gradientGray, 0.55) 45.26%, rgba($gradientGray, 0) 100%)};
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include theme.small-desktop {
    --plyr-video-background: transparent;
  }

  :global(.plyr__video-wrapper) {
    position: absolute;
    width: 100%;
    height: 100%;

    @include theme.small-desktop {
      position: relative;
    }
  }

  [data-plyr="controls"] {

    position: static;
    width: 100%;
    padding: $mobileControlsPadding;
    flex-direction: column;
    gap: $controlsGap;
    transform: none !important;

    @include theme.small-desktop {
      padding: $desktopControlsPadding;
      position: absolute;
      flex-direction: row;
      bottom: 0;
      left: 0;
    }

    [data-plyr="play"] {
      width: $playButtonWidth;
      height: $playButtonHeight;
      border-radius: $playButtonBorderRadius;
      background-color: theme.$green-secondary;
      color: theme.$green-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include theme.small-desktop {
        position: static;
        transform: none;
      }
  
      &:hover {
        background-color: theme.$green-secondary;
        color: theme.$green-primary;
      }
    }
    
    [data-plyr="progress"] {

      margin: 0;
      padding: 0;
      width: 100%;

      @include theme.small-desktop {
        margin: $desktopProgressBarPadding
      }

      [data-plyr="seek"] {
        // This padding is not visible but it makes clickable area bigger
        padding: $mobileProgressInputPadding;
        cursor: pointer;
  
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          background: transparent;
          box-shadow: none !important;
        }

        @include theme.small-desktop {
          padding: $desktopProgressInputPadding;
        }
      }
  
      [role="progressbar"]::-webkit-progress-value {
        background: transparent;
      }
    }

    [data-plyr="mobile-controls"] {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      div {
        display: flex;
        flex-direction: row;
      }
    }
  
    [data-plyr="current-time"] {
      pointer-events: none;
    }
  
    [data-plyr="volume-control"] {
  
      [data-plyr="mute"] {
        @include theme.size($buttonSize);
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      [data-plyr="volume"] {
        display: none;
        position: absolute;
        top: $volumeSliderTop;
        transform: rotate(-90deg);
        transform-origin: bottom center;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        margin: 0;
        left: $volumeSliderLeft;

        @include theme.small-desktop {
          display: block;
        }
  
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          background: transparent;
          box-shadow: none;
        }
      }
  
      &:hover,
      &:focus-within {
        [data-plyr="volume"] {
          opacity: 1;
          cursor: pointer;
          pointer-events: auto;
          visibility: visible;
        }
      }
    }
  
    [data-plyr="captions"], [data-plyr="transcript"] {
        padding: 0;
        margin: 0;
        @include theme.size($buttonSize);
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
}

:global(.plyr--transcription--enabled) {
  position: absolute;
  top: $mobileTranscriptionEnabledTop;
  // This 16px in the calc comes from the 8px padding that the video has on the sides, since it's absolutely positioned
  // we can't use padding itself so we make it smaller and it centers automatically
  width: calc(100% - 16px);
  max-height: $mobileTranscriptionEnabledMaxHeight;
  border-radius: $mobileTranscriptionEnabledBorderRadius;
  z-index: 1;

  @include theme.small-desktop {
    position: relative;
    top: 0;
    width: unset;
    height: fit-content;
    max-height: $desktopTranscriptionEnabledMaxHeight;
    aspect-ratio: 1228 / 696;

    video {
      object-fit: cover;
    }
  }

  [data-plyr="controls"] {
    padding-bottom: 0;

    @include theme.small-desktop {
      padding-bottom: $desktopTranscriptionEnabledControlsPaddingBottom;
    }
  }
}