@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$spacingMobileDevice: 12px;
$spacingXLargeDesktop: 24px;

$xLargeDesktop: 1920px;

$mobileHeight: 448px;
$deviceHeight: 700px;
$desktopTopOffset: 110px;

$maxHeightBgImagesVisibility: 1024px;

$gridMobileColumnGap: 20px;
$gridSmallDesktopColumnGap: 24px;

$mobileSpacing: 0 25px;
$smallDesktopSpacing: 0 60px;

$firstCardSmallDesktopGridPosition: #{2} / #{1} / #{-1} / #{5};

$secondCardMobileGridPosition: #{2} / #{3} / #{-1} / #{-1};
$secondCardMediumDeviceGridPosition: #{2} / #{4} / #{-1} / #{-1};
$secondCardSmallDesktopGridPosition: #{2} / #{22} / #{-1} / #{24};

$thirdCardMediumDeviceGridPosition: #{11} / #{4} / #{-1} / #{-1};
$thirdCardSmallDesktopGridPosition: #{7} / #{3} / #{-1} / #{7};

$fourthCardSmallDesktopGridPosition: #{7} / #{19} / #{-1} / #{23};

$contactFormFirstCardMobileGridPosition: #{10} / #{1} / #{-1} / #{2};
$contactFormFirstCardLargeDeviceGridPosition: #{10} / #{1} / #{-1} / #{2};
$contactFormSecondCardLargeDeviceGridPosition: #{3} / #{5} / #{-1} / #{-1};
$contactFormThirdCardMobileGridPosition: #{11} / #{3} / #{-1} / #{-1};
$contactFormThirdCardLargeDeviceGridPosition: #{10} / #{5} / #{-1} / #{-1};

$creditsInterestFirstCardMobileGridPosition: #{11} / #{1} / #{-1} / #{2};
$creditsInterestSecondCardLargeDeviceGridPosition: #{3} / #{11} / #{10} / #{11};
$creditsInterestThirdCardMobileCardGridPosition: #{11} / #{4} / #{-1} / #{-1};;
$creditsInterestThirdCardLargeDeviceGridPosition: #{9} / #{6} / #{-1} / #{12};

.imagesBackground {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(12, 1fr);
  padding: $mobileSpacing;
  column-gap: $gridMobileColumnGap;

  @include theme.medium-device {
    grid-template-columns: repeat(4, 1fr);
  }

  @include theme.large-device {
    grid-template-columns: repeat(5, 1fr);
  }

  @include theme.small-desktop {
    top: $desktopTopOffset;
    grid-template-columns: repeat(24, 1fr);
    padding: $smallDesktopSpacing;
    column-gap: $gridSmallDesktopColumnGap;
  }
}

.creditsInterestFormImages {
  border-radius: 20px;
  width: 100%;
  object-fit: cover;

  @include theme.medium-desktop {
    border-radius: 32px;
  }

  // First image
  &:nth-child(1) {
    aspect-ratio: 1 / 1;
    grid-area: $creditsInterestFirstCardMobileGridPosition;

    @include theme.small-desktop {
      aspect-ratio: 1.41 / 1;
      grid-area: $firstCardSmallDesktopGridPosition;
    }
  }

  // Second image
  &:nth-child(2) {
    aspect-ratio: 1 / 1;
    grid-area: $secondCardMobileGridPosition;
    display: none;

    @media (max-height: $maxHeightBgImagesVisibility) and (orientation: landscape) {
      display: none;
    }

    @include theme.medium-device {
      grid-area: $secondCardMediumDeviceGridPosition;
    }

    @include theme.large-device {
      display: block;
      grid-area: $creditsInterestSecondCardLargeDeviceGridPosition;
    }

    @include theme.small-desktop {
      grid-area: $secondCardSmallDesktopGridPosition;
    }
  }

  // Third image
  &:nth-child(3) {
    aspect-ratio: 1 / 1;
    grid-area: $creditsInterestThirdCardMobileCardGridPosition;

    @include theme.medium-device {
      grid-area: $thirdCardMediumDeviceGridPosition;
    }

    @include theme.large-device {
      grid-area: $creditsInterestThirdCardLargeDeviceGridPosition;
    }

    @include theme.small-desktop {
      aspect-ratio: 4 / 3;
      grid-area: $thirdCardSmallDesktopGridPosition;
    }
  }

  //  Fourth image
  &:nth-child(4) {
    display: none;

    @media (max-height: $maxHeightBgImagesVisibility) and (orientation: landscape) {
      display: none;
    }

    @include theme.small-desktop {
      display: block;
      grid-area: $fourthCardSmallDesktopGridPosition;
      aspect-ratio: 1.34 / 1;
      width: 76%;
      justify-self: end;
      border-radius: 20px;
    }

    @include theme.medium-desktop {
      border-radius: 40px;
    }
  }
}

  .contactFormImages {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;

    @include theme.medium-desktop {
      border-radius: 32px;
    }

    // First image
    &:nth-child(1) {
      aspect-ratio: 1 / 1;
      grid-area: $contactFormFirstCardMobileGridPosition;

      @include theme.large-device {
        grid-area: $contactFormFirstCardLargeDeviceGridPosition;
      }

      @include theme.small-desktop {
        aspect-ratio: 1.41 / 1;
        grid-area: $firstCardSmallDesktopGridPosition;
      }
    }

    // Second image
    &:nth-child(2) {
      aspect-ratio: 1 / 1;
      grid-area: $secondCardMobileGridPosition;
      display: none;

      @media (max-height: $maxHeightBgImagesVisibility) and (orientation: landscape) {
        display: none;
      }

      @include theme.medium-device {
        grid-area: $secondCardMediumDeviceGridPosition;
      }

      @include theme.large-device {
        display: block;
        grid-area: $contactFormSecondCardLargeDeviceGridPosition;
        width: 50%;
      }

      @include theme.small-desktop {
        grid-area: $secondCardSmallDesktopGridPosition;
        width: 100%
      }
    }

    // Third image
    &:nth-child(3) {
      aspect-ratio: 1 / 1;
      grid-area: $contactFormThirdCardMobileGridPosition;

      @include theme.medium-device {
        grid-area: $thirdCardMediumDeviceGridPosition;
      }

      @include theme.large-device {
        grid-area: $contactFormThirdCardLargeDeviceGridPosition;
        width: 80%;
      }

      @include theme.small-desktop {
        aspect-ratio: 4 / 3;
        grid-area: $thirdCardSmallDesktopGridPosition;
        width: 100%
      }
    }

    //  Fourth image
    &:nth-child(4) {
      display: none;

      @media (max-height: $maxHeightBgImagesVisibility) and (orientation: landscape) {
        display: none;
      }

      @include theme.small-desktop {
        display: block;
        grid-area: $fourthCardSmallDesktopGridPosition;
        aspect-ratio: 1.34 / 1;
        width: 76%;
        justify-self: end;
        border-radius: 20px;
      }

      @include theme.medium-desktop {
        border-radius: 40px;
      }
    }
  }
