@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$checkboxSize: 16px;
$checkmarkBorderRadius: 4px;
$checkmarkTransitionDuration: 0.2s;
$checkmarkRightMargin: 16px;
$checkmarkRightMarginMobile: 12px;
$iconPadding: 1px;

$roundCheckmarkBottomMargin: 12px;

$errorColor: #cf1b24;

.checkmarkWrapper {
  display: inline-flex;
}

.checkmark {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $checkboxSize;
  height: $checkboxSize;
  background-color: transparent;
  transition: background-color 0.2s;
  margin-inline-end: $checkmarkRightMarginMobile;
  border: 1px solid rgba(theme.$green-primary, 0.65);

  @include theme.small-desktop {
    margin-inline-end: $checkmarkRightMargin;
  }
}

.square {
  border-radius: $checkmarkBorderRadius;

  &.checked {
    background-color: theme.$green-primary;
  }
}

.round {
  margin-right: $roundCheckmarkBottomMargin;
  border-radius: 100%;
}

.icon {
  display: flex;
  padding: $iconPadding;
  color: transparent;
  width: $checkboxSize * 0.8;
  height: $checkboxSize * 0.8;
  opacity: 1;
  transition: opacity $checkmarkTransitionDuration;

  &.checked {
    color: theme.$green-secondary;
  }
}

.error {
  border-color: $errorColor;
}
