@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';
@import '../../../index.scss';

$paddingDevice: 16px;
$paddingDesktop: 24px;

.anchorPill {
  display: inline-block;
  font-family: $amazonEmberMono;
  font-size: $fontSizeLabel;
  line-height: $fontLineHeightLabel;
  color: $green-primary !important;
  padding: $paddingDevice;
  padding-left: 24px;
  border-radius: $border-radius-medium;
  background-color: $white !important;
  white-space: nowrap;

  @include small-desktop {
    background-color: unset;
    padding: $paddingDesktop;
    padding-left: 38px;
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px $green-primary;
  }

  &.active {
    background-color: $green-secondary !important;
    &:focus {
      outline: none;
      box-shadow:
        inset 0 0 0 2px $green-secondary,
        inset 0 0 0 3px $green-primary;
    }
  }
}
