@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';

$margin: 16px;
$paddingSmall: 8px;
$paddingLarge: 20px;
$paddingDevice: 24px;
$dropdwonHeight: 48px;
$fluidPadding: 200px;
$dropdownNegativeMargin: -15px;
$marginTopDesktop: 135px;

.AccordionsComponent {
  display: flex;
  flex-direction: column;

  @include small-desktop {
    margin-top: $marginTopDesktop;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  @include large-device {
    flex-direction: row;
  }
}

.tabSelectionPills {
  button:not(:last-child) {
    margin-bottom: $margin;
  }
}

.dropdownWrapper {
  display: flex;
  height: calc($dropdwonHeight);
  justify-content: center;
  align-items: center;

  position: relative;
  margin: $paddingLarge $dropdownNegativeMargin;

  &::after {
    @include pseudo;
    height: 1px;
    bottom: -$paddingLarge;
    width: calc(100% + $paddingDevice * 2) !important;
  }
  &.dark {
    &::after {
      background-color: rgba($grey-secondary, 0.2);
    }
  }
  &.light {
    &::after {
      background-color: rgba($green-primary, 0.2);
    }
  }
}

.dropdown {
  position: absolute !important;
  width: calc(100%) !important;
}

.accordions {
  width: 100%;

  @include large-device {
    padding-left: $fluidPadding;
  }
}
