@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$deviceNavBar: 72px;

$minScreenWidth: 414px;
$fullHeight: calc((1svh * 100) - $deviceNavBar);

$paddingXSmall: 8px;
$paddingSmall: 16px;
$paddingMedium: 24px;
$paddingLarge: 112px;
$paddingXLarge: 120px;

$paddingBottom: 74px;

$marginSmall: 20px;
$marginMedium: 32px;
$marginLarge: 56px;
$marginXLarge: 60px;

$gradientFluidWidth: 37vw;
$gradientFluidHeight: calc(1svh * 65);
$gradientBackground: radial-gradient(
  49.81% 40.98% at 50.15% 49.85%,
  rgba(32, 22, 12, 0.9) 0%,
  rgba(32, 22, 12, 0.62) 35.69%,
  rgba(32, 22, 12, 0) 100%
);
$gradientFluidWidthDesktop: 54vw;

$borderRadiusSmall: 24px;
$borderRadiusLarge: 56px;

$minCardWidth: 364px;
$minCardHeight: 736px;

$cardFooterFluidWidth: 33vw;
$redirectionCardMaxWidth: 460px;

.PrimaryHero {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-block-size: $fullHeight;
  inline-size: 100%;
  object-fit: cover;
  padding-block-end: $paddingMedium;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: theme.zindex(website.$zLayout, hero);
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inline-size: $gradientFluidWidth;
    block-size: $gradientFluidHeight;
    background: $gradientBackground;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 70%);
    z-index: -1;
  }

  @include theme.small-desktop {
    min-block-size: unset;
    padding-block-start: $paddingLarge;
    padding-block-end: $paddingBottom;
    background-position: top;
    &::after {
      right: 0;
      left: unset;
      transform: translate(50%, 55%);
      inline-size: $gradientFluidWidthDesktop;
    }
  }
}

.mainBox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-inline: $paddingMedium;

  @include theme.small-desktop {
    flex-direction: row;
    align-items: flex-end;
    gap: $paddingSmall;
    padding: 0 $marginXLarge;
  }
}

.mainCard {
  background: theme.$grey-secondary;
  border-radius: $borderRadiusSmall;
  color: theme.$green-primary;
  display: flex;
  flex-direction: column;
  margin-block-end: $paddingXSmall;
  padding: $paddingMedium $paddingSmall;

  @include theme.small-desktop {
    border-radius: $borderRadiusLarge;
    padding: $marginMedium $marginMedium $marginLarge;
    margin-block-end: 0;
    inline-size: theme.fluid-size($minCardWidth, $minCardHeight, $minScreenWidth, theme.$maxViewportWidth);
  }
}

.tagline {
  font-family: website.$amazonEmberMono !important;
  margin-bottom: $paddingXSmall;
  @include theme.small-desktop {
    margin-bottom: $paddingSmall;
  }
}

.heading {
  block-size: fit-content;
  max-width: 70vw;
  margin: 0 0 $marginMedium !important;

  @include theme.small-desktop {
    max-width: 36vw;
  }
}

.cardFooter {
  padding-top: $marginSmall;
  border-top: rgba(theme.$green-primary, 0.4) dashed 1px;

  @include theme.small-desktop {
    padding-top: $paddingMedium;
    max-width: $cardFooterFluidWidth;
  }
}

.redirectionCard {
  @include theme.small-desktop {
    max-width: $redirectionCardMaxWidth;
  }
}

.label {
  display: inline-block;
  font-size: theme.fluid-size(14px, 16px, $minScreenWidth, theme.$maxViewportWidth) !important;
  line-height: theme.fluid-size(20px, 24px, $minScreenWidth, theme.$maxViewportWidth) !important;
  margin: 0 !important;
}

.scrollDownLabel {
  font-family: website.$amazonEmberMono !important;
  margin-block-start: $paddingMedium !important;
  margin-bottom: 0 !important;
  text-align: center;
  color: theme.$grey-secondary;
  font-size: theme.$fontSizeBodySmall !important;
  line-height: theme.$fontLineHeightBodySmall !important;
  align-self: center;

  @include theme.small-desktop {
    display: none;
  }
}
