@use "~@amzn/sss-website-theme/dist/theme/index.module.scss" as theme;
@use "sass:math";

$aspectRatio: math.div(16, 9);

$imageHeightDevice: 274px;
$imageHeightDesktop: 520px;

$paddingSmall: 16px;
$horizontalPadding: $paddingSmall * 2;
$paddingMedium: 24px;
$paddingLarge: 48px;
$paddingXLarge: 80px;

$background: rgba(theme.$grey-secondary, 0.2);
$linePositionDevice: 88px;
$linePositionDesktop: 96px;

$borderWitdthSmall: 24px;

$minScreenSize: 414px;

// At this breakpoint is where the card should have it's height set to 100%, smaller screens than this
// need a fixed height to avoid the content overflowing the card due to it being too small
$cardHeightBreakpoint: 536px;
$cardDeviceHeight: 274px;

$fontSizeDevice: 40px;
$fontSizeDesktop: 88px;
$lineHeightDevice: 48px;
$lineHeightDesktop: 96px;

.TeaserCardImage {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: $aspectRatio;

  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: $borderWitdthSmall;
    filter: brightness(60%);

    @include theme.large-device {
      border-radius: theme.$border-radius-medium;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    padding: theme.fluid-size($paddingLarge, $paddingXLarge, $minScreenSize, theme.$maxViewportWidth)
      theme.fluid-size($paddingSmall, $paddingMedium, $minScreenSize, theme.$maxViewportWidth)
      theme.fluid-size($paddingSmall, $paddingMedium, $minScreenSize, theme.$maxViewportWidth);
    z-index: 1;
    position: relative;
    width: 100%;
    height: $cardDeviceHeight;
    justify-content: space-between;

    @media screen and (min-width: $cardHeightBreakpoint) {
      height: 100%;
    }
  }

  .divider {
    border: none;
    position: absolute;
    width: calc(100% - $horizontalPadding);
    height: 1.1px;
    background-color: $background;
    opacity: 0.8;
    bottom: $linePositionDevice;
    display: block;

    @include theme.large-device {
      width: calc(100% - $paddingMedium * 2);
      bottom: $linePositionDesktop;
    }
  }

  .cardTitle {
    color: theme.$grey-secondary;
    margin: 0;
  }

  .ctaWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cta {
    font-size: theme.$fontSizeCta;
    line-height: theme.$fontLineHeightCta;
    color: theme.$grey-secondary !important;
  }
}
