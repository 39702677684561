@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$minScreenSize: 414px;
$maxScreenSize: 1920px;

$marginXSmall: 44px;
$marginSmall: 48px;
$marginLarge: 64px;

$backgroundDark: rgba(theme.$grey-secondary, 0.1);
$backgroundLight: rgba(theme.$green-primary, 0.1);

$bannerGapMax: 195px;

$headingMaxSizeDesktop: 888px;

$imagesGapDevice: 10px;
$imagesGapDesktop: 22px;

$logoHeightMin: 124px;
$logoHeightMax: 191px;

$logoPaddingTopDevice: 39px;
$logoPaddingRightDevice: 30px;
$logoPaddingTopDesktop: 58px;
$logoPaddingRightDesktop: 44px;

$logoOpacity: 0.4;

$imageSizeMin: 230px;
$imageSizeMax: 376px;
$imageMarginDevice: 55px;
$imageMarginDesktop: 80px;
$imageContainerShrink: 0;

.smallBanner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include theme.large-device {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: theme.fluid-size($marginLarge, $bannerGapMax, theme.$largeDevice, $maxScreenSize);
  }

  .copyContainer {
    flex: 1;
    flex-basis: 100%;
    @include theme.large-device {
      flex-basis: 0;
      margin-block-start: $marginXSmall;
    }
  }

  .heading {
    flex: 1;

    @include theme.large-device {
      max-inline-size: $headingMaxSizeDesktop;
    }
  }

  .cta {
    display: inline-block;
    margin-block-start: $marginSmall;

    @include theme.large-device {
      margin-block-start: $marginLarge;
    }
  }

  .imagesContainer {
    margin-block-start: $marginLarge;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: $imagesGapDevice;

    @include theme.large-device {
      margin-block-start: 0;
      gap: $imagesGapDesktop;
    }
  }

  .logoContainer {
    block-size: theme.fluid-size($logoHeightMin, $logoHeightMax, $minScreenSize, $maxScreenSize);
    inline-size: theme.fluid-size($logoHeightMin, $logoHeightMax, $minScreenSize, $maxScreenSize);
    position: relative;
    padding: $logoPaddingTopDevice $logoPaddingRightDevice;
    border-radius: theme.$border-radius-small;

    &::before {
      content: '';
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      inline-size: 100%;
      block-size: 100%;
      border-radius: theme.$border-radius-small;
      opacity: $logoOpacity;
    }

    @include theme.large-device {
      border-radius: theme.$border-radius-medium;
      padding: $logoPaddingTopDesktop $logoPaddingRightDesktop;

      &::before {
        border-radius: theme.$border-radius-medium;
      }
    }
  }

  .logo,
  .image {
    @include theme.size(100%);
  }

  .imageContainer {
    block-size: theme.fluid-size($imageSizeMin, $imageSizeMax, $minScreenSize, $maxScreenSize);
    inline-size: theme.fluid-size($imageSizeMin, $imageSizeMax, $minScreenSize, $maxScreenSize);
    margin-block-start: $imageMarginDevice;

    flex-shrink: $imageContainerShrink;
    border-radius: theme.$border-radius-small;

    @include theme.large-device {
      margin-block-start: $imageMarginDesktop;
      border-radius: theme.$border-radius-medium;
    }
  }

  .image {
    border-radius: theme.$border-radius-small;

    @include theme.large-device {
      border-radius: theme.$border-radius-medium;
    }
  }

  &.dark {
    .heading {
      color: theme.$green-secondary !important;
    }
    .logoContainer {
      &::before {
        background: $backgroundDark;
      }
    }
    .logo {
      color: theme.$white;
    }
  }

  &.light {
    .heading {
      color: theme.$green-primary !important;
    }
    .logoContainer {
      &::before {
        background: $backgroundLight;
      }
    }
    .logo {
      color: theme.$green-primary;
    }
  }
}
