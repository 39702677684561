@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$heroBannerCtaTopMargin: 40px;
$heroBannerImageSlideshowGap: 100px;
$heroBannerSlideImageBorderRadius: 40px;

$deviceNavbar: 72px;
$mobileHeroBannerTopPadding: 160px;
$mobileHeroBannerRightPadding: 25px;
$mobileHeroBannerBottomPadding: 72px;
$mobileHeroBannerLeftPadding: 25px;
$mobileHeroBannerTitleFontSize: 52px;
$mobileHeroBannerTitleLineHeight: 56px;
$mobileHeroBannerSubtitleMargin: 48px 0 0 0;
$mobileHeroBannerCtaPadding: 20px 24px 20px 32px;
$mobileHeroBannerScrollDownMargin: 140px 0 72px 0;

$desktopNavbar: 96px;
$desktopHeroBannerTitleFontSize: 140px;
$desktopHeroBannerTitleLineHeight: 150px;
$desktopHeroBannerSubtitleMargin: 80px 0 0 0;
$dekstopHeroBannerCtaPadding: 28px 32px 28px 40px;
$desktopHeroBannerScrollDownMargin: 132px 0 0 0;
$desktopHeroBannerVideoWithPreviewsWrapperPadding: 0 32px;

.animatedHeroBanner {
  height: calc(100vh - $deviceNavbar);
  width: 100%;
  padding: $mobileHeroBannerTopPadding $mobileHeroBannerRightPadding $mobileHeroBannerBottomPadding $mobileHeroBannerLeftPadding;
  background: theme.$slate-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;

  @include theme.small-desktop {
    height: calc(100vh - $desktopNavbar);
    padding: 0;
  }
}

.title {
  // For some reason in Figma we have a specific font size for this title that doesn't fit our current heading hierarchy
  font-size: theme.fluid-size($mobileHeroBannerTitleFontSize,$desktopHeroBannerTitleFontSize,theme.$minViewportWidth, theme.$maxViewportWidth) !important;
  line-height: theme.fluid-size($mobileHeroBannerTitleLineHeight,$desktopHeroBannerTitleLineHeight,theme.$minViewportWidth, theme.$maxViewportWidth) !important;
  color: theme.$grey-secondary;
  margin: 0 !important;
}

.titleFragment {
  color: theme.$green-secondary;
}

.subtitle {
  font-size: theme.$fontSizeSubtitle !important;
  line-height: theme.$fontLineHeightSubtitle !important;
  color: theme.$grey-secondary;
  margin: $mobileHeroBannerSubtitleMargin !important;

  @include theme.small-desktop {
    margin: $desktopHeroBannerSubtitleMargin !important;
  }
}

.cta {
  margin-top: $heroBannerCtaTopMargin;
  font-size: theme.$fontSizeCta;
  line-height: theme.$fontLineHeightCta;
  padding: $mobileHeroBannerCtaPadding !important;
  flex-shrink: 0;

  @include theme.small-desktop {
    padding: $dekstopHeroBannerCtaPadding !important;
  }
}

.scrollDown {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$grey-secondary;
  margin: $mobileHeroBannerScrollDownMargin !important;

  @include theme.small-desktop {
    margin: $desktopHeroBannerScrollDownMargin !important;
  }
}

.animationContainer {
  height: 100vh;
  width: 100%;
  pointer-events: none;

  &.hidden {
    pointer-events: all;
  }
}

.imageSlideshowWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $heroBannerImageSlideshowGap;
  position: relative;
  z-index: 2;
  
  &.hidden {
    background: theme.$slate-dark;
  }
}

.imageSlideContainer {
  height: 40vh;
  width: 40vw;

  &.hidden {
    display: none;
  }
}

.slideImage {
  height: 100%;
  width: 100%;
  border-radius: $heroBannerSlideImageBorderRadius;
}

.finalSlideContainer {
  height: 100vh;
  width: 100%;
  background: theme.$slate-dark;
}

.videoWithPreviewsWrapper {
  @extend .finalSlideContainer;

  @include theme.small-desktop {
    padding: $desktopHeroBannerVideoWithPreviewsWrapperPadding;
  }
}