@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$mobileBackButtonContainerBottomMargin: 32px;
$mobileContactFormWrapperGap: 64px;

$desktopBackButtonContainerBottomMargin: 60px;

.backButtonContainer {
  margin-bottom: $mobileBackButtonContainerBottomMargin;
  @include theme.medium-device {
    margin-bottom: theme.fluid-size(
      $mobileBackButtonContainerBottomMargin,
      $desktopBackButtonContainerBottomMargin,
      theme.$mediumDevice,
      theme.$maxViewportWidth
    );
  }
}

.backButton {
  font-size: theme.$fontSizeCta;
  line-height: theme.$fontLineHeightCta;
}
