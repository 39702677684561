@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$dropdownMobileMinHeight: 56px;
$dropdownMobilePadding: 16px 16px 16px 24px;

$dropdownBorderRadius: 12px;
$dropdownOutlineOffset: -4px;
$dropdownIconSize: 24px;
$dropdownMenuBorderRadius: 12px;
$dropdownMenuTopMargin: 4px;
$dropdownOptionContainer: 8px 24px 24px;
$dropdownOptionPadding: 12px 12px;
$dropdownMaxWidthDesktop: 684px;

$dropdownDesktopHeight: 64px;
$dropdownDesktopPadding: 16px 20px 16px 24px;

$errorIconSize: 24px;
$maxMobileWidth: 87vw;

$checkboxSubLabelGap: 4px;
$checkboxSubLabelMargin: 12px 0 28px;

$errorColor: #cf1b24;
$errorWrapperTopMargin: 12px;
$errorWrapperGap: 4px;
$disabledBackgroundColor: rgba(35, 47, 62, 0.15);
$disabledTextColor: rgba(35, 47, 62, 0.30);

.dropdownInputMultiSelectionContainer {
  position: relative;

  &.error {
    .label,
    .subLabel,
    .selectedOption {
      color: $errorColor;
    }

    .dropdown {
      border-color: rgba($errorColor, 0.65);

      &:focus-within {
        border: 1px solid $errorColor;
        outline: 1px solid $errorColor;
      }
    }
  }

  .label {
    font-family: $amazonEmberDisplay;
    font-size: #{theme.$fontSizeSubtitle};
    line-height: #{theme.$fontLineHeightSubtitle};
    margin-bottom: 24px;
    color: theme.$green-primary;
  }

  .dropdown {
    position: relative;
    background: theme.$white;
    border: 1px solid rgba(theme.$green-primary, 0.65);
    border-radius: $dropdownBorderRadius;
    padding: $dropdownMobilePadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: $dropdownMobileMinHeight;
    cursor: pointer;
    white-space: nowrap;
    min-width: 85vw;
    max-width: $maxMobileWidth;

    &:focus-within {
      border: 1px solid theme.$green-primary;
      outline: 1px solid theme.$green-primary;
      outline-offset: $dropdownOutlineOffset;
    }

    &:disabled {
      border: 1px solid $disabledBackgroundColor;
      background: $disabledBackgroundColor;
      cursor: not-allowed;
      .selectedOption {
        color: $disabledTextColor
      }
    }

    @include theme.medium-device {
      min-width: unset;
      width: 100%;
      max-width: $dropdownMaxWidthDesktop
    }

    @include theme.small-desktop {
      padding: $dropdownDesktopPadding;
      height: $dropdownDesktopHeight;
    }
  }

  .selectedOption,
  .counter {
    font-size: #{theme.$fontSizeSubtitle} !important;
    line-height: #{theme.$fontLineHeightSubtitle} !important;
    color: theme.$green-primary;
    margin: 0 !important;
  }

  .selectedOption {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .leftSideWrapper {
    display: flex;
  }

  .icon {
    @include theme.size($dropdownIconSize);
    transform: rotate(90deg);
    &.open {
      transform: rotate(-90deg);
    }
  }

  .optionsContainer {
    border-radius: $dropdownMenuBorderRadius;
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    margin-top: $dropdownMenuTopMargin !important;
    z-index: 99999999; // Needed for modal
    overflow-y: scroll;
    padding: $dropdownOptionContainer;
    max-width: $maxMobileWidth;
    max-height: 30vh;
    min-height: 50%;

    @include theme.medium-device {
      max-height: 240px;
      max-width: $dropdownMaxWidthDesktop
    }
  }

  .option {
    padding: $dropdownOptionPadding;
    line-height: #{theme.$fontLineHeightBodySmall} !important;

    &.active {
      background: theme.$green-primary;
      color: theme.$grey-secondary !important;
    }

    &:hover {
      outline: none;
      background: theme.$grey-tertiary;
      color: theme.$green-primary !important;

      .optionIcon {
        color: theme.$green-primary;
      }
    }
  }

  .optionLabel {
    font-size: #{theme.$fontSizeBodySmall} !important;
    line-height: #{theme.$fontLineHeightBodySmall} !important;
    margin: 0 !important;
    color: inherit;
  }

  .optionIcon {
    @include theme.size($dropdownIconSize);
    color: theme.$grey-secondary;
  }

  .subLabelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $checkboxSubLabelGap;
    margin: $checkboxSubLabelMargin !important;
  }

  .subLabelIcon {
    @include theme.size($dropdownIconSize);
    outline: 2px solid theme.$green-primary;
    outline-offset: -3px;
    border-radius: 50%;

    &.error {
      color: $errorColor;
      outline-color: $errorColor;
    }
  }

  .subLabel {
    font-family: $amazonEmberMono !important;
    font-size: theme.$fontSizeLabel !important;
    line-height: theme.$fontLineHeightLabel !important;
    color: theme.$green-primary;
    margin: 0 !important;
  }

  .errorWrapper {
    margin-top: $errorWrapperTopMargin;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $errorWrapperGap;
  }

  .errorIcon {
    @include theme.size($errorIconSize);
    color: #cf1b24;
  }

  .errorMessage {
    font-family: $amazonEmberMono !important;
    font-size: theme.$fontSizeLabel !important;
    line-height: theme.$fontLineHeightLabel !important;
    margin: 0 !important;
    color: #cf1b24;
  }
}
