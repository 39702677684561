@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../../index.scss';

$bannerOutroLinkRightMargin: 12px;
$bannerOutroModalBodyBorderRadius: 0 0 32px 32px;
$bannerOutroModalAuthorThumbnailBorderRadius: 24px;
$bannerOutroModalFooterGap: 16px;
$bannerOutroModalFooterPadding: 20px 0;
$bannerOutroModalFooterIconWrapperSize: 56px;
$bannerOutroModalFooterIconWrapperPadding: 16px;
$bannerOutroModalFooterIconWrapperBorderRadius: 32px;
$bannerOutroModalFooterIconSize: 24px;
$bannerOutroModalFooterIconBorderRadius: 32px;

$mobileBannerOutroTopPadding: 118px;
$mobileBannerOutroRightPadding: 25px;
$mobileBannerOutroBottomPadding: 116px;
$mobileBannerOutroLeftPadding: 25px;
$mobileBannerOutroContentTopMargin: 72px;
$mobileBannerOutroLinkLeftMargin: 69px;
$mobileBannerOutroTextMargin: 40px 0 0 0;
// Mobile modal styles
$mobileBannerOutroModalBodyPadding: 0 17px;
$mobileBannerOutroModalMaxHeight: 754px;
$mobileBannerOutroModalTagTopMargin: 12px;
$mobileBannerOutroModalTagPadding: 6px 12px;
$mobileBannerOutroModalQuoteMargin: 24px 0 0 0;
$mobileBannerOutroModalQuoteMinWidth: 302px;
$mobileBannerOutroModalQuoteLineHeight: 30px;
$mobileBannerOutroModalAuthorInformationContainerWidth: 300px;
$mobileBannerOutroModalAuthorInformationContainerGap: 16px;
$mobileBannerOutroModalAuthorInformationContainerMargin: 0 64px 0 0;
$mobileBannerOutroModalAuthorThumbnailSize: 88px;
$mobileBannerOutroModalAuthorInformationGap: 4px;
$mobileBannerOutroModalAuthorInformationBottomMargin: 6px;
$mobileBannerOutroModalFooterMargin: 32px 0 16px 0;

$desktopBannerOutroTopPadding: 270px;
$desktopBannerOutroRightPadding: 487px;
$desktopBannerOutroBottomPadding: 266px;
$desktopBannerOutroLeftPadding: 516px;
$desktopBannerOutroContentTopMargin: 104px;
$desktopBannerOutroLabelLeft: 60px;
$desktopBannerOutroLinkLeftMargin: 168px;
// Desktop modal styles
$desktopBannerOutroModalBodyPadding: 0 40px 0 52px;
$desktopBannerOutroModalTagTop: 44px;
$desktopBannerOutroModalQuoteMargin: 56px 0 0 0;
$desktopBannerOutroModalQuoteMaxWidth: 675px;
$desktopBannerOutroModalAuthorInformationContainerWidth: 348px;
$desktopBannerOutroModalAuthorInformationContainerGap: 24px;
$desktopBannerOutroModalAuthorInformationContainerMargin: 0 36px 0 auto;
$desktopBannerOutroModalAuthorThumbnailSize: 128px;
$desktopBannerOutroModalAuthorInformationGap: 8px;
$desktopBannerOutroModalFooterMargin: 56px 0 40px 0;

.bannerOutro {
  height: auto;
  width: 100%;
  padding: theme.fluid-size($mobileBannerOutroTopPadding, $desktopBannerOutroTopPadding, $minViewportWidth, $maxViewportWidth) theme.fluid-size($mobileBannerOutroRightPadding, $desktopBannerOutroRightPadding, $minViewportWidth, $maxViewportWidth) theme.fluid-size($mobileBannerOutroBottomPadding, $desktopBannerOutroBottomPadding, $minViewportWidth, $maxViewportWidth) theme.fluid-size($mobileBannerOutroLeftPadding, $desktopBannerOutroLeftPadding, $minViewportWidth, $maxViewportWidth);
  background: theme.$slate-dark;

  @include theme.small-desktop {
    height: 100vh;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.outroTitle {
  color: theme.$grey-secondary;
  font-size: theme.$fontSizeHeading01;
  line-height: theme.$fontLineHeightHeading01;
}

.outroTitleFragment {
  color: theme.$green-secondary;
  font-size: theme.$fontSizeHeading01;
  line-height: theme.$fontLineHeightHeading01;

  @include theme.small-desktop {
    text-align: right;
  }
}

.outroContent {
  margin-top: $mobileBannerOutroContentTopMargin;

  @include theme.small-desktop {
    margin-top: $desktopBannerOutroContentTopMargin;
  }
}

.outroLabel {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$grey-secondary;
  margin: 0 !important;

  @include theme.small-desktop {
    position: absolute;
    left: $desktopBannerOutroLabelLeft;
  }
}

.outroLink {
  color: theme.$green-secondary !important;
  margin-left: theme.fluid-size($mobileBannerOutroLinkLeftMargin, $desktopBannerOutroLinkLeftMargin, $minViewportWidth, $maxViewportWidth);
  margin-right: $bannerOutroLinkRightMargin;

  span {
    font-size: theme.$fontSizeHeading05 !important;
    line-height: theme.$fontLineHeightHeading05 !important;
  }

  &:hover {
    .outroLinkIcon {
      background: theme.$green-secondary;
      color: theme.$green-primary;
    }
  }
}

.outroLinkIcon {
  background: rgba(theme.$grey-tertiary, 0.1);
  border-radius: 50%;
}

.outroText {
  margin: $mobileBannerOutroTextMargin !important;
  color: theme.$grey-secondary;

  @include theme.small-desktop {
    margin: 0 !important;
  }
}

.authorModal {
  --body-padding: #{$mobileBannerOutroModalBodyPadding} !important;
  --footer-padding: 0 !important;
  --max-height: #{$mobileBannerOutroModalMaxHeight} !important;
  --body-border-radius: #{$bannerOutroModalBodyBorderRadius};

  @include theme.small-desktop {
    --body-padding: #{$desktopBannerOutroModalBodyPadding} !important;
    --max-height: unset !important;
  }
}

.modalTag {
  font-family: $amazonEmberMono !important;
  color: theme.$green-primary;
  margin-top: $mobileBannerOutroModalTagTopMargin;
  padding: $mobileBannerOutroModalTagPadding;

  @include theme.small-desktop {
    position: absolute;
    top: $desktopBannerOutroModalTagTop;
    margin: 0;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.authorQuote {
  color: theme.$green-primary;
  margin: $mobileBannerOutroModalQuoteMargin !important;
  min-width: $mobileBannerOutroModalQuoteMinWidth;
  width: 75%;
  line-height: $mobileBannerOutroModalQuoteLineHeight !important;

  @include theme.small-desktop {
    margin: $desktopBannerOutroModalQuoteMargin !important;
    width: 100%;
    max-width: $desktopBannerOutroModalQuoteMaxWidth;
    line-height: #{theme.$fontLineHeightHeading05} !important;
  }
}

.authorInformationContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: $mobileBannerOutroModalAuthorInformationContainerWidth;
  gap: $mobileBannerOutroModalAuthorInformationContainerGap;
  margin: $mobileBannerOutroModalAuthorInformationContainerMargin;
  
  @include theme.small-desktop {
    width: $desktopBannerOutroModalAuthorInformationContainerWidth;
    gap: $desktopBannerOutroModalAuthorInformationContainerGap;
    margin: $desktopBannerOutroModalAuthorInformationContainerMargin;
  }
}

.authorThumbnail {
  @include theme.size($mobileBannerOutroModalAuthorThumbnailSize);
  border-radius: $bannerOutroModalAuthorThumbnailBorderRadius;
  flex-shrink: 0;

  @include theme.small-desktop {
    @include theme.size($desktopBannerOutroModalAuthorThumbnailSize);
  }
}

.authorInformation {
  display: flex;
  flex-direction: column;
  gap: $mobileBannerOutroModalAuthorInformationGap;
  margin-bottom: $mobileBannerOutroModalAuthorInformationBottomMargin;
  
  @include theme.small-desktop {
    gap: $desktopBannerOutroModalAuthorInformationGap;
  }
}

.authorName {
  font-size: #{theme.$fontSizeBodySmall} !important;
  line-height: #{theme.$fontLineHeightBodySmall} !important;
  color: theme.$green-primary;
  margin: 0 !important;
}

.authorDescription {
  @extend .authorName;
  opacity: 0.85;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  gap: $bannerOutroModalFooterGap;
  align-items: center;
  border-top: 1px solid rgba(theme.$green-primary, 0.15);
  margin: $mobileBannerOutroModalFooterMargin;
  padding: $bannerOutroModalFooterPadding;

  @include theme.small-desktop {
    margin: $desktopBannerOutroModalFooterMargin;
  }
}

.footerIconWrapper {
  @include theme.size($bannerOutroModalFooterIconWrapperSize);
  background: theme.$white;
  padding: $bannerOutroModalFooterIconWrapperPadding;
  border-radius: $bannerOutroModalFooterIconWrapperBorderRadius;
}

.footerIcon {
  @include theme.size($bannerOutroModalFooterIconSize);
  background: theme.$green-primary;
  color: theme.$white;
  border-radius: $bannerOutroModalFooterIconBorderRadius;
}