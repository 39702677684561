@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$backButtonTextUnderlineOffset: 4px;

.iconOnlyButton {
  width: 24px !important;
  height: 24px !important;
  color: theme.$white !important;

  @include theme.small-desktop {
    width: auto !important;
    height: auto !important;
    color: theme.$green-primary !important;
    padding: 0 !important;
    border-radius: 0 !important;
    text-decoration: underline !important;
    text-underline-offset: $backButtonTextUnderlineOffset;
    &::before {
      content: none;
    }
  }
}

.backButton {
  padding: 0 !important;
  border-radius: 0 !important;
  text-decoration: underline !important;
  text-underline-offset: $backButtonTextUnderlineOffset;
  &::before {
    content: none;
  }
}