@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$mobileResultBoxContainerMaxWidth: 720px;
$mobileTagPadding: 6px 12px;
$mobileTagBorderRadius: 8px;
$mobileContentMargin: 12px 0 0 0;

$desktopResultBoxContainerMaxWidth: 1192px;
$desktopTagPadding: 10px 16px;
$desktopTagBorderRadius: 12px;
$desktopContentMargin: 24px 0 0 0;
$desktopLinkContainerLeftMargin: 160px;

$titleMargin: 24px 0 0 0;

.resultBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: $mobileResultBoxContainerMaxWidth;

  @include theme.small-desktop {
    flex-direction: row;
    max-width: $desktopResultBoxContainerMaxWidth;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  color: theme.$green-primary;
  width: 100%;
}

.tagSlot {
  font-family: $amazonEmberMono;
  font-size: theme.$fontSizeLabel;
  line-height: theme.$fontLineHeightLabel;
  width: fit-content;
  background-color: theme.$white;
  color: theme.$green-primary;
  padding: $mobileTagPadding;
  border-radius: $mobileTagBorderRadius;
  @include theme.small-desktop {
    padding: $desktopTagPadding;
    border-radius: $desktopTagBorderRadius;
  }
}

.title {
  margin: $titleMargin !important;
}

.content {
  margin: $mobileContentMargin !important;
  font-size: theme.$fontSizeBody;
  line-height: theme.$fontLineHeightBody;
  @include theme.small-desktop {
    margin: $desktopContentMargin !important;
  }
}

.linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  @include theme.small-desktop {
    margin-left: $desktopLinkContainerLeftMargin;
    flex-direction: column;
    flex: 0;
  }
}

.link {
  background-color: theme.$white !important;
  color: theme.$green-primary !important;
  &:hover {
    color: theme.$green-secondary !important;
  }
}