@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$maxScreenSize: 1920px;

.redirectionCardListContainer {
  background-color: theme.$white;
  padding: 32px 24px;
  width: 100%;
  border-radius: theme.$border-radius-medium;

  @include theme.medium-desktop {
    padding: 56px 173px 64px;
    padding-left: theme.fluid-size(32px, 60px, theme.$mediumDesktop, theme.$largeDesktop);
    padding-right: theme.fluid-size(32px, 60px, theme.$mediumDesktop, theme.$largeDesktop);
    
  }

  @include theme.large-desktop {
    padding-left: theme.fluid-size(60px, 173px, theme.$largeDesktop, $maxScreenSize);
    padding-right: theme.fluid-size(60px, 173px, theme.$largeDesktop, $maxScreenSize);
  }
}

.cardList {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 12px;

  @include theme.large-desktop {
    margin-top: 44px;
    column-gap: 8px;
    flex-direction: row;
  }
}
