@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$transcriptionContainerGradientBlurHeight: 128px;

// This 27.45vh was calculated according to the size of the video in the mobile mocks, we need it to be able to maintain an aspect ratio
$mobileVideoHeight: 27.45vh;
$mobileCloseButtonTop: 8px;
$mobileCloseButtonRight: 8px;
// These styles are for when the transcription is visible
$mobileTranscriptionEnabledCloseButtonTop: 16px;
$mobileTranscriptionContainerBorderRadius: 32px 32px 0 0;
$mobileTranscriptionHeaderPadding: 24px 25px;
$mobileTranscriptionFragmentsContainerPadding: 24px 25px 128px 25px;
$mobileTranscriptionFragmentsContainerGap: 32px;
$mobileTranscriptionFragmentGap: 12px;
$mobileTranscriptionFragmentTimeBorderRadius: 12px;
$mobileTranscriptionFragmentTimePadding: 4px;

$desktopBackdropFilterBlur: 24px;
$desktopPlayerWrapperGap: 24px;
$desktopCloseButtonTop: 48px;
$desktopCloseButtonRight: 60px;
// These styles are for when the transcription is visible
$desktopTranscriptionEnabledPadding: 0 24px;
$desktopTranscriptionEnabledCloseButtonTop: -72px;
$desktopTranscriptionEnabledCloseButtonRight: 0px;
$desktopTranscriptionWrapperWidth: 33vw;
$desktopTranscriptionWrapperMaxWidth: 620px;
$desktopTranscriptionWrapperBorderRadius: 24px;
$desktopTranscriptionHeaderPadding: 32px 40px 28px 40px;
$desktopTranscriptionFragmentsContainerPadding: 32px 40px 128px 40px;
$desktopTranscriptionFragmentGap: 24px;
$desktopTranscriptionFragmentTimePadding: 10px 16px;

:fullscreen::backdrop {
  background: none;
}

.playerContainer {
  &.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: theme.$slate-dark;

    @include theme.small-desktop {
      flex-direction: row;
      justify-content: center;
      background: transparent;
      backdrop-filter: blur($desktopBackdropFilterBlur);
    }
  }

  &.transcription {
    @include theme.small-desktop {
      padding: $desktopTranscriptionEnabledPadding
    }
  }

  &.hidden {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    width: 0px;
    height: 0px;
  }
}

.playerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  @include theme.small-desktop {
    flex-direction: row;
    align-items: normal;
    gap: $desktopPlayerWrapperGap;
    position: relative;

    &.transcription {
      height: auto;
      width: auto;
    }
  }
}

.closeButton {
  position: absolute !important;
  top: $mobileCloseButtonTop;
  right: $mobileCloseButtonRight;

  @include theme.small-desktop {
    top: $desktopCloseButtonTop;
    right: $desktopCloseButtonRight;
  }

  &.transcription {
    top: $mobileTranscriptionEnabledCloseButtonTop;

    @include theme.small-desktop {
      top: $desktopTranscriptionEnabledCloseButtonTop;
      right: $desktopTranscriptionEnabledCloseButtonRight;
    }
  }
}

.transcriptionWrapper {
  // These 80px in the calc come from the space at the top there is in the mobile mocks in the Figma
  height: calc(100% - 80px);
  margin-top: auto;
  flex-shrink: 0;

  @include theme.small-desktop {
    height: auto;
    margin-top: 0;
    width: $desktopTranscriptionWrapperWidth;
    flex-grow: 1;
    position: relative;
    max-width: $desktopTranscriptionWrapperMaxWidth;
    border-radius: $desktopTranscriptionWrapperBorderRadius;
    overflow: hidden;
  }
}

.transcriptionContainer {
  width: 100%;
  max-height: 100%;
  margin-top: auto;
  border-radius: $mobileTranscriptionContainerBorderRadius;
  background: theme.$grey-secondary;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @include theme.small-desktop {
    height: auto;
    position: absolute;
  }

  &::after {
    // This is the blur gradient at the bottom of the transcription container
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $transcriptionContainerGradientBlurHeight;
    background: linear-gradient(180deg, rgba(theme.$grey-secondary, 0) 0%, theme.$grey-secondary 100%);
  }
}

.transcriptionHeader {
  padding: $mobileTranscriptionHeaderPadding;
  // This calc is the spacing between the top of the transcription container and the header itself, similar to what we do on the sides
  margin-top: calc($mobileVideoHeight + 8px);
  border-bottom: 1px solid rgba(theme.$green-primary, 0.15);

  @include theme.small-desktop {
    padding: $desktopTranscriptionHeaderPadding;
    margin-top: 0;
  }
}

.transcriptionTitle {
  margin: 0 !important;
  color: theme.$green-primary;
  font-size: #{theme.$fontSizeBodySmall};
  line-height: #{theme.$fontLineHeightBodySmall};
}

.transcriptionFragmentsContainer {
  padding: $mobileTranscriptionFragmentsContainerPadding;
  display: flex;
  flex-direction: column;
  gap: $mobileTranscriptionFragmentsContainerGap;
  overflow-y: scroll;

  @include theme.small-desktop {
    padding: $desktopTranscriptionFragmentsContainerPadding;
  }
}

.transcriptionFragment {
  display: flex;
  flex-direction: row-reverse;
  gap: $mobileTranscriptionFragmentGap;

  @include theme.small-desktop {
    flex-direction: row;
    gap: $desktopTranscriptionFragmentGap;
  }
}

.transcriptionText {
  margin: 0 !important;
  color: theme.$green-primary;
  font-size: #{theme.$fontSizeBody} !important;
  line-height: #{theme.$fontLineHeightBody} !important;
}

.transcriptionTime {
  margin: 0 !important;
  color: theme.$green-primary;
  font-family: $amazonEmberMono !important;
  font-size: #{theme.$fontSizeLabel} !important;
  line-height: #{theme.$fontLineHeightLabel} !important;
  border-radius: $mobileTranscriptionFragmentTimeBorderRadius;
  padding: $mobileTranscriptionFragmentTimePadding;
  height: fit-content;

  @include theme.small-desktop {
    padding: $desktopTranscriptionFragmentTimePadding;
    background: theme.$grey-tertiary;
  }
}