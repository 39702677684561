@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$pickerContainerMobileMargin: 40px 0 16px;

$pickerWrapperHeight: 48px;
$pickerWrapperBorderRadius: 40px;
$pickerWrapperPadding: 20px;
$pickerBorderWidth: 2.5px;
$pickerTrackHeight: 5px;
$pickerThumbSize: 8px;
$pickerStepMarkersTopOffset: 12px;
$pickerStepMarketsLeftOffset: 22px;
$pickerStepLabelBottomOffset: 40px;
$pickerStepLabelActivePadding: 12px 16px;
$pickerStepLabelActiveBorderRadius: 16px;
$pickerStepLabelActiveBottomOffset: 24px;
$pickerStepLabelActiveIndicatorSize: 10px;
$pickerStepLabelActiveIndicatorBottomOffset: -4px;
$pickerStepLabelActiveIndicatorBorderRadius: 6px;
$pickerStepThumbSize: 24px;
$pickerStepInnerThumbSize: 8px;
$pickerTextWrapperTopMargin: 8px;

$pickerContainerDesktopMargin: 32px 0;

.pickerContainer {
  margin: $pickerContainerMobileMargin;
  width: 100%;
  position: relative;

  @include theme.small-desktop {
    margin: 32px 0;
  }
}

.pickerLabel {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
}

.pickerWrapper {
  display: flex;
  align-items: center;
  height: $pickerWrapperHeight;
  background: theme.$white;
  border-radius: $pickerWrapperBorderRadius;
  padding: $pickerWrapperPadding;
}

.picker {
  --slider-progress: 0%; /* Default slider progress */

  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 0;
  background: theme.$grey-tertiary;
  border: $pickerBorderWidth solid #e5efef;
  border-left: none;
  border-right: none;

  // Chrome/Safari specific styles
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    height: $pickerTrackHeight;
    background: linear-gradient(to right, theme.$green-primary var(--slider-progress), theme.$grey-tertiary var(--slider-progress));
    position: relative;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    @include theme.size($pickerThumbSize);
    background: theme.$green-primary;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: calc(var(--slider-progress) - 4px);
  }

  &:focus {
    outline: none;
  }

  // Firefox specific styles
  @supports (-moz-appearance: none) {
    &::-moz-range-track {
      height: $pickerTrackHeight;
      background: linear-gradient(to right, theme.$green-primary var(--slider-progress), theme.$grey-tertiary var(--slider-progress));
    }

    &::-moz-range-thumb {
      appearance: none;
      border: none;
      @include theme.size($pickerThumbSize);
      background: theme.$green-primary;
      border-radius: 50%;
      cursor: pointer;
      transform: translateX(calc(var(--slider-progress) - 4px));
    }
  }
}

.stepMarkers {
  position: absolute;
  top: $pickerStepMarkersTopOffset;
  left: $pickerStepMarketsLeftOffset;
  // These 44px amount for the paddings/borders so these steps are as wide as the input and not like the wrapper
  width: calc(100% - 44px);
  display: flex;
  justify-content: space-between;
}

.stepMarker {
  position: relative;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    .stepLabel {
      @include theme.small-desktop {
        display: block
      }
    }
  }

  .stepLabel {
    @extend .pickerLabel;
    display: none;
    color: theme.$green-primary;
    position: absolute;
    bottom: $pickerStepLabelBottomOffset;
    width: max-content;
    text-align: center;

    &.active {
      display: block;
      background: theme.$green-primary;
      color: theme.$green-secondary;
      padding: $pickerStepLabelActivePadding;
      border-radius: $pickerStepLabelActiveBorderRadius;
      bottom: $pickerStepLabelActiveBottomOffset;
      &::before {
        content: "";
        position: absolute;
        @include theme.size($pickerStepLabelActiveIndicatorSize);
        bottom: $pickerStepLabelActiveIndicatorBottomOffset;
        background-color: theme.$green-primary;
        left: 50%;
        transform: translateX(-50%);
        border-radius: $pickerStepLabelActiveIndicatorBorderRadius;
      }
    }
  }

  .stepThumb {
    @include theme.size($pickerStepThumbSize);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover, &:focus, &:focus-visible, &.active {
      outline: none;
      .innerThumb {
        background: theme.$green-primary;
        box-shadow: 0 0 0 8px rgba(0,0,0,0.1);
      }
    }
  }

  .innerThumb {
    @include theme.size($pickerStepInnerThumbSize);
    background: theme.$green-primary;
    border-radius: 50%;
  }
}

.pickerTextWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $pickerTextWrapperTopMargin;
}

.pickerMinimum, .pickerMaximum {
  @extend .pickerLabel;
  color: theme.$green-primary;
}