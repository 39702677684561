@use "~@amzn/sss-website-theme/dist/theme/index.module.scss" as theme;

$backgroundBarOpacity: 0.2;
$backgroundDotsOpacity: 0.4;

$dotSize: 16px;

.graphic {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.bar {
  stroke-linecap: round;
  stroke-dashoffset: 0;

  &Raised {
    stroke: theme.$green-primary;
    stroke-dasharray: 0, 150;
  }

  &Background {
    stroke: rgba(theme.$green-primary, $backgroundBarOpacity);
    stroke-dasharray: 125, 150;
  }
}

.backgroundDot {
  @include theme.size($dotSize, $dotSize);

  position: absolute;
  border-radius: 100%;

  fill: rgba(theme.$green-primary, $backgroundDotsOpacity);
}

.raisedDot {
  @include theme.size($dotSize, $dotSize);

  position: absolute;
  border-radius: 100%;

  fill: theme.$green-secondary;

  opacity: 0;
}

.topRaisedDot {
  opacity: 1;
}
