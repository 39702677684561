@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$mobileSubtitleBottomMargin: 48px;
$mobileSearchResultPadding: 16px;
$mobileSearchResultBorderRadius: 24px;
$mobileSearchResultMinHeight: 426px;
$mobileSearchResultContainerMaxWidth: 720px;

$desktopSubtitleBottomMargin: 72px;
$desktopSearchResultPadding: 32px;
$desktopSearchResultBorderRadius: 40px;
$desktopSearchResultMinHeight: 368px;
$desktopSearchResultContainerMaxWidth: 1192px;

$no-results-subtitle-margin-bottom: 10px;
$no-results-container-gap: -100px;

.subtitleWrapper {
  display: flex;
  justify-content: center;
}

.subtitle {
  font-size: theme.$fontSizeSubtitle !important;
  line-height: theme.$fontLineHeightSubtitle !important;
  color: theme.$green-primary;
  margin-bottom: $mobileSubtitleBottomMargin !important;

  @include theme.small-desktop {
    margin-bottom: $desktopSubtitleBottomMargin !important;
  }
}

.searchResultContainer {
  --card-background: #{theme.$grey-tertiary};
  margin: 0 auto;
  padding: $mobileSearchResultPadding;
  border: 0;
  border-radius: $mobileSearchResultBorderRadius;
  min-height: $mobileSearchResultMinHeight;
  max-width: $mobileSearchResultContainerMaxWidth;
  width: 100%;
  display: flex;
  justify-content: center;

  @include theme.small-desktop {
    padding: $desktopSearchResultPadding;
    border-radius: $desktopSearchResultBorderRadius;
    min-height: $desktopSearchResultMinHeight;
    max-width: $desktopSearchResultContainerMaxWidth;
  }
}

.noResultsContainer {
  margin-top: 0.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 40vh;
  gap: $no-results-container-gap;

@include theme.small-desktop {
  padding-bottom: 24vh;
}

  .noResultsSubtitle {
    @extend .subtitle;
    margin-bottom: $no-results-subtitle-margin-bottom !important;
  }

  .noResultsTextWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .baseText {
      color: theme.$green-primary !important;
      font-size: inherit;
      font-family: inherit;
    }

    .noResultsParagraph {
      @extend .baseText;
    }

    .noResultsLink {
      @extend .baseText;
      font-weight: bold;
      text-decoration-line: underline;
    }
  }
}
