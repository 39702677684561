@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$mobileVerticalPadding: 80px;
$mobileHorizontalPadding: 25px;
$mobileWrapperGap: 64px;
$mobileHeadingBottomMargin: 24px;
$mobileDropdownWrapperMaxWidth: 364px;
$mobileDropdownLabelBottomMargin: 12px;
$mobileDropdownBottomMargin: 32px;
$mobileDropdownBorderRadius: 12px;
$mobileDropdownHeight: 56px;
$mobileDropdownPadding: 16px 16px 16px 24px;
$mobileDropdownOptionPadding: 18px 16px 18px 24px;
$mobileCtaPadding: 20px 24px 20px 32px;

$desktopVerticalPadding: 104px;
$desktopLeftPadding: 60px;
$desktopRightPadding: 212px;
$desktopTextWrapperMaxWidth: 752px;
$desktopDescriptionMaxWidth: 608px;
$desktopDropdownWrapperTopMargin: 24px;
$desktopDropdownWrapperMaxWidth: 584px;
$desktopDropdownBottomMargin: 56px;
$desktopDropdownHeight: 64px;
$desktopDropdownOptionPadding: 20px 16px 20px 24px;
$desktopCtaPadding: 32px 32px 32px 40px;

.footerBannerWrapper {
	padding-top: theme.fluid-size($mobileVerticalPadding, $desktopVerticalPadding, theme.$minViewportWidth, theme.$maxViewportWidth);
	padding-bottom: theme.fluid-size($mobileVerticalPadding, $desktopVerticalPadding, theme.$minViewportWidth, theme.$maxViewportWidth);
	padding-left: theme.fluid-size($mobileHorizontalPadding, $desktopLeftPadding, theme.$minViewportWidth, theme.$maxViewportWidth);
	padding-right: theme.fluid-size($mobileHorizontalPadding, $desktopRightPadding, theme.$minViewportWidth, theme.$maxViewportWidth);
	background: theme.$green-primary;
	display: flex;
	flex-direction: column;
	gap: $mobileWrapperGap;
	border-bottom: 1px solid rgba(theme.$white, 0.3);

	@include theme.large-device {
		flex-direction: row;
		justify-content: space-between;
	}

	.textWrapper {
		width: 100%;

		@include theme.large-device {
			max-width: $desktopTextWrapperMaxWidth;
		}

		.heading {
			margin-bottom: $mobileHeadingBottomMargin;
			color: theme.$green-secondary;
		}
	
		.description {
			margin: 0;
			color: theme.$grey-secondary;
			opacity: 0.85;

			@include theme.large-device {
				max-width: $desktopDescriptionMaxWidth;
			}
		}
	}

	.dropdownWrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: theme.fluid-size($mobileDropdownWrapperMaxWidth, $desktopDropdownWrapperMaxWidth, theme.$minViewportWidth, theme.$maxViewportWidth);

		@include theme.large-device {
			margin-top: $desktopDropdownWrapperTopMargin;
		}

		.dropdownLabel {
			font-family: "amazonEmberMono";
			font-size: theme.$fontSizeLabel !important;
			line-height: theme.$fontLineHeightLabel !important;
			color: theme.$grey-secondary;
			opacity: 0.85;
			margin-bottom: $mobileDropdownLabelBottomMargin;
		}
	
		.dropdown {
			margin-bottom: $mobileDropdownBottomMargin;
			width: 100%;
			border-radius: $mobileDropdownBorderRadius;
			--height: #{$mobileDropdownHeight};
			--menu-border-radius: 12px !important;
			--font-size: #{theme.$fontSizeSubtitle};
			--line-height: #{theme.$fontLineHeightSubtitle};
			--font-weight: 400;
			--padding: #{$mobileDropdownPadding};
			--option-font-size: #{theme.$fontSizeBodySmall};
			--option-line-height: #{theme.$fontLineHeightBodySmall};
			--option-background-selected: #{theme.$green-secondary};
			--option-border-width: 0;
			--option-focus-ring-width: 0;
			--option-padding: #{$mobileDropdownOptionPadding};
			--kat-option-padding-content: 0;

			@include theme.large-device {
				margin-bottom: $desktopDropdownBottomMargin;
				--height: #{$desktopDropdownHeight};
				--option-padding: #{$desktopDropdownOptionPadding};
			}
		}

		.cta {
			width: fit-content;
			font-size: theme.$fontSizeCta;
			padding: $mobileCtaPadding;

			@include theme.large-device {
				padding: $desktopCtaPadding;
			}
		}
	}
}