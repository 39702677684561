@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$containerVerticalPadding: 24px;
$containerHorizontalPadding: 0px;

$dividerDashWidth: 8px;
$dividerDashHeight: 1px;

$smallDeviceVerticalPadding: 24px;
$largeDeviceVerticalPadding: 56px;

$taglineTopSpaceXSmall: 8px;
$taglineBottomSpaceXSmall: 12px;

$taglineTopSpaceSmallDevice: 8px;
$taglineTopSpaceLessThanLargeDevice: 32px;

$taglineBottomSpaceSmallDevice: 12px;
$taglineBottomSpaceLessThanLargeDevice: 32px;

$taglineTopSpaceLargeDevice: 0px;
$taglineBottomSpaceLargeDevice: 32px;

$copysSpacing: 32px;

$copyMaxWidthDesktop: 584px;

.dynamicItem {
  transition: opacity 0.4s;
  width: 90%;
  padding: $containerVerticalPadding $containerHorizontalPadding;
  background-position: top;
  background-size: $dividerDashWidth $dividerDashHeight;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, rgba(theme.$green-primary, 1) 33%, rgba(theme.$green-primary, 0) 0%);

  @include theme.small-device {
    padding-top: theme.fluid-size(
      $smallDeviceVerticalPadding,
      $largeDeviceVerticalPadding,
      theme.$smallDevice,
      theme.$largeDevice
    );
    padding-bottom: theme.fluid-size(
      $smallDeviceVerticalPadding,
      $largeDeviceVerticalPadding,
      theme.$smallDevice,
      theme.$largeDevice
    );
  }

  @include theme.large-device {
    width: 100%;
    padding: $largeDeviceVerticalPadding 0;
    max-width: $copyMaxWidthDesktop;
    flex-direction: row;
    justify-content: flex-end;
  }

  // Last item doesn't have spacing to keep symmetry
  &:last-child {
    padding-bottom: 0;
  }
}

.disabled {
  opacity: 0.4;
}

.tagline {
  color: theme.$green-primary;
  margin: $taglineTopSpaceXSmall 0 $taglineBottomSpaceXSmall;

  @include theme.small-device {
    margin-top: theme.fluid-size(
      $taglineTopSpaceSmallDevice,
      $taglineTopSpaceLargeDevice,
      theme.$smallDevice,
      theme.$largeDevice
    );
    margin-bottom: theme.fluid-size(
      $taglineTopSpaceSmallDevice,
      $taglineTopSpaceLessThanLargeDevice,
      theme.$smallDevice,
      theme.$largeDevice
    );
  }

  @include theme.large-device {
    margin-top: $taglineTopSpaceLargeDevice;
    margin-bottom: $taglineBottomSpaceLargeDevice;
  }
}

.title {
  color: theme.$green-primary;
  margin-bottom: $copysSpacing;
}

.copy {
  color: theme.$green-primary;
  margin-bottom: $copysSpacing;
}

li a.externalLink,
li button.externalLink {
  margin-bottom: calc($copysSpacing / 2);
  color: theme.$green-primary;
}
