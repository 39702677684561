@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$smallDesktopSpacing: 0 60px;
$gridSmallDesktopColumnGap: 24px;

$desktopCardsBorderRadius: 32px;

$mobileSpacing: 0 25px;
$mediumDeviceSpacing: 0 64px;

$taglineMarginBottom: 32px;
$headingMarginBottom: 64px;

.bentoCards {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: $mobileSpacing;
  color: theme.$green-primary;

  .textContent {
    position: relative;
  }

  .description {
    white-space: pre-line;
  }

  .highlight {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    font-weight: bold !important;
    padding: 0;
    font-size: theme.$fontSizeBody !important;
    line-height: theme.$fontLineHeightBody !important;
  }

  .card {
    height: 100%;

    @include theme.small-desktop {
      aspect-ratio: 0.9 / 1;
      height: auto;
      border-radius: $desktopCardsBorderRadius;
    }
  }

  .tagline {
    margin-bottom: $taglineMarginBottom;
  }

  .heading {
    margin-bottom: $headingMarginBottom;
  }

  .cardHeading {
    color: theme.$green-secondary;
    margin-top: auto;
  }

  .gridElement {
    width: 100%;

    // First element
    &:nth-child(1) {
      grid-area: 1 / 1 / auto / 6;
    }

    // Second element
    &:nth-child(2) {
      margin-top: 70%;
      grid-area: 1 / 7 / auto / 10;
    }

    // Third element
    &:nth-child(3) {
      margin-top: 30%;
      grid-area: 1 / 10 / auto / 13;
    }
  }

  @include theme.medium-device {
    padding: $mediumDeviceSpacing;
  }

  @include theme.small-desktop {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: $gridSmallDesktopColumnGap;
    padding: $smallDesktopSpacing;
  }
}
