@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$minScreenSize: 414px;

$marginBottomDevice: 48px;
$marginBottomDesktop: 80px;
$gapDevice: 24px;
$gapDesktop: 32px;

$fontSizeDevice: 30px;
$fontSizeDesktop: 56px;

.TeaserCard {
  .title {
    margin-bottom: theme.fluid-size($marginBottomDevice, $marginBottomDesktop, $minScreenSize, theme.$maxViewportWidth);
    font-size: theme.fluid-size($fontSizeDevice, $fontSizeDesktop, $minScreenSize, theme.$maxViewportWidth) !important;
  }

  .imagesWrapper {
    display: flex;
    flex-direction: column;
    gap: theme.fluid-size($gapDevice, $gapDesktop, $minScreenSize, theme.$maxViewportWidth);

    @include theme.large-device {
      flex-direction: row;
    }
  }

  &.dark {
    .title {
      color: theme.$grey-secondary !important;
    }
  }

  &.light {
    .title {
      color: theme.$green-primary !important;
    }
  }
}
