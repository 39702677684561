@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$mobileGridGap: 8px;
$mobilePadding: 0 0 80px 0;

$desktopGridGap: 24px;
$desktopPadding: 80px 0 96px 0;

.accordionCards {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $mobileGridGap;
  padding: $mobilePadding !important;

  @include theme.small-desktop {
    grid-template-columns: repeat(3, 1fr);
    row-gap: $desktopGridGap;
    column-gap: $desktopGridGap;
    padding: $desktopPadding !important;
  }
}