@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../../../index.scss';

$mobileListContainerMarginTop: 64px;
$mobileListMarginTop: 12px;
$mobileListGap: 24px;
$mobileListElementGap: 12px;
$mobileListDecorationLeftOffset: -32px;
$listDecorationWidth: 24px;

$desktopListContainerMarginTop: 88px;
$desktopListMarginTop: 32px;
$desktopListGap: 64px;
$desktopListIndexMarginTop: 5px;
$desktopListDecorationLeftOffset: -52px;
$desktopListElementMarginLeft: 24px;

.ModalListContainer {
  color: #{$green-primary};
  margin-top: $mobileListContainerMarginTop;

  @include theme.small-desktop {
    margin-top: $desktopListContainerMarginTop;
  }

  .ModalList {
    display: flex;
    flex-direction: column;
    margin-top: $mobileListMarginTop;
    gap: $mobileListGap;

    @include theme.small-desktop {
      margin-top: $desktopListMarginTop;
      gap: $desktopListGap;
    }
  }

  .ModalListHeading {
    font-family: #{$amazonEmberDisplay}, sans-serif;
    font-size: #{$fontSizeHeading05} !important;
    line-height: #{$fontLineHeightHeading05} !important;
  }

  .ModalListElement {
    font-family: #{$amazonEmberMono};
    opacity: #{$asxCopyOpacity};
    display: flex;
    flex-direction: column;
    gap: $mobileListElementGap;

    @include theme.small-desktop {
      flex-direction: row;
      gap: 0;
    }

    .ListElementIndex {
      position: relative;
      height: fit-content;

      @include theme.small-desktop {
        margin-top: $desktopListIndexMarginTop;
      }

      &::before {
        content: "";
        position: absolute;
        left: $mobileListDecorationLeftOffset;
        top: 50%;
        transform: translateY(-50%);
        width: $listDecorationWidth;
        height: 1px;
        background: #{$green-primary};
        opacity: 0.4;

        @include theme.small-desktop {
          left: $desktopListDecorationLeftOffset;
        }
      }
    }

    .ListElementString {
      font-family: #{$amazonEmberDisplay}, sans-serif;
      font-size: #{theme.$fontSizeBodySmall};
      line-height: #{theme.$fontLineHeightBodySmall};

      @include theme.small-desktop {
        margin-left: $desktopListElementMarginLeft;
      }
    }
  }
}