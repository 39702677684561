@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$spacingMobileDevice: 12px;
$spacingXLargeDesktop: 24px;

$xLargeDesktop: 1920px;

$mobileHeight: 448px;
$deviceHeight: 700px;

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: theme.fluid-size($spacingMobileDevice, $spacingXLargeDesktop, theme.$smallDevice, $xLargeDesktop);

  kat-card {
    height: $mobileHeight;
  }

  @include theme.large-device {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;

    kat-card {
      height: $deviceHeight;
      width: 48%;
    }
  }

  @include theme.medium-desktop {
    kat-card {
      width: 31%;
    }
  }
}
