@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import 'src/index.scss';

$wrapperHeightDevice: 80px;
$wrapperHeightDesktop: 144px;

$mainPadding: 8px;
$desktopTopOffset: 110px;
$deviceNavBar: 72px;

$buttonWrapperWidth: 80px;
$buttonWrapperHeight: 48px;

$rightButtonLightBackground: linear-gradient(to right, transparent, theme.$grey-secondary 30px);
$leftButtonLightBackground: linear-gradient(to left, transparent, theme.$grey-secondary 30px);

$rightButtonDarkBackground: linear-gradient(to right, transparent, theme.$slate-dark 30px);
$leftButtonDarkBackground: linear-gradient(to left, transparent, theme.$slate-dark 30px);
$buttonWrapperPadding: 20px;

$buttonSize: 24px;

.wrapper {
  height: $wrapperHeightDevice;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: $deviceNavBar;
  z-index: zindex($zLayout, anchorLinks);

  &.light {
    background-color: theme.$grey-secondary;
  }

  &.dark {
    background-color: theme.$slate-dark;
  }

  @include theme.small-desktop {
    height: $wrapperHeightDesktop;
    position: relative;
    top: 0;

    &.light,
    &.dark {
      background-color: transparent;
    }
  }
}

.AnchorLinks {
  display: flex;
  justify-content: space-around;
  top: 0;
  z-index: zindex($zLayout, anchorLinks);
  overflow-x: hidden;
  position: relative;

  &.showButtonRight {
    padding-right: 0;
  }
  &.showButtonLeft {
    padding-left: 0;
  }

  @include theme.small-desktop {
    padding: 0;
    background-color: transparent;
    top: $desktopTopOffset;
    position: static;
  }
}

.anchorList {
  display: flex;
  overflow-x: scroll;
  position: relative;
  background-color: unset;
  padding: $mainPadding !important;
  border-radius: 0;
  margin: 0 !important;
  gap: $mainPadding;

  &.showButtonRight {
    padding-right: $buttonWrapperWidth !important;
  }

  @include theme.small-desktop {
    padding: calc($mainPadding / 2) !important;
    background-color: theme.$white;
    gap: 0;
    border-radius: theme.$border-radius-medium;
  }

  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for other browsers */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.buttonWrapperRight,
.buttonWrapperLeft {
  @include theme.size($buttonWrapperWidth, $buttonWrapperHeight);

  position: absolute;
  display: none;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);

  &.showButtonRight,
  &.showButtonLeft {
    display: flex;
  }
}

.buttonWrapperRight {
  justify-content: flex-end;
  right: 0;
  padding-right: $buttonWrapperPadding;
  &.light {
    background: $rightButtonLightBackground;
  }
  &.dark {
    background: $rightButtonDarkBackground;
  }
}

.buttonWrapperLeft {
  justify-content: flex-start;
  left: 0;
  padding-left: $buttonWrapperPadding;
  &.light {
    background: $leftButtonLightBackground;
  }
  &.dark {
    background: $leftButtonDarkBackground;
  }
}

.button {
  @include theme.size($buttonSize);
  background-color: theme.$white !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.pillContainer {
  position: relative;
}

.activeAnchorPillBullet {
  @include theme.size(6px);
  background-color: theme.$green-primary;
  border-radius: 100%;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  @include theme.small-desktop {
    left: 24px;
  }
}
