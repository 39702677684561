@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$pageSectionWidth: 100%;
$boundedContainerMargin: 0 auto;

$paddingDevice: 24px;
$paddingTopDevice: 80px;

$paddingSmallDesktop: 32px;
$paddingTopSmallDesktop: 90px;

$paddingDesktop: 48px;
$paddingTopDesktop: 100px;

$paddingLargeDesktop: 60px;
$paddingTopLargeDesktop: 136px;

$maxScreenWidth: 1920px;

.pageSection {
  display: block;
  background-color: theme.$grey-secondary;
  width: $pageSectionWidth;
  padding: $paddingDevice;

  &.greenPrimary {
    background-color: theme.$green-primary;
  }

  &.slateDark {
    background-color: theme.$slate-dark;
  }

  &.greyTertiary {
    background-color: theme.$grey-tertiary;
  }

  .boundedContainer {
    margin: $boundedContainerMargin;
    max-width: theme.$maxViewportWidth;
  }

  @include theme.small-desktop {
    padding: $paddingDesktop;
  }

  &.hasDynamicSpacing {
    padding-top: $paddingTopDevice;
  
    @include theme.medium-device {
      padding: theme.fluid-size($paddingDevice, $paddingSmallDesktop, theme.$mediumDevice, theme.$largeDevice);
      padding-top: theme.fluid-size($paddingTopDevice, $paddingLargeDesktop, theme.$mediumDevice, theme.$largeDevice);
    }

    @include theme.medium-desktop {
      padding: theme.fluid-size($paddingDesktop, $paddingLargeDesktop, theme.$mediumDesktop, $maxScreenWidth);
      padding-top: theme.fluid-size($paddingTopDesktop, $paddingTopLargeDesktop, theme.$mediumDesktop, $maxScreenWidth);
    }

    @include theme.large-desktop {
      padding: $paddingLargeDesktop;
      padding-top: $paddingTopLargeDesktop;
    }
  }
}
