@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$selectPillBorderRadius: 111px;
$selectPillPadding: 12px 16px;
$selectPillFocusPadding: 8px 12px;
$checkboxRightMargin: 10px;

.selectPill {
  width: fit-content;
  background-color: rgba(theme.$grey-primary, 0.65) !important;
  border-radius: $selectPillBorderRadius;
  padding: $selectPillPadding;
  cursor: pointer;

  &:hover {
    background-color: theme.$grey-tertiary !important;
    .checkmark {
      border-color: theme.$green-primary;
    }
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    padding: $selectPillFocusPadding;
    border: solid 4px theme.$white;
    outline: solid 1px theme.$green-primary;
  }

  &.error {
    .checkmark {
      border: 1px solid theme.$errorColor;
    }

    .label {
      color: theme.$errorColor;
    }

    .description {
      color: theme.$errorColor;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: solid 1px theme.$errorColor;
    }
  }
}

.checkbox {
  display: inline-flex;
  align-items: center;
  font-size: #{theme.$fontSizeBodySmall};
  line-height: #{theme.$fontLineHeightBodySmall};
  margin-right: $checkboxRightMargin;
  height: fit-content;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.label {
  color: theme.$green-primary;
}
