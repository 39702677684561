@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$mobileCardHeight: 104px;
$mobileCardWidth: 364px;
$mobileCardBorderRadius: 24px;
$mobileCardPadding: 8px 36px 8px 8px;
$mobileCardBottomMargin: 40px;
$mobileCardGap: 16px;
$mobileIconSize: 24px;
$mobileLinkUnderlineOffset: 4px;

$desktopCardTopMargin: 0;
$desktopCardHeight: 152px;
$desktopCardWidth: 584px;
$desktopCardBorderRadius: 32px;
$desktopCardPadding: 8px 32px 8px 8px;
$desktopCardGap: 24px;
$desktopIconSize: 40px;
$desktopTextWrapperGap: 24px;

.formEntryPointCard {
  height: $mobileCardHeight;
  width: 100%;
  max-width: $mobileCardWidth;
  border-radius: $mobileCardBorderRadius;
  padding: $mobileCardPadding;
  margin-bottom: $mobileCardBottomMargin;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $mobileCardGap;

  @include theme.small-desktop {
    margin-top: $desktopCardTopMargin;
    height: $desktopCardHeight;
    border-radius: $desktopCardBorderRadius;
    padding: $desktopCardPadding;
    gap: $desktopCardGap;
    max-width: $desktopCardWidth;
  }

  &.dark {
    background: rgba(theme.$white, 0.05);
  }

  &.light {
    background: theme.$grey-tertiary;
  }

  .iconWrapper {
    height: 100%;
    border-radius: $mobileCardBorderRadius;
    aspect-ratio: 88/88;
    display: flex;
    justify-content: center;
    align-items: center;

    &.dark {
      background: rgba(theme.$white, 0.05);
    }

    &.light {
      background: theme.$green-secondary;
    }
  }

  .icon {
    @include theme.size($mobileIconSize);

    @include theme.small-desktop {
      @include theme.size($desktopIconSize);
    }

    &.dark {
      color: theme.$green-secondary;
    }

    &.light {
      color: theme.$green-primary;
    }
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    gap: $desktopTextWrapperGap;
    width: 100%;
  }

  .subtitle {
    font-size: #{theme.$fontSizeSubtitle} !important;
    line-height: #{theme.$fontLineHeightSubtitle} !important;
    margin: 0;
    &.dark {
      color: theme.$green-secondary !important;
    }

    &.light {
      color: theme.$green-primary !important;
    }
  }

  .link {
    font-size: #{theme.$fontSizeCta} !important;
    line-height: #{theme.$fontLineHeightCta} !important;
    text-underline-offset: $mobileLinkUnderlineOffset;
    &.dark {
      color: theme.$grey-secondary !important;
    }
    &.light {
      color: theme.$green-primary !important;
    }
  }
}