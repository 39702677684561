@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: theme.$green-secondary;
  border-radius: 120px;
  width: 80px;
  height: 80px;


  @include theme.small-desktop {
    width: 120px;
    height: 120px;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: theme.$green-primary;
  }
}