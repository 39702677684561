@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$min-image-viewport-width: 414px;
$min-image-viewport-width-small-desktop: 1024px;
$max-image-viewport-width: 1920px;

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include theme.small-desktop {
    flex-direction: row;
    justify-content: space-around;
    gap: 10%;
  }
}

.notFoundLeftContainer {
  flex-basis: 100%;

  @include theme.small-desktop {
    flex-basis: 40%;
    min-height: 73vh;
  }
}

.notFoundRightContainer {
  flex-basis: 100%;
  min-height: 70vh;

  @include theme.small-desktop {
    flex-basis: 60%;
    min-height: 73vh;
  }
}

.title {
  color: rgb($green-primary, 0.8);
}

.description {
  color: rgb($green-primary, 0.8);
  margin-block-start: 10%;
}

/* TODO: Consolidate this and the pill in the Cards */
.notFoundPill {
  font-family: #{$amazonEmberMono};
  display: inline-block;
  font-size: 16px;
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  font-weight: 400;
  color: #{theme.$green-primary};

  &.dark {
    background-color: #{theme.$slate-light};
    color: #{theme.$green-secondary};
  }
}
