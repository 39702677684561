@use '../../../index.scss' as website;
@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$gapDevice: 8px;
$gapDesktop: 20px;

$buttonWidth: 112px;
$buttonActiveMaxWidth: 117px;
$buttonActiveWidth: 468px;

$buttonDeviceHeight: 72px;
$buttonDesktopHeight: 80px;

$buttonPadding: 25px;
$activeButtonPadding: 32px;

$borderRadiusDevice: 8px;
$borderRadiusDesktop: 12px;

$profilePadding: 8px;
$profileMarginDevice: 16px;
$profileMarginDesktop: 12px;

$profileImageDeviceSize: 72px;
$profileImageDesktopSize: 48px;

$buttonWrapperWidth: 80px;

$rightButtonDarkBackground: linear-gradient(90deg, transparent 0%, theme.$slate-dark 100%);
$leftButtonDarkBackground: linear-gradient(90deg, theme.$slate-dark 0%, transparent 100%);

$scrollButtonSize: 24px;

$divisorWidth: 2px;
$divisorHeight: 24px;
$divisorBorderRadius: 16px;
$divisorMargin: auto 32px auto 0;

$activeButtonWidth: 32px;
$activeButtonHeight: 2px;
$activeButtonBorderRadius: 2px;

$transitionDuration: 0.75s;

$transitionEasing: linear(
  0,
  0.012 0.9%,
  0.049 2%,
  0.409 9.3%,
  0.513 11.9%,
  0.606 14.7%,
  0.691 17.9%,
  0.762 21.3%,
  0.82 25%,
  0.868 29.1%,
  0.907 33.6%,
  0.937 38.7%,
  0.976 51.3%,
  0.994 68.8%,
  1
);

.brandTabsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: fit-content;
  margin: 0 auto;
}

.brandTabsWrapper {
  position: relative;
  display: flex;
}

.brandTabs {
  display: flex;
  gap: $gapDevice;
  position: relative;
  overflow-x: auto;

  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for other browsers */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @include theme.large-device {
    gap: $gapDesktop;
    overflow-x: hidden;
  }
}
.profileWrapper {
  display: flex;
  border-radius: $borderRadiusDesktop;
  border: 1px solid rgba(theme.$white, 0.35);
  padding: $profilePadding;

  @include theme.large-device {
    border: 0;
    padding: 0 $buttonPadding 0 0;
  }

  .profileImage {
    @include theme.size($profileImageDeviceSize);
    border-radius: $borderRadiusDevice;
    @include theme.large-device {
      @include theme.size($profileImageDesktopSize);
      border-radius: $borderRadiusDesktop;
    }
  }

  .profileCopyWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: $profileMarginDevice;

    @include theme.large-device {
      margin-left: $profileMarginDesktop;
    }
  }

  .profileName,
  .profileOcupation {
    font-size: #{theme.$fontSizeBodySmall} !important;
    line-height: #{theme.$fontLineHeightBodySmall} !important;
    font-family: website.$amazonEmberDisplay;
    white-space: nowrap;
    margin: 0 !important;
    color: theme.$grey-secondary;
  }

  .profileOcupation {
    opacity: 0.85;
  }

  .divisor {
    width: $divisorWidth;
    height: $divisorHeight;
    border-radius: $divisorBorderRadius;
    background: rgba(theme.$white, 0.15);
    margin: $divisorMargin;
  }
}

.brandTab {
  position: relative;
  min-width: $buttonWidth;
  width: $buttonWidth;
  height: $buttonDeviceHeight;
  border-radius: $borderRadiusDesktop;
  border: 1px solid rgba(theme.$white, 0.35) !important;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  background-color: transparent;
  transform-origin: left;
  transition-duration: $transitionDuration;
  transition-property: width, border, background-color;
  transition-timing-function: $transitionEasing;

  &:hover {
    background-color: rgba(theme.$white, 0.05);
    border-color: theme.$white !important;

    .brandIcon {
      svg {
        color: theme.$white;
        opacity: 1;
      }
    }
  }
  &:focus-visible {
    outline: 1px solid rgba(theme.$white, 0.35);
    outline-offset: -4px;

    .brandIcon {
      svg {
        color: theme.$white;
        opacity: 1;
      }
    }
  }

  &::before {
    @include theme.pseudo;
    width: $activeButtonWidth;
    height: $activeButtonHeight;
    border-radius: $activeButtonBorderRadius;
    background: theme.$green-secondary;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;
  }

  .brandIcon {
    min-width: $buttonWidth;
    display: flex;
    justify-content: center;
    margin-left: -1px;
    opacity: 1;

    svg {
      color: theme.$grey-secondary;
      opacity: 0.6;
      transition-duration: $transitionDuration;
      transition-property: opacity;
      transition-timing-function: $transitionEasing;
    }
  }

  &.isActive {
    background-color: rgba(theme.$white, 0.05);
    transform-origin: right;
    border: 0 !important;

    &:focus-visible {
      outline-offset: -1px;
    }

    &::before {
      display: block;
    }
    @include theme.large-device {
      width: $buttonActiveWidth;

      &::before {
        display: none;
      }
    }

    .brandIcon {
      animation: fadeIn 0.3s ease forwards;

      svg {
        color: theme.$white;
        opacity: 1;
      }

      @include theme.large-device {
        min-width: $buttonActiveMaxWidth;
      }
    }
  }
}

.buttonWrapperRight,
.buttonWrapperLeft {
  @include theme.size($buttonWrapperWidth, 100%);

  position: absolute;
  display: none;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);

  &.showButtonRight,
  &.showButtonLeft {
    display: flex;
  }
}

.buttonWrapperRight {
  justify-content: flex-end;
  right: 0;
  background: $rightButtonDarkBackground;
}

.buttonWrapperLeft {
  justify-content: flex-start;
  left: 0;
  background: $leftButtonDarkBackground;
  button {
    rotate: 180deg;
  }
}

.scrollButton {
  @include theme.size($scrollButtonSize);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), theme.$slate-dark !important;
  color: theme.$white !important;

  svg {
    margin-top: -1px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
