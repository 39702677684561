@import '~@amzn/sss-website-theme/dist/theme/variables.module.scss';

// https://katal.amazon.dev/design-system/components/toggle/?tab-props=style-api
kat-toggle {
  --indicator-background: #{$grey-primary};
  --indicator-background-active: #{$green-secondary};
  --indicator-background-disabled: #{$grey-primary};
  --indicator-background-disabled-active: #{$grey-primary};
  --indicator-border-radius: 32px;
  --indicator-border-width: 0;
  --indicator-height: 32px;
  --indicator-width: 48px;
  
  --dot-background: #{$green-primary};
  --dot-background-active: #{$green-primary};
  --dot-background-disabled: #{$grey-primary};
  --dot-inlay: 2px;
  --dot-size: 28px;
  --dot-width: 28px;
}