@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

.customGraphic {
  position: relative;
  color: theme.$green-primary;
}

.heading {
  padding: 0 25px 48px;
  @include theme.medium-device {
    padding: 104px 60px 80px;
  }
}

.tagline {
  font-family: website.$amazonEmberMono;
  margin-bottom: 32px;
}

.descriptionAndButtonWrapper {
  display: flex;
  width: 100%;
  padding: 0 25px;
  justify-content: space-between;
  align-items: center;
  @include theme.small-device {
    padding: 0 65px;
  }
}

.description {
  z-index: 1;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  font-family: website.$amazonEmberMono;
  max-width: 192px;

  @include theme.small-device {
    max-width: unset;
  }
}

.pauseButton {
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 100%;
  background-color: theme.$grey-tertiary !important;
  display: grid;
  justify-content: center;
  align-items: center;

  @include theme.small-desktop {
    width: 56px;
    height: 56px;
  }
}

.detailsContent {
  padding: 0 25px;
  @include theme.small-desktop {
    padding: 0 60px;
  }
}

.detailsWrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
  background-color: theme.$white;
  padding: 16px 12px;
  border-radius: 8px;
  width: 100%;
  position: relative;

  @include theme.small-desktop {
    flex-direction: row;
    width: fit-content;
    padding: 16px;
    border-radius: 12px;
    flex-wrap: wrap;
    gap: 35px;
  }
}

.details {
  display: flex;
  align-items: center;
  gap: 16px;
  @include theme.small-desktop {
    gap: 12px;
  }
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.line {
  width: 26px;
  height: 2px;
  background-color: theme.$green-primary;
}

.copy {
  color: theme.$green-primary;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
  font-family: website.$amazonEmberMono;
  white-space: nowrap;
}

.footer {
  padding: 0 25px;
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  margin-top: 32px;
  gap: 36px;

  @include theme.small-desktop {
    padding: 0 60px 0 71px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.footerLinkWrapper {
  display: flex;
  gap: 8px;
}

.footerParagraph {
  font-size: theme.$fontSizeBodySmall !important;
  line-height: theme.$fontLineHeightBodySmall !important;
}

.footerLink {
  font-weight: 700;
}

.footerButton {
  background-color: transparent !important;
  border: 2px solid !important;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.modalUpperCopy {
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: theme.$green-primary;
  h4 {
    margin: 0;
  }
}

.modalImage {
  width: 100%;
  height: 31vh;
}

.modalTagline {
  font-family: website.$amazonEmberMono;
  margin-top: 32px;
}
