@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';

$paddingSmall: 8px;
$paddingMedium: 16px;
$paddingLarge: 40px;

.AccordionsComponentContent {
  display: flex;
  padding: $paddingLarge 0 0;
  flex-direction: column;
  align-items: center;
  gap: $paddingLarge;
  @include large-device {
    padding: 0;
  }
}

.accordions {
  width: 100%;
  padding: 0 $paddingMedium;
}
