@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$marginXSmall: 8px;
$marginSmall: 16px;
$marginMedium: 24px;
$marginLarge: 32px;
$marginXLarge: 48px;
$marginXXLarge: 72px;

$maxScreenWidth: 1920px;

$paddingXSmall: 4px;
$paddingMedium: 12px;
$paddingLarge: 16px;
$paddingXLarge: 28px;

$borderRadius: 32px;

$gap: 12px;

$indexDecorationHeight: 16px;
$accordionToggleDeviceWidth: 48px;
$accordionToggleDeviceHeight: 32px;
$accordionToggleDesktopWidth: 80px;
$accordionToggleDesktopHeight: 56px;

$iconSize: 24px;

$indexLineHeight: 30px;

.accordionWithIndex {
  margin: $marginMedium 0;

  .accordionIndex {
    font-family: $amazonEmberMono !important;
    font-size: theme.$fontSizeLabel !important;
    line-height: $indexLineHeight !important;
    position: relative;
    margin: 0 !important;

    &.indexDecoration {
      &::before {
        content: '';
        position: absolute;
        inset-inline-start: calc($marginMedium * -1 + 1px);
        inline-size: calc($marginMedium / 2);
        block-size: 2px;
        opacity: 0.4;
        top: 50%;
        @include theme.small-desktop {
          inset-inline-start: calc($accordionToggleDesktopHeight * -1 + 1px);
          inline-size: 15px;
        }
      }
    }

    @include theme.small-desktop {
      order: 1;
    }
  }

  &.indexDecoration {
    &:first-child {
      .accordionIndex {
        &::before {
          inset-block-start: calc($paddingLarge * -1 - 1px);
          @include theme.small-desktop {
            inset-block-start: calc($marginLarge * -1);
          }
        }
      }
    }
    &:last-child {
      &::after {
        content: '';
        position: absolute;
        inline-size: calc($marginMedium / 2);
        block-size: 1px;
        inset-block-end: -$marginMedium;
        inset-inline-start: 1px;
        opacity: 0.4;
        @include theme.small-desktop {
          inline-size: $marginMedium;
          inset-block-end: $marginMedium;
          inset-inline-start: calc($accordionToggleDesktopHeight * -1 + 1px);
        }
      }
    }
  }

  @include theme.small-desktop {
    margin: $marginXLarge 0;
    &:first-child {
      margin-block-start: 0;
    }
  }

  .accordionHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: $paddingLarge;
    @include theme.small-desktop {
      padding-top: $marginLarge;

      flex-wrap: nowrap;
      gap: 0 theme.fluid-size($marginMedium, $marginXXLarge, theme.$largeDevice, $maxScreenWidth);
    }
  }

  .accordionToggle {
    display: flex;
    inline-size: $accordionToggleDeviceWidth;
    block-size: $accordionToggleDeviceHeight;
    padding: $paddingXSmall $paddingMedium;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: $borderRadius;

    .accordionIcon {
      @include size($iconSize);
    }

    @include theme.small-desktop {
      inline-size: $accordionToggleDesktopWidth;
      block-size: $accordionToggleDesktopHeight;
      padding: $paddingMedium $paddingLarge;
      order: 3;
    }
  }

  .accordionTextContainer {
    overflow: hidden;
    inline-size: 100%;
    transition-property: height;
    transition-delay: 0.01s;
    transition-timing-function: ease-out;
    transition-duration: 0.2s;

    @include theme.small-desktop {
      order: 2;
    }
  }

  .accordionTitle {
    margin-block-start: $paddingMedium;

    &.open {
      font-size: theme.$fontSizeHeading03 !important;
      line-height: theme.$fontLineHeightHeading03 !important;
    }
    &.closed {
      font-size: theme.$fontSizeSubtitle !important;
      line-height: theme.$fontLineHeightSubtitle !important;
    }
    @include theme.small-desktop {
      margin: 0 !important;
    }
  }

  .accordionContent {
    margin: $marginSmall 0;
    opacity: theme.$asxCopyOpacity;
    @include theme.small-desktop {
      margin: $marginLarge 0;
    }
  }

  .accordionLinksWrapper {
    display: flex;
    flex-direction: column;
    gap: $gap;
  }

  &.light {
    .accordionIndex {
      color: theme.$green-primary !important;
      &.indexDecoration {
        &::before {
          background: theme.$green-primary;
        }
      }
    }
    &.indexDecoration {
      &:last-child {
        &::after {
          background: theme.$green-primary;
        }
      }
    }
    .accordionToggle {
      border: 1px solid theme.$green-primary;
      &.open {
        background: theme.$green-primary;

        .accordionIcon {
          color: theme.$green-secondary;
        }
      }

      &.closed {
        color: theme.$green-primary;
      }
    }
    .accordionTitle,
    .accordionContent,
    .externalLink {
      color: theme.$green-primary !important;
    }
  }

  &.dark {
    .accordionIndex {
      color: theme.$green-secondary !important;
      &.indexDecoration {
        &::before {
          background: theme.$grey-secondary;
        }
      }
    }
    &.indexDecoration {
      &:last-child {
        &::after {
          background: theme.$grey-secondary;
        }
      }
    }
    .accordionToggle {
      border: 1px solid theme.$grey-secondary;
      &.open {
        background: theme.$green-secondary;

        .accordionIcon {
          color: theme.$green-primary;
        }
      }

      &.closed {
        color: theme.$green-secondary;
      }
    }
    .accordionTitle {
      color: theme.$green-secondary !important;
    }
    .accordionContent,
    .externalLink {
      color: theme.$grey-secondary !important;
    }
  }
}
