@use 'src/index.scss' as website;
@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';

$backgroundDark: rgba(255, 255, 255, 0.05);
$backgroundLight: rgba($green-primary, 0.05);
$borderRadius: 100px;
$padding: 16px;
$size: 20px;

.SelectionPill {
  display: flex;
  padding: $padding;
  justify-content: start;
  gap: $padding;
  border-radius: $borderRadius;

  .icon {
    @include size($size);
  }

  .label {
    font-family: website.$amazonEmberDisplay;
    font-size: $fontSizeCta !important;
    line-height: 19px !important;
    font-weight: 700 !important;
    margin: 0;
    white-space: nowrap;
  }

  &.isActive {
    background-color: $green-secondary !important;
    .icon,
    .label {
      color: $green-primary !important;
    }
  }

  &.dark {
    background-color: $backgroundDark !important;

    .icon,
    .label {
      color: $green-secondary !important;
    }
    &.isActive {
      background-color: $green-secondary !important;
      .icon,
      .label {
        color: $green-primary !important;
      }
    }
  }

  &.light {
    background-color: $backgroundLight !important;

    .icon,
    .label {
      color: $green-primary !important;
    }
    &.isActive {
      background-color: $green-primary !important;
      .icon,
      .label {
        color: $grey-secondary !important;
      }
    }
  }
}
