@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$desktopMaxWidth: 1192px;

$quoteMinFontSize: 26px;
$quoteMaxFontSize: 56px;
$quoteMinLineHeight: 30px;
$quoteMaxLineHeight: 64px;

.quote {
  font-size: theme.fluid-size(
    $quoteMinFontSize,
    $quoteMaxFontSize,
    theme.$minViewportWidth,
    theme.$maxViewportWidth
  ) !important;
  line-height: theme.fluid-size(
    $quoteMinLineHeight,
    $quoteMaxLineHeight,
    theme.$minViewportWidth,
    theme.$maxViewportWidth
  ) !important;
  text-align: center;

  @include theme.small-desktop {
    max-width: $desktopMaxWidth;
  }
  span {
    transition: color 0.3s ease;
  }
}
