@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$componentPadding: 25px;
$topSectionPaddingTopDevice: 97px;
$topSectionPaddingTopDesktop: 184px;

$titleMaxWidth: 1193px;

$subtitleMarginTopDevice: 40px;
$subtitleMarginTopDesktop: 64px;
$subtitleMaxWidth: 553px;

$imageBorderRadiusDevice: 24px;
$imageBorderRadiusDesktop: 32px;

$leftImageLeftPosition: 156px;
$leftImageBottomPosition: -90px;
$leftImageMinWidth: 114px;
$leftImageMaxWidth: 228px;
$leftImageMinHeight: 142px;
$leftImageMaxHeight: 284px;

$rightImageTopPosition: -58px;
$rightImageRightPositionDevice: 8px;
$rightImageRightPositionDesktop: 140px;
$rightImageMinWidth: 120px;
$rightImageMaxWidth: 256px;
$rightImageMinHeight: 128px;
$rightImageMaxHeight: 272px;

$brandTabsMarginTopDevice: 52px;
$brandTabsMarginTopDesktop: 120px;

$quoteMarginTopDevice: 48px;
$quoteMarginTopDesktop: 96px;
$quotePadding: 23px;

$primaryButtonPaddingDevice: 80px;
$primaryButtonPaddingDesktop: 104px;

.quoteComponent {
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 0 $componentPadding;
}

.topSection {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $topSectionPaddingTopDevice;

  @include theme.medium-device {
    padding-top: $topSectionPaddingTopDesktop;
  }
}

.title {
  max-width: $titleMaxWidth;
  text-align: center;
}

.greenTitle {
  color: theme.$green-secondary;
}

.whiteTitle {
  color: theme.$grey-secondary;
}

.subtitle {
  margin: $subtitleMarginTopDevice 0 0 !important;
  max-width: $subtitleMaxWidth;
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  text-align: center;
  color: theme.$grey-secondary;
  opacity: theme.$asxCopyOpacity;

  @include theme.medium-device {
    margin: $subtitleMarginTopDesktop 0 0 !important;
  }
}

.imageLeft,
.imageRight {
  position: absolute;
  border-radius: $imageBorderRadiusDevice;
  object-fit: cover;
  @include theme.medium-device {
    border-radius: $imageBorderRadiusDesktop;
  }
}

.imageLeft {
  left: $leftImageLeftPosition;
  bottom: $leftImageBottomPosition;
  width: theme.fluid-size($leftImageMinWidth, $leftImageMaxWidth, theme.$minViewportWidth, theme.$maxViewportWidth);
  height: theme.fluid-size($leftImageMinHeight, $leftImageMaxHeight, theme.$minViewportWidth, theme.$maxViewportWidth);
}

.imageRight {
  top: $rightImageTopPosition;
  right: $rightImageRightPositionDevice;
  width: theme.fluid-size($rightImageMinWidth, $rightImageMaxWidth, theme.$minViewportWidth, theme.$maxViewportWidth);
  height: theme.fluid-size(
    $rightImageMinHeight,
    $rightImageMaxHeight,
    theme.$minViewportWidth,
    theme.$maxViewportWidth
  );

  @include theme.medium-device {
    right: $rightImageRightPositionDesktop;
  }
}

.brandTabs {
  margin-top: $brandTabsMarginTopDevice !important;
  @include theme.medium-device {
    margin-top: $brandTabsMarginTopDesktop !important;
  }
}

.quote {
  margin: $quoteMarginTopDevice auto 0 !important;
  padding: 0 $quotePadding;

  @include theme.medium-device {
    margin: $quoteMarginTopDesktop auto 0 !important;
  }
}

.primaryButtonWrapper {
  padding: $primaryButtonPaddingDevice 0;

  @include theme.medium-device {
    padding: $primaryButtonPaddingDesktop 0;
  }
}
