@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use '../../../index.scss';

$minScreenWidth: 414px;
$maxScreenWidth: 1920px;

$paddingMedium: 24px;

$marginSmall: 32px;
$marginMedium: 48px;
$marginLarge: 56px;
$marginXLarge: 160px;

$gapMin: 72px;
$gapMax: 200px;

$titleWidthMin: 288px;
$titleWidthMax: 736px;
$titleDeviceLetterSpacing: 0.4px;
$titleDesktopLetterSpacing: 0.015em;

$imagesContainerWidthMin: 488px;
$imagesContainerWidthMax: 712px;
$imagesContainerHeighthMin: 311px;
$imagesContainerHeighthMax: 416px;

$imagesWidthMin: 342px;
$imagesWidthMax: 584px;
$imagesHeightMin: 268px;
$imagesHeightMax: 368px;

$wrapperPaddingLeftSmallDevice: 23px;
$wrapperPaddingLeftLargeDevice: 55px;
$wrapperBorderRadius: 15px;

$rightPadding: 11.04vw;
$sectionPaddingDesktop: 48px;

.AccordionsBanner {
  @include theme.small-desktop {
    display: flex;
    flex-direction: row;
    gap: 0 theme.fluid-size($gapMin, $gapMax, theme.$largeDevice, $maxScreenWidth);
  }

  .title {
    letter-spacing: -$titleDeviceLetterSpacing;
    inline-size: theme.fluid-size($titleWidthMin, $titleWidthMax, $minScreenWidth, $maxScreenWidth);
    @include theme.small-desktop {
      letter-spacing: -$titleDesktopLetterSpacing;
    }
  }

  .imagesContainer {
    display: none;
    @include theme.small-desktop {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;

      block-size: theme.fluid-size(
        $imagesContainerHeighthMin,
        $imagesContainerHeighthMax,
        theme.$largeDevice,
        $maxScreenWidth
      );
      margin-block-start: $marginXLarge;
      position: relative;
    }
  }

  .image {
    inline-size: theme.fluid-size($imagesWidthMin, $imagesWidthMax, theme.$largeDevice, $maxScreenWidth);
    block-size: theme.fluid-size($imagesHeightMin, $imagesHeightMax, theme.$largeDevice, $maxScreenWidth);
    margin-block-start: $marginMedium;
    z-index: 2;
    object-fit: cover;
    border-radius: theme.$border-radius-medium;
  }

  .accordionsWrapper {
    margin-block-start: $marginSmall;
    padding-left: $wrapperPaddingLeftSmallDevice !important;
    position: relative;
    border-left: 2px solid;
    border-radius: $wrapperBorderRadius;
    border-color: rgba(theme.$green-primary, 0.4);

    @include theme.large-device {
      padding-left: $wrapperPaddingLeftLargeDevice !important;
    }

    @include theme.small-desktop {
      margin-block-start: 0;
      padding-inline-start: 0;
      block-size: fit-content;
      &:first-child {
        margin-block-start: 0;
      }
    }
  }
  &.light {
    .title {
      color: theme.$green-primary !important;
    }
    .accordionsWrapper {
      &::before {
        background: theme.$green-primary;
      }
    }
  }
  &.dark {
    .title {
      color: theme.$green-secondary !important;
    }
    .accordionsWrapper {
      &::before {
        background: theme.$grey-secondary;
      }
    }
  }
  .gridImage {
    padding-right: 0;
  }
}
