@use 'src/index.scss' as website;
@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';

$primaryButtonDeviceRegularSizePadding: 20px 24px 20px 32px;
$primaryButtonDesktopRegularSizePadding: 20px 32px 20px 40px;
$lighterGreenTertiary: #277979;

.primaryButton {
  --primary-button-background-color: #{$green-primary};
  --primary-button-border-color: #{$green-secondary};
  --secondary-button-background-color: #{$slate-dark};
  --secondary-button-border-color: #{$green-primary};
  --primary-button-hover-in-duration: 600ms;
  --primary-button-hover-out-duration: 800ms;
  appearance: none;
  border: none;
  background-color: var(--primary-button-background-color) !important;
  border-radius: 40px;
  color: var(--primary-button-border-color) !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 150ms linear;
  font-family:
    #{website.$amazonEmberDisplay},
    sans-serif;
  display: inline-block; // Button was 'inline-block' but Links were taking 'inline' by default and breaking layout
  max-height: 80px;
  z-index: 2; // Ensure buttons are rendered above other page content

  &.hasMobileHover {
    &:hover:not(:disabled) {
      .mobileHover span::after {
        transform: scaleX(1);
      }
    }
  }

  // "rate" variant has same :hover styling across all colors
  &.rate {
    --secondary-button-background-color: #{$white} !important;
    --secondary-button-border-color: #{$white} !important;
    
    @include small-desktop {
      &:hover {
        color: $green-primary !important;
      }
    }
  }

  &.isLight {
    &.rate {
      --primary-button-background-color: #{$grey-secondary};
      --primary-button-border-color: #{$grey-secondary};
      color: $green-primary !important;
    }

    &.secondary {
      --primary-button-background-color: transparent;
      --primary-button-border-color: #{$green-primary};
      border-width: 2px !important;
      border-style: solid !important;
      &::before {
        background-color: $green-primary;
      }

      @include small-desktop {
        &:hover {
          color: $white !important;
        }
      }
    }
    &.secondaryNoIcon {
      background-color: rgba($white, 0.05) !important;
      color: $green-secondary !important;

      &::before {
        background-color: rgba($white, 0.15);
        color: $green-secondary !important;
      }
      &:focus {
        box-shadow:
          inset 0 0 0 2px transparent,
          inset 0 0 0 3px rgba($white, 0.15);
      }
    }
  }

  &.secondary,
  &.secondaryNoIcon {
    width: fit-content;
    font-size: 16px;
  }

  &.inverted:not(.isDark) {
    --primary-button-background-color: #{$slate-dark};
    --primary-button-border-color: #{$green-primary};
    --secondary-button-background-color: #{$green-primary};
    --secondary-button-border-color: #{$green-secondary};
    color: $green-secondary !important;
  }

  &.outlined {
    --primary-button-background-color: transparent;
    border-width: 2px !important;
    border-style: solid !important;
    --primary-button-border-color: #{$green-primary};

    &::before {
      background-color: transparent;
    }
  }

  &.bold {
    font-weight: bold;
  }

  &.regularSize {
    padding: $primaryButtonDeviceRegularSizePadding;
    @include small-desktop {
      padding: $primaryButtonDesktopRegularSizePadding;
    }
  }

  &.smallSize {
    padding: 20px 24px;
  }

  &.wideSize {
    width: 364px;
    height: 64px;
    border-radius: 32px;
  }

  &.highlightedSize {
    padding: 24px 26px;
  }

  &.icon {
    padding: 20px 24px;

    @include small-desktop {
      padding: 28px 40px;
    }
  }

  &:disabled {
    &.outlined {
      --primary-button-border-color: rgba($green-primary, 0.5);
      background-color: transparent !important;
      color: rgba($green-primary, 0.5);
    }
    &.isDark {
      &.outlined {
        --primary-button-border-color: rgba($grey-secondary, 0.5);
      }
    }
  }

  &.isGreenPrimary {
    --primary-button-background-color: #{$green-tertiary};
    --primary-button-border-color: #{$green-tertiary};
    --secondary-button-background-color: #{$lighterGreenTertiary};
    --secondary-button-border-color: #{$white};
    color: $green-secondary !important;
  }

  &.isTeal {
    --primary-button-background-color: #{$grey-tertiary};
    --primary-button-border-color: #{$grey-tertiary};
    --secondary-button-background-color: #{$white};
    --secondary-button-border-color: #{$grey-tertiary};
    color: $green-primary !important;
  }

  &.isDark {
    --primary-button-background-color: #{$green-secondary};
    --primary-button-border-color: #{$green-primary};
    --secondary-button-background-color: #{$grey-secondary};
    --secondary-button-border-color: #{$green-secondary};
    color: $green-primary;

    &.rate {
      --primary-button-background-color: #{$slate-lighter};
      --primary-button-border-color: #{$slate-lighter};
      color: $green-secondary !important;
      
      @include small-desktop {
        &:hover {
          color: $green-primary !important;
        }
      }
    }

    &.inverted {
      --primary-button-background-color: #{$grey-secondary};
      --primary-button-border-color: #{$green-primary};
      --secondary-button-background-color: #{$green-secondary};
      --secondary-button-border-color: #{$green-primary};
      color: $green-primary;
    }

    &.outlined {
      --primary-button-background-color: transparent;
      --primary-button-border-color: #{$grey-secondary};
    }
    &.secondary {
      --primary-button-background-color: transparent;
      --primary-button-border-color: #{$green-secondary};
      color: $green-secondary !important;
      border-width: 2px !important;
      border-style: solid !important;
      &::before {
        background-color: $white;
      }

      @include small-desktop {
        &:hover {
          color: $slate-dark !important;
        }
      }
    }
  }

  &.isGray {
    --primary-button-background-color: #{rgba($white, 0.05)};
    --primary-button-border-color: #{rgba($white, 0.05)};
    --secondary-button-background-color: #{$grey-tertiary};
    --secondary-button-border-color: #{$grey-tertiary};
    color: $white !important;
    &:hover {
      color: $green-secondary !important;
    }
  }

  &.isOnlyIcon {
    inline-size: 80px;
    block-size: 56px;
    padding: 0;

    &.smallSize {
      inline-size: 56px;
      block-size: 40px;
    }
  }

  .buttonContent {
    overflow: hidden;
    position: relative;
    block-size: 100%;
  }

  .primaryContent,
  .secondaryContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    block-size: 100%;
    transition: transform var(--primary-button-hover-out-duration) $hoverCubicBezier;
    transform-style: preserve-3d;
    &.mobileHover {
      span {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0.1px;
          left: 0;
          background-color: currentColor;
          transform: scaleX(0);
        }
      }
    }

    &.reversed {
      flex-direction: row-reverse;
    }
  }

  .icon {
    inline-size: 24px;
    block-size: 24px;
    color: currentColor;
  }

  .secondaryContent {
    position: absolute;
    inset: 0;
    transform: translateY(250%);

    .icon {
      color: currentColor;
    }
  }

  .iconWrapper {
    position: relative;
    background-color: rgba(0, 97, 97, 0.1);
    padding: 16px 26px;
    margin: 4px;
    border-radius: 100px;
  }

  &:focus {
    outline: none;
    box-shadow:
      inset 0 0 0 2px var(--primary-button-background-color),
      inset 0 0 0 3px var(--primary-button-border-color);
  }

  &:disabled {
    background-color: #c9cfd2 !important;
    color: #868e97 !important;
    cursor: initial;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    clip-path: ellipse(100% 125% at 50% 125%);
    background-color: var(--secondary-button-background-color);
    transform: translate(0, 176%) scale(2.03, 2.5);
    transition: transform var(--primary-button-hover-out-duration) $hoverCubicBezier;
    inline-size: 100%;
    block-size: 100%;
  }

  @include small-desktop {
    &.hasHoverAnimation {
      &:hover:not(:disabled) {
        &::before {
          transform: translate(0, 0%) scale(2.03, 1.3);
          transition: transform var(--primary-button-hover-in-duration) $hoverCubicBezier;
        }

        .primaryContent {
          transform: translateY(-150%);
          transition: transform var(--primary-button-hover-in-duration) $hoverCubicBezier;
        }

        .secondaryContent {
          transform: translateY(0%);
          transition: transform var(--primary-button-hover-in-duration) $hoverCubicBezier;
        }
      }
    }
  }
}
