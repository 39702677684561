@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$largeDesktopBreakpoint: 1920px;

$mobileDynamicListHorizontalPadding: 25px;

$largeDeviceRightPadding: 262px;
$largeDeviceDynamicListLeftPadding: 60px;

.dynamicList {
  @include theme.large-device {
    padding-right: theme.fluid-size(
      $mobileDynamicListHorizontalPadding,
      $largeDeviceRightPadding,
      theme.$largeDevice,
      $largeDesktopBreakpoint
    );

    padding-left: theme.fluid-size(
      $mobileDynamicListHorizontalPadding,
      $largeDeviceDynamicListLeftPadding,
      theme.$largeDevice,
      $largeDesktopBreakpoint
    );
  }
}
