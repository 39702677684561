@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import './PlyrOverrides.module.scss';

$backgroundBlack: #000000;
$gradientGray: #090C11;

$videoPreviewGap: 24px;
$previewsImagesContainerBorderRadius: 8px 8px 0 0;
$progressBarHeight: 2px;
$autoScrollButtonIconSize: 24px;

$mobileVideoContainerGradientBorderRadius: 24px;
$mobileVideoPosterBorderRadius: 24px;
$mobileVideoTextContainerTop: 32px; 
$mobileVideoTextContainerLeft: 16px;
$mobileVideoTextContainerWidth: 313px;
$mobileVideoTextContainerGap: 32px;
$mobileVideoPlayCtaPadding: 20px 32px 20px 24px;
$mobileVideoPreviewBottom: 22px;
$mobileVideoPreviewLeft: 16px;
$mobilePreviewsContainerGap: 38px;
$mobilePreviewButtonWidth: 72px;
$mobilePreviewButtonHeight: 60px;
$mobilePreviewAutoScrollButtonSize: 40px;

$desktopVideoContainerHorizontalPadding: 32px;
$desktopVideoContainerGradientLeft: 32px;
$desktopVideoContainerGradientBorderRadius: 40px;
$desktopVideoPosterBorderRadius: 40px;
$desktopVideoTextContainerTop: 240px; 
$desktopVideoTextContainerLeft: 112px;
$desktopVideoTextContainerWidth: 708px;
$desktopVideoTextContainerGap: 56px;
$desktopVideoPlayCtaPadding: 28px 40px 28px 32px;
$desktopVideoPreviewBottom: 56px;
$desktopVideoPreviewLeft: 112px;
$desktopVideoPreviewInfoContainerGap: 16px;
$desktopVideoPreviewInfoDividerBorderRadius: 8px;
$desktopVideoPreviewInfoDividerWidth: 1px;
$desktopVideoPreviewInfoDividerHeight: 12px;
$desktopPreviewsContainerGap: 16px;
$desktopPreviewButtonWidth: 114px;
$desktopPreviewButtonHeight: 72px;
$desktopPreviewAutoScrollButtonSize: 56px;

.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background: theme.$slate-dark;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $backgroundBlack;
    opacity: 0.5;
    pointer-events: none;
    border-radius: $mobileVideoContainerGradientBorderRadius;

    @include theme.small-desktop {
      width: 100%;
      opacity: 1;
      background: linear-gradient(90deg, rgba($gradientGray, 0.7) 0%, rgba($gradientGray, 0.5) 60%, rgba($gradientGray, 0) 100%);
      border-radius: $desktopVideoContainerGradientBorderRadius;
    }
  }

  // When this video component is being used as part of the animated hero banner we don't want to see the dark gradient
  &.intro {
    padding: 0;
    &::before {
      content: none;
    }
  }
}

.videoPoster {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: $mobileVideoPosterBorderRadius;

  @include theme.small-desktop {
    border-radius: $desktopVideoPosterBorderRadius;
  }

  &.intro {
    border-radius: 0;
  }
}

.videoTextContainer {
  position: absolute;
  top: theme.fluid-size($mobileVideoTextContainerTop, $desktopVideoTextContainerTop, theme.$minViewportWidth, theme.$maxViewportWidth);
  left: theme.fluid-size($mobileVideoTextContainerLeft, $desktopVideoTextContainerLeft, theme.$minViewportWidth, theme.$maxViewportWidth);
  width: theme.fluid-size($mobileVideoTextContainerWidth, $desktopVideoTextContainerWidth, theme.$minViewportWidth, theme.$maxViewportWidth);
  display: flex;
  flex-direction: column;
  gap: theme.fluid-size($mobileVideoTextContainerGap, $desktopVideoTextContainerGap, theme.$minViewportWidth, theme.$maxViewportWidth);
}

.videoTextHeader {
  color: theme.$white;
}

.videoPlayCta {
  width: fit-content;
  font-size: theme.$fontSizeCta;
  line-height: theme.$fontLineHeightCta;
  padding: $mobileVideoPlayCtaPadding !important;

  @include theme.small-desktop {
    padding: $desktopVideoPlayCtaPadding !important;
  }
}

.videoPreview {
  position: absolute;
  bottom: theme.fluid-size($mobileVideoPreviewBottom, $desktopVideoPreviewBottom, theme.$minViewportWidth, theme.$maxViewportWidth);
  left: theme.fluid-size($mobileVideoPreviewLeft, $desktopVideoPreviewLeft, theme.$minViewportWidth, theme.$maxViewportWidth);
  display: flex;
  flex-direction: column;
  gap: $videoPreviewGap;
  z-index: 1;
}

.videoPreviewInfoContainer {
  display: flex;
  flex-direction: column;

  @include theme.small-desktop {
    display: flex;
    flex-direction: row;
    gap: $desktopVideoPreviewInfoContainerGap;
    align-items: center;
  }
}

.videoPreviewInfo {
  font-size: #{theme.$fontSizeBodySmall};
  line-height: #{theme.$fontLineHeightBodySmall};
  color: theme.$green-secondary;
}

.videoPreviewInfoDivider {
  display: none;

  @include theme.small-desktop {
    display: block;
    background: theme.$white;
    opacity: 0.4;
    border-radius: $desktopVideoPreviewInfoDividerBorderRadius;
    width: $desktopVideoPreviewInfoDividerWidth;
    height: $desktopVideoPreviewInfoDividerHeight;
  }
}

.currentPreviewInfo {
  font-size: #{theme.$fontSizeBodySmall};
  line-height: #{theme.$fontLineHeightBodySmall};
  color: theme.$white;
}

.previewsContainer {
  display: flex;
  flex-direction: row;
  gap: theme.fluid-size($mobilePreviewsContainerGap, $desktopPreviewsContainerGap, theme.$minViewportWidth, theme.$maxViewportWidth);
  align-items: center;
}

.previewsImagesContainer {
  border-radius: $previewsImagesContainerBorderRadius;
  overflow: hidden;
}

.previewButton {
  width: theme.fluid-size($mobilePreviewButtonWidth, $desktopPreviewButtonWidth, theme.$minViewportWidth, theme.$maxViewportWidth);
  height: theme.fluid-size($mobilePreviewButtonHeight, $desktopPreviewButtonHeight, theme.$minViewportWidth, theme.$maxViewportWidth);
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
  padding: 0;

  &.activePreview, &:hover, &:focus {
    opacity: 1;
  }

  &:focus-visible {
    outline: none;
  }
}

.previewImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.progressBar {
  height: $progressBarHeight;
}

.previewsAutoScrollButton {
  @include theme.size($mobilePreviewAutoScrollButtonSize);
  border-radius: 50%;
  border: 2px solid theme.$white !important;
  background-color: transparent !important;
  color: theme.$white !important;
  opacity: 0.5;

  @include theme.small-desktop {
    @include theme.size($desktopPreviewAutoScrollButtonSize);
  }
}

.icon {
  @include theme.size($autoScrollButtonIconSize);
  margin: auto;
}