@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$dropdownMobileMinHeight: 56px;
$dropdownMobilePadding: 16px 16px 16px 24px;

$dropdownLabelBottomMargin: 12px;
$dropdownLabelBottomMarginMobile: 24px;
$dropdownBorderRadius: 12px;
$dropdownOutlineOffset: -4px;
$dropdownIconSize: 24px;
$dropdownMenuBorderRadius: 12px;
$dropdownMenuTopMargin: 4px;
$dropdownOptionHeight: 48px;
$dropdownOptionPadding: 12px 12px 12px 24px;

$dropdownDesktopHeight: 64px;
$dropdownDesktopPadding: 16px 20px 16px 24px;

$errorWrapperTopMargin: 12px;
$errorWrapperGap: 4px;
$errorIconSize: 24px;

$checkboxSubLabelGap: 4px;
$checkboxSubLabelMargin: 8px 0 0 0;

$roundCheck: 16px;
$borderBottomMargin: 24px;
$creditTopMargin: 8px;
$creditOptionHeightDesktop: 56px;
$creditOptionHeight: 36px;

.dropdownInputContainer {
  position: relative;

  &.error {
    .label,
    .selectedOption,
    .optionLabel {
      color: theme.$errorColor;
    }

    .dropdown,
    .optionRoundUncheck {
      border-color: rgba(theme.$errorColor, 0.65);
    }
  }

  .label {
    font-family: $amazonEmberMono;
    font-size: #{theme.$fontSizeLabel};
    line-height: #{theme.$fontLineHeightLabel};
    color: theme.$green-primary;
    opacity: 0.85;
    margin-bottom: $dropdownLabelBottomMargin;

    &.round {
      font-family: inherit;
      font-size: #{theme.$fontSizeSubtitle};
      line-height: #{theme.$fontLineHeightSubtitle};
      margin-bottom: $dropdownLabelBottomMarginMobile;

      @include theme.small-desktop {
        margin-bottom: $dropdownLabelBottomMargin;
      }
    }
  }

  .dropdown {
    position: relative;
    background: theme.$white;
    border: 1px solid rgba(theme.$green-primary, 0.65);
    border-radius: $dropdownBorderRadius;
    padding: $dropdownMobilePadding;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: $dropdownMobileMinHeight;
    cursor: pointer;

    &:focus-visible {
      border: 1px solid theme.$green-primary;
      outline: 1px solid theme.$green-primary;
      outline-offset: $dropdownOutlineOffset;
    }

    @include theme.small-desktop {
      padding: $dropdownDesktopPadding;
      height: $dropdownDesktopHeight;
    }
  }

  .selectedOption {
    font-size: #{theme.$fontSizeSubtitle} !important;
    line-height: #{theme.$fontLineHeightSubtitle} !important;
    color: theme.$green-primary;
    margin: 0 !important;
  }

  .icon {
    @include theme.size($dropdownIconSize);
    transform: rotate(90deg);
    &.open {
      transform: rotate(-90deg);
    }
  }

  .optionsContainer {
    border: 1px solid rgba(theme.$green-primary, 0.65);
    border-radius: $dropdownMenuBorderRadius;
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    margin-top: $dropdownMenuTopMargin !important;
    z-index: 3;
    overflow: hidden;

    &.round {
      padding: $creditTopMargin $borderBottomMargin $borderBottomMargin;
    }
  }

  .option {
    height: $dropdownOptionHeight;
    cursor: pointer;
    color: theme.$green-primary !important;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.check {
      padding: $dropdownOptionPadding;
      justify-content: space-between;

      &.active {
        background: theme.$green-primary;
        color: theme.$grey-secondary !important;
      }
      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        background: theme.$grey-tertiary;
        color: theme.$green-primary !important;

        .optionIcon {
          color: theme.$green-primary;
        }
      }
    }

    &.round {
      justify-content: flex-start;
      border-bottom: 1px solid rgba(theme.$green-primary, 0.2);
      height: $creditOptionHeight;

      @include theme.small-desktop {
        height: $creditOptionHeightDesktop;
      }

      &:hover {
        outline: none;
        background: theme.$grey-tertiary;
        color: theme.$green-primary !important;

        .optionIcon {
          color: theme.$green-primary;
        }
      }
      &:focus,
      &:focus-visible {
        border-radius: $dropdownMenuBorderRadius;
        outline: 1px solid theme.$green-primary;
        outline-offset: $dropdownOutlineOffset;
      }
    }
  }

  .optionRoundUncheck {
    width: $roundCheck;
    height: $roundCheck;
    border-radius: 100%;
    border: 1px solid rgba(theme.$green-primary, 0.65);
    position: relative;
    margin: 0 $dropdownLabelBottomMargin;
  }

  .optionRoundCheck {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .optionLabel {
    font-size: #{theme.$fontSizeBodySmall} !important;
    line-height: #{theme.$fontLineHeightBodySmall} !important;
    margin: 0 !important;
    color: inherit;
  }

  .optionIcon {
    @include theme.size($dropdownIconSize);
    color: theme.$grey-secondary;
  }

  .errorWrapper {
    margin-top: $errorWrapperTopMargin;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $errorWrapperGap;
  }

  .errorIcon {
    @include theme.size($errorIconSize);
    color: theme.$errorColor;
  }

  .errorMessage {
    font-family: $amazonEmberMono !important;
    font-size: theme.$fontSizeLabel !important;
    line-height: theme.$fontLineHeightLabel !important;
    margin: 0 !important;
    color: theme.$errorColor;
  }
}
