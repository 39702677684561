@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../../../index.scss';

$submitButtonWrapperMobileTopMargin: 24px;
$submitButtonWrapperMobileRowGap: 40px;

$submitButtonWrapperDesktopTopMargin: 32px;
$submitButtonWrapperDesktopRowGap: 80px;

$submitCtaFontSize: 16px;

$textAreaSubLabelGap: 4px;
$textAreaSubLabelMargin: 8px 0 0 0;
$formRequiredFieldsBottomMargin: 48px;

$disabledColor: rgba(35, 47, 62, 0.30);


.submitButtonWrapper {
  margin-top: $submitButtonWrapperMobileTopMargin;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: end;
  row-gap: $submitButtonWrapperMobileRowGap;

  @include theme.medium-device {
    margin-left: auto;
  }

  @include theme.small-desktop {
    margin-top: $submitButtonWrapperDesktopTopMargin;
    row-gap: $submitButtonWrapperDesktopRowGap;
  }

  button {
    font-size: $submitCtaFontSize !important;
    width: fit-content;
    align-self: end;
  }
}

.hmdRadio {
    p {
      font-size: 16px !important;
      @include theme.small-desktop {
        font-size: 20px !important;
      }
    }

    label {
      font-size: 16px !important;
      @include theme.small-desktop {
        font-size: 20px !important;
      }
    }
}

.hmdDropdown {
  label {
    font-size: 16px !important;
    @include theme.small-desktop {
      font-size: 20px !important;
    }
  }
}



.primaryButton {
  font-size: $submitCtaFontSize !important;
}

.formRequiredFields {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$green-primary;
  margin-bottom: $formRequiredFieldsBottomMargin !important;
  opacity: 0.85;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 40px;
  width: 86vw;
  @include theme.medium-device {
    width: 100%;
  }
  height: 100%;
  color: theme.$green-primary;

  p {
    font-size: 20px !important;
  }
}

.formContainer {
  display: grid;
  grid-template-rows: auto auto;
  gap: 20px;
  // used to control the Modal .body width since Katal doesn't provide overrides for it
  width: 100%;
  @include theme.medium-device {
    grid-template-rows: auto auto 1fr;
    padding-left: unset;
    width: 100%;
  }

  .ratingQuestionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 48px;

    @media (max-width: 400px) {
      padding: 0 8px;
    }

    @include theme.medium-device {
      padding: 0; // Remove padding for medium devices and up
      align-items: center;
    }
  }

  .reasonQuestionContainer {
    padding: 0 8px;
    legend {
      font-size: 16px !important;
      @include theme.small-desktop {
        font-size: 20px !important;
      }
    }
  }
}

