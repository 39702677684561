@use "~@amzn/sss-website-theme/dist/theme/variables.module.scss" as theme;

$maxScreenSize: 1920px;
$subsectionSizeMin: 30px;
$subsectionSizeMax: 56px;
$subsectionLineHeightMin: 40px;
$subsectionLineHeightMax: 64px;
$h1LetterSpacing: -0.02em;

.subsectionSize {
  font-size: theme.fluid-size($subsectionSizeMin, $subsectionSizeMax, theme.$mediumDevice, $maxScreenSize);
  line-height: theme.fluid-size(
    $subsectionLineHeightMin,
    $subsectionLineHeightMax,
    theme.$mediumDevice,
    $maxScreenSize
  );
}

.h1 {
  letter-spacing: $h1LetterSpacing;
  font-weight: 400;
  font-size: theme.$fontSizeHeading01;
  line-height: theme.$fontLineHeightHeading01;
}

.h2 {
  font-weight: 400;
  font-size: theme.$fontSizeHeading02;
  line-height: theme.$fontLineHeightHeading02;
}

.h3 {
  font-weight: 400;
  font-size: theme.$fontSizeHeading03;
  line-height: theme.$fontLineHeightHeading03;
}
